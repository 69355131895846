/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2024-01-28 18:29:37
 * @Description: file content
 * @FilePath: \auxface_web\src\views\projectInfo\lang\en.jsx
 */
export default {
  "projectInfo": {
    "router": {
      "projectInformation": "Project Information",
      "permissionManagement": "Permission Management",
      "participatingUnits": "Participating Units",
      "constructionPermit": "Construction Permit",
      "projectSettings": "Project Settings",
      "integratorList": "Integrator List",
      "integratorApproval": "Integrator Approval",
      "governmentIntegration": "Government Integration",
      "salesSettings": "Sales Settings"
    },
    "project": {
      "projectCode": "Project Code",
      "projectEdit": "Project Edit",
      "cancelEdit": "Cancel Edit",
      "projectDelete": "Project Delete",
      "projectName": "Project Name",
      "enterProjectName": "Enter Project Name",
      "contractorCorpName": "Contractor Corporation Name",
      "unifiedSocialCreditCode": "Unified Social Credit Code",
      "projectCategory": "Project Category",
      "selectProjectCategory": "Select Project Category",
      "projectLocation": "Project Location",
      "projectAddress": "Project Address",
      "totalInvestment": "Total Investment (thousands of yuan)",
      "unitInTenThousand": "Unit: Thousands of yuan",
      "totalArea": "Total Area (square meters)",
      "unitInSquareMeter": "Unit: Square meters",
      "totalLength": "Total Length (meters)",
      "unitInMeter": "Unit: Meters",
      "constructionLandPlanningPermitNumber": "Construction Land Planning Permit Number",
      "constructionProjectPlanningPermitNumber": "Construction Project Planning Permit Number",
      "startDate": "Start Date",
      "selectDate": "Select Date",
      "completeDate": "Completion Date",
      "contactPerson": "Contact Person",
      "contactPersonName": "Contact Person Name",
      "contactPersonPhone": "Contact Person Phone",
      "projectStatus": "Project Status",
      "selectProjectStatus": "Select Project Status",
      "projectUsage": "Project Usage",
      "selectProjectUsage": "Select Project Usage",
      "projectScale": "Project Scale",
      "selectProjectScale": "Select Project Scale",
      "constructionNature": "Construction Nature",
      "selectConstructionNature": "Select Construction Nature",
      "approvalLevel": "Approval Level",
      "selectApprovalLevel": "Select Approval Level",
      "approvalNumber": "Approval Number",
      "projectIntroduction": "Project Introduction",
      "submit": "Submit Immediately",
      "projectStatusRequired": "Project status cannot be empty",
      "projectNameRequired": "Project name cannot be empty",
      "onlyNumbersAllowed": "Only numbers can be entered",
      "confirmDeleteProject": "Confirm to delete this project? Once deleted, the project cannot be recovered and irreversible. Please operate with caution.",
      "warning": "Warning",
      "confirm": "Confirm",
      "cancel": "Cancel",
      "warning": "Warning: Project deletion is irreversible! Please proceed with caution!",
      "warningTips": "This operation will permanently delete the data of project {projectName} and cancel the association of participating units in the project.",
      "confirmation": "To prevent accidents, please confirm the project name and code to continue:",
      "prjName": "Project Name: {projectName}",
      "prjCode": "Project Code: {projectCode}",
      "enterProjectName": "Enter Project Name",
      "enterProjectCode": "Enter Project Code",
      "projectNameRequired": "Project name cannot be empty",
      "projectCodeRequired": "Project code cannot be empty",
      "deleteProject": "Delete Project",
      "cancel": "Cancel",
      "deleteProject": "Delete",
      "nameRequired": "Please enter the project name",
      "codeRequired": "Please enter the project code",
      "contractorCorpName": "Contractor Corporation Name",
      "contractorCorpCode": "Unified Social Credit Code",
      "category": "Project Category",
      "area": "Project Location",
      "address": "Project Address",
      "invest": "Total Investment (thousands of yuan)",
      "buildingArea": "Total Area (square meters)",
      "buildingLength": "Total Length (meters)",
      "buildPlanNum": "Construction Land Planning Permit Number",
      "prjPlanNum": "Construction Project Planning Permit Number",
      "startDate": "Start Date",
      "completeDate": "Completion Date",
      "linkMan": "Contact Person",
      "linkPhone": "Contact Person Phone",
      "prjStatus": "Project Status",
      "prjNum": "Project Usage",
      "prjSize": "Project Scale",
      "propertyNum": "Construction Nature",
      "approvalLevelNum": "Approval Level",
      "approvalNum": "Approval Number",
      "description": "Project Introduction",
      "preparation": "Preparatory",
      "notCompleted": "Not Completed"
    },
    "projectCorp": {
      "participatingUnits": "Participating Units",
      "addParticipatingUnit": "Add Participating Unit",
      "entered": "Entered",
      "wait": "Awaiting Entry",
      "exit": "Exited",
      "corpType": "Corporate Type",
      "select": "Select",
      "search": "Search",
      "alertDescription": "To change the general contracting unit or enterprise name, please contact the administrator for assistance",
      "randomPersonSelect": "Random Personnel Selection",
      "entry": "Entry",
      "notEntry": "Not Entered",
      "exit": "Exit",
      "notExit": "Not Exited",
      "exitConfirmation": "Confirm the exit of the participating unit? Once exited, the personnel and teams of this project will automatically exit and cannot be reversed. Please proceed with caution.",
      "participatingUnit": "Participating Unit",
      "unifiedSocialCode": "Unified Social Code",
      "corpType": "Corporate Type",
      "randomButton": "Random Button",
      "permissionGroup": "Permission Group",
      "entryTime": "Entry Time",
      "exitTime": "Exit Time",
      "confirmation": "Confirmation",
      "cancel": "Cancel",
      "addParticipatingUnit": "Add Participating Unit",
      "participatingUnitDetails": "Participating Unit Details",
      "add": "Add",
      "participatingUnit": "Participating Unit",
      "enterpriseName": "Enterprise Name",
      "enterEnterpriseName": "Enter Enterprise Name",
      "unifiedSocialCreditCode": "Unified Social Credit Code",
      "enterUnifiedSocialCreditCode": "Enter Unified Social Credit Code",
      "participatingEnterpriseType": "Participating Enterprise Type",
      "projectCategory": "Project Category",
      "projectLeaderName": "Project Leader Name",
      "projectLeaderIDNumber": "Project Leader ID Number",
      "projectLeaderPhoneNumber": "Project Leader Phone Number",
      "enterpriseNameRequired": "Enterprise name cannot be empty",
      "unifiedSocialCreditCodeRequired": "Unified Social Credit Code cannot be empty",
      "participatingEnterpriseTypeRequired": "Please select participating enterprise type",
      "confirmEntry": "Confirm Entry",
      "updatePermissionGroup": "Update Permission Group",
      "permissionGroupTips": "Permission Group (If there are no permission groups, please go to the 'Permission Management' to add):",
      "selectPermissionGroup": "Select Permission Group",
      "entry": "Entry",
      "update": "Update",
      "permissionGroupRequired": "Permission group cannot be empty",
      "addRandomPerson": "Add Random Person",
      "faceRandomButton": "Facial Recognition Random Button",
      "addOption": "Add Option",
      "availableCountAndValidity": "Available Count and Validity",
      "enterAvailableCount": "Enter Available Count",
      "selectDate": "Select Date",
      "delete": "Delete",
      "enterEnterpriseName": "Enter Enterprise Name",
      "enterUnifiedSocialCreditCode": "Enter Unified Social Credit Code",
      "search": "Search",
      "loading": "Loading",
      "customize": "Customize",
      "enterpriseName": "Enterprise Name",
      "unifiedSocialCreditCode": "Unified Social Credit Code",
      "select": "Select",
      "confirm": "Confirm"
    },
    "builderLicense": {
      "title": "Construction Permit Details",
      "builderLicense": "Construction Permit",
      "add": "Add Construction Permit",
      "certificate": "Construction Permit Certificate",
      "number": "Construction Permit Number",
      "remark": "Remark",
      "delete": "Delete",
      "confirmDelete": "Are you sure you want to delete this construction permit?",
      "deleteConfirmation": "Delete Confirmation",
      "confirm": "Confirm",
      "cancel": "Cancel",
      "number": "Construction Permit Number",
      "enterNumber": "Enter Construction Permit Number",
      "certificate": "Construction Permit Certificate",
      "remark": "Remark",
      "enterRemark": "Enter Remark",
      "numberRequired": "Construction permit number cannot be empty",
      "certificateRequired": "Construction permit certificate cannot be empty"
    },
    "rule": {
      "ruleList": "Permission Group List",
      "addRule": "Add Permission Group",
      "available": "Available",
      "disabled": "Disabled",
      "view": "View",
      "userManagement": "User Management",
      "delete": "Delete",
      "corpName": "Corporate Name",
      "ruleName": "Permission Group Name",
      "ruleStatus": "Permission Group Status",
      "viewRule": "View Permission Group",
      "userManagement": "User Management",
      "confirmDelete": "Deleting the permission group will revoke the permission of the members. Are you sure you want to continue?",
      "warning": "Warning",
      "confirm": "Confirm",
      "cancel": "Cancel",
      "cancelDelete": "Delete canceled",
      "editRule": "Edit Permission Group",
      "addRule": "Add Permission Group",
      "corpName": "Corporate Name",
      "selectCorp": "Select Corporate",
      "ruleName": "Permission Group Name",
      "enterRuleName": "Enter Permission Group Name",
      "ruleStatus": "Status",
      "havePermissions": "Have Permissions",
      "update": "Update",
      "create": "Create",
      "selectCorp": "Corporate must be selected",
      "enterRuleName": "Enter permission group name",
      "selectPermissions": "Please select at least one permission",
      "searchToAdd": "Enter user name or phone number to search for adding users",
      "noData": "No data available",
      "roleMembers": "Permission Group Members"
    },
    "saleSetting": {
      "salesDealer": "Project Sales/Dealer",
      "bindSales": "Bind Sales Personnel",
      "select": "Select",
      "purchaseQuantity": "Device Purchase Quantity",
      "normalFaceDevice": "Normal Facial Recognition Device",
      "temperatureFaceDevice": "Temperature Measurement Facial Recognition Device",
      "normalDustDevice": "Normal Dust Detection Device",
      "dust3CDevice": "3C Dust Detection Device",
      "certificationIntegration": "Real-name System Interface Quantity",
      "dustIntegration": "Dust Interface Quantity",
      "videoIntegration": "Video Interface",
      "enterQuantity": "Enter the number of devices purchased for this project",
      "availableModules": "Available Modules",
      "save": "Save"
    },
    "integrator": {
      "management": "Integrator Management",
      "integratorName": "Integrator Name",
      "enterIntegratorName": "Enter Integrator Name",
      "includeDevice": "Include Device",
      "selectIncludeDevice": "Select Included Device",
      "search": "Search",
      "corpCode": "Corporate Unified Code",
      "corpName": "Corporate Name",
      "accessDevice": "Access Device",
      "managementApply": "Integrator Approval",
      "pass": "Approve",
      "reject": "Reject",
      "approval": "Approval",
      "confirmPass": "Confirm the approval of this integrator? Once approved, the integrator will be able to access personnel data and report device data via the API.",
      "prompt": "Prompt",
      "confirm": "Confirm",
      "cancel": "Cancel"
    },
    "setting": {
      "title": "Personnel Entry Rules",
      "description": "Enter 0 in the input box to indicate that this rule is not in use. If the real-name system is turned off, the age restriction will only apply to registered personnel.",
      "requireRealName": "Require real-name registration for personnel entering the team",
      "childAgeRestriction": "No child labor allowed to enter the team. Child labor refers to those under",
      "maleRetirementAge": "No entry for male personnel at the retirement age of",
      "femaleRetirementAge": "No entry for female personnel at the retirement age of",
      "yearsOld": "years old",
      "saveButton": "Save",
      "requireRealNameRequired": "Real-name registration requirement cannot be empty",
      "childAgeRequired": "Please enter the child labor age",
      "maleRetirementAgeRequired": "Please enter the retirement age",
      "femaleRetirementAgeRequired": "Please enter the retirement age"
    }
  }
}