<template>
  <div class="self-table" id="self-table">
    <el-table
      :data="list"
      border
      size="mini"
      style="width: 100%"
      @header-dragend="setWidth"
      class="table-fixed"
      ref="multipleTable"
      :header-cell-style="{ 'text-align': 'center' , }"
      :cell-style="{ 'text-align': 'center',}"
      v-loading="tableLoad"
      :element-loading-text="$t('global.loading')"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :row-class-name="tableRowClassName"
      @selection-change="userSelect"
      @row-contextmenu="rightClick"
      @row-click="clickTableRow"
    >
    
      <template v-for="(item, index) in tableColumn">
        <!-- 需要特殊处理(插槽) -->
        <el-table-column
          v-if="item.slotName != undefined && $scopedSlots[item.slotName]"
          :key="index"
          :label="item.label"
          :width="item.width"
          :fixed="item.fixed"
          :align="item.align"
        >
          <template slot-scope="scope">
            <slot
              :name="item.slotName"
              :data="scope.row"
              :index="scope.$index"
            ></slot>
          </template>
        </el-table-column>
        <!-- 不需要特殊处理 (无插槽)-->
        <el-table-column
          v-if="item.slotName == undefined && item.type==undefined"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.width"
        >
          <template slot-scope="scope">
            <span>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>


        <!-- 多选框 -->
        <el-table-column
          v-if="item.type !=undefined"
          :key="index"
          :type="item.type"
          :fixed="item.fixed"
          :width="item.width"
          :sortable="item.sortable"
        >
        </el-table-column>
      </template>
    </el-table>
    <!-- 右键菜单 -->
    <div class="menuDiv" :style="menuStyle">
      <ul class="menuUl">
        <span v-for="(item, index) in tableColumn"
            :key="index"
            >
            <li v-if="item.copy"  @click.stop="infoClick(item)">
              复制{{ item.label }}
            </li>
          </span>
      </ul>
    </div>
    <Pages
      @scrollChange="scrollChange"
      @sizeChange="sizeChange"
      @currentChange="currentChange"
      :tableWidth="tableWidth"
      :limit.sync="searchForm.limit"
      :page.sync="searchForm.page"
      :total="tabelTotal"
      :width="headerWidth"
    >
    </Pages>
  </div>
</template>

<script>
import stacky from "../utils/table-sticky";
import Pages from "@/components/page.vue";
import axios from "@/utils/axios.js";

export default {
  components: {
    Pages,
  },
  name: "tables",
  props: {
    tableColumn: {
      type: Array,
      default: () => [],
    },
    formData: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [stacky],
  data() {
    return {
      parent:'self-table',
      copyData:{},
      menuStyle:{
        'display':'none',
      },
      func :  '/getList',
      controller: '',
      tableLoad: false,
      headerWidth: 0,
      list: [],
      index: null,
      listUrl: [],
      searchForm: {
        limit: 30,
        page: 1,
      },
      tabelTotal: 0,
    };
  },
  watch:{
    tableColumn(){
      this.setScrollXWidth();
    }
  },
  methods: {
    getParents(element, className) {
      //dom.getAttribute('class')==dom.className，两者等价
      var returnParentElement = null;
      function getpNode(element, className) {
        //创建父级节点的类数组
        let pClassList = element.parentNode.getAttribute('class');
        let pNode = element.parentNode;
        if (!pClassList) {
          //如果未找到类名数组，就是父类无类名，则再次递归
          getpNode(pNode, className);
        } else if (pClassList && pClassList.indexOf(className) < 0) {
          //如果父类的类名中没有预期类名，则再次递归
          getpNode(pNode, className);
        } else if (pClassList && pClassList.indexOf(className) > -1) {
          returnParentElement = pNode;
        }
      }
      getpNode(element, className);
      return returnParentElement;
    },

    // table的右键点击当前行事件
    rightClick(row, column, event) {
      this.copyData = row;
      this.menuStyle.display = "none";
      let obj = this.getParents(document.getElementById('self-table'),'el-card__body'),
      left = obj.getBoundingClientRect().left,
      top = obj.getBoundingClientRect().top;
      //阻止元素发生默认的行为
      event.preventDefault();
      // 根据事件对象中鼠标点击的位置，进行定位
      this.menuStyle.left = event.clientX - left + "px";
      this.menuStyle.top = event.clientY - top + "px";
      // 改变自定义菜单的隐藏与显示
      this.menuStyle.display = "block";
    },
    // table的左键点击当前行事件
    clickTableRow(row, column, event) {
      this.menuStyle.display = "none";
    },
    // 自定义菜单的点击事件
    infoClick(item) {
      let param = item.prop || item.slotName;
      navigator.clipboard.writeText(this.copyData[param]);
      this.$message.success('复制成功！');
      this.menuStyle.display = "none";
    },
    scrollChange(x) {
      this.$refs.multipleTable.bodyWrapper.scrollLeft = x;
    },
    getList(func='',controller='') {
      this.$store.commit('changePopUp',true);
      if(func)  this.func = func;
  
      if(controller) this.controller = controller;
      this.tableLoad = true;
      this.searchForm = { ...this.searchForm, ...this.formData };
      //安全培训需要修改
      this.module = this.listUrl[0];
      if(this.module =='corpSafetytrain') this.module = 'safetytrain';

      this.controller = controller || this.controller || this.listUrl[1];
      var api = this.module+ "/admin/v1/" + this.controller + this.func ;
      axios
        .get(api, { params: this.searchForm })
        .then((res) => {
          this.list = res.data.list;
          this.tabelTotal = res.data.count;
          this.$emit("listData", res.data.list,res.data.other || {} );
        })
        .finally(() => {
          this.tableLoad = false;
        });
    },
    // 切换每页条数
    sizeChange(data) {
      this.searchForm.limit = data;
      this.tableLoad = true;
      this.getList(this.func,this.controller);
    },
    getData(index) {
      var ruleForm = {};
      if (index !== undefined) {
        ruleForm = {...this.list[index]}
      }
      this.index = index;
      this.$emit("getData", ruleForm);
    },
    handleSave(ruleForm) {
      if (this.index == null) {
        this.list.unshift(ruleForm);
        this.tabelTotal++;
      } else {
        this.$set(this.list, this.index, ruleForm);
      }
    },
    // 分页
    currentChange(data) {
      this.searchForm.page = data;
      this.tableLoad = true;
      this.getList(this.func,this.controller);
    },
    // 吸顶效果
    setWidth() {
      this.headerDragend();
    }, 
   remove(index) {
      this.list.splice(index, 1);
      this.tabelTotal--;
    },

    /**
     * @Descripttion: index 加入到row数据
     * @name: tableRowClassName
     * @Author: Terry
     * @Date: 2021-03-26 18:33:09
     * @param {*} row 每一列的数据
     * @param {*} rowIndex 每一列的下标
     */
    tableRowClassName({ row, rowIndex }) {
      row.rowIndex = rowIndex;
    },
    /**
     * @Descripttion: 表格勾选中的用户
     * @name: userSelect
     * @Author: Terry
     * @Date: 2021-03-26 17:13:29
     * @param {*} selection 选中的数据
     */
     userSelect(selection) {
      this.$emit('select',selection)
    },
    // 删除
    destroy(index, uuid) {
        this.$confirm(this.$t("global.deleteConfirmation"), this.$t("global.delete"), {
          confirmButtonText: this.$t("global.confirm"),
          cancelButtonText: this.$t("global.cancel"),
          type: "warning",
        }).then(() => {
          var api = this.module + "/admin/v1/" + this.controller + '/destroy' ;
          axios.delete(api, {data:{ uuid: uuid }})
          .then((res) => {
            this.load = this.loading();
            this.remove(index);
          })
          .finally(() => {
            this.load.close();
          });

        }).catch(()=>{})
      },

  },
  mounted() {
    var listUrl = this.$route.path.split("/").filter((item) => {
      return item != "modules" && item != "";
    });
    this.listUrl = listUrl;
  },
  destroyed(){
    this.$store.commit('changePopUp',true)
  }
};
</script>

<style lang="scss" scoped>
.el-table {
  /deep/ .el-table__fixed {
    height: 100% !important; //设置高优先，以覆盖内联样式  但是会遮挡滚动条
  }


  /deep/ .cell{
  word-break:normal !important;
  white-space: pre-wrap;
}
}


.table-fixed {
  /deep/ .el-table__fixed-right {
    height: 100% !important;
}
  /deep/ .el-table_fixed {
    height: 100% !important;
  }
}

/deep/ .el-table--scrollable-y .el-table__body-wrapper {
  overflow-y: inherit !important;
}
/deep/ .el-table__body-wrapper {
  overflow: hidden !important;
}
.menuDiv {
  display: none;
  position: absolute;
  z-index:1000;
  .menuUl {
    padding-inline-start :0px !important;
    height: auto;
    width: auto;
    font-size: 14px;
    text-align: center;
    border: none;
    background-color: #fff;
    color: #444;
    list-style: none;
    box-shadow: 2px 4px 98px -12px rgba(0,0,0,1);
    -webkit-box-shadow: 2px 4px 98px -12px rgba(0,0,0,1);
    -moz-box-shadow: 2px 4px 98px -12px rgba(0,0,0,1);
    li {
      width: 140px;
      height: 35px;
      line-height: 35px;
      cursor: pointer;
      border-bottom: 1px solid rgba(255, 255, 255, 0.47);
      &:hover {
        background-color: #c4c4c4;
        // color: rgb(54, 138, 175);
      }
    }
  }
}

</style>
