/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-09 18:19:48
 * @Description: file content
 * @FilePath: \auxface_web\src\views\greenSite\lang\zh-cn.jsx
 */
export default {
  greenSite:{
    router:{
      greenSite: '绿色工地',
      dustDevices: '扬尘设备',
      electricMeterManagement: '电表管理',
      waterMeterManagement: '水表管理'
    },
    dust: {
        dustDeviceManagement: '扬尘设备管理',
        addDevice: '设备添加',
        enterDeviceName: '请输入设备名称',
        integrator: '集成商',
        selectIntegrator: '请选择集成商',
        search: '查询',
        online: '在线',
        onlineStatus: '在线状态',
        detail:"详细数据",
        offline: '离线',
        noRemark: '无备注',
        noData: '无数据',
        northWind: '北风',
        northeastByEast: '东北偏东',
        northeast: '正东北',
        northeastByNorth: '东北偏北',
        eastWind: '东风',
        southeastByEast: '东南偏东',
        southeast: '正东南',
        southeastBySouth: '东南偏南',
        southWind: '南风',
        southwestBySouth: '西南偏南',
        southwest: '正西南',
        southwestByWest: '西南偏西',
        westWind: '西风',
        northwestByWest: '西北偏西',
        northwest: '正西北',
        northwestByNorth: '西北偏北',
        detailData: '详细数据',
        remove: '删除',
        deviceName: '设备名称',
        deviceNamePlaceholder: '请输入设备名称',
        deviceSerialNumber: '设备序列号',
        deviceSerialNumberPlaceholder: '请输入设备序列号',
        integratorPlaceholder: '请选择集成商',
        remark: '备注',
        remarkPlaceholder: '请输入备注',
        deviceNameRequired: '请输入设备名称',
        deviceNameLength: '长度在 1 到 40 个字符',
        integratorRequired: '集成商不能为空',
        deviceSerialNumberRequired: '请输入设备序列号',
        deviceSerialNumberLength: '长度在 1 到 40 个字符',
        noise: '噪音(dB)',
        windDirect: '风向',
        windSpeed: '风速(m/s)',
        temp: '温度(℃)',
        humid: '湿度(%RH)',
        atoms: '气压(kpa)',
        inDate: '上传时间',
        confirmDeleteDevice: '是否确认删除此设备?',
        deleteConfirmation: '删除确认',
        confirm: '确定',
        cancel: '取消',
        title:'扬尘设备管理详情',
        deviceDetails: '设备详细信息',
        dateLabel: '日期：',
        dateSeparator: '至',
        startDatePlaceholder: '开始日期',
        endDatePlaceholder: '结束日期',
        searchButton: '查询'
      },
      electric:{
        title:'电表管理详情',
        electricMeterManagement: '电表检测管理',
        selectIntegrator: '请选择集成商',
        search: '查询' ,
        electricMeterManagement: '电表检测管理',
        addEquipment: '设备添加',
        offline: '离线',
        online: '在线',
        noRemark: '无备注',
        noData: '无数据',
        detailData: '详细数据',
        delete: '删除',
        integrator: "集成商",
        deviceName: "设备名称",
        deviceSerialNumber: "设备序列号",
        onlineStatus: "在线状态",
        remark: "备注",
        electricMeterReading: "电表读数(kWh)",
        uploadTime: "上传时间",
        confirmDeleteDevice: "是否确认删除此设备?",
        deleteConfirmation: "删除确认",
        confirm: "确定",
        cancel: "取消",
        enterDeviceName: "请输入设备名称",
        enterDeviceSerialNumber: "请输入设备序列号",
        enterDeviceName: "请输入设备名称",
        nameLength: "长度在 1 到 40 个字符",
        integratorRequired: "集成商不能为空",
        enterDeviceSerialNumber: "请输入设备序列号",
        codeLength: "长度在 1 到 10 个字符",
        deviceDetails: "设备详细信息",
        date: "日期：",
        to: "至",
        startDate: "开始日期",
        endDate: "结束日期",
        search: "查询",
        recentSevenDaysElectricity: "最近七天用电(kWh)",
        powerConsumption: "用电量",
        todayElectricity: "今日用电 {reading} kWh",
        deviceName: "设备名称",
        enterDeviceName: "请输入设备名称"
      },
      water: {
        title:'水表详情',
        waterMeterManagement: "水表检测管理",
        addEquipment: "设备添加",
        deviceName: "设备名称",
        enterDeviceName: "请输入设备名称",
        integrator: "集成商",
        selectIntegrator: "请选择集成商",
        search: "查询",
        offline: "离线",
        online: "在线",
        noRemark: "无备注",
        noData: "无数据",
        detailData: "详细数据",
        deviceName: "设备名称",
        deviceSerialNumber: "设备序列号",
        onlineStatus: "在线状态",
        remark: "备注",
        waterMeterReading: "水表读数",
        uploadTime: "上传时间",
        delete: "删除",
        confirmDeleteDevice: "是否确认删除此设备?",
        deleteConfirmation: "删除确认",
        confirm: "确定",
        cancel: "取消",
        deviceDetails: "设备详细信息",
        date: "日期：",
        to: "至",
        startDate: "开始日期",
        endDate: "结束日期",
        search: "查询",
        todayWater: "今日用水 {reading} kWh",
        recentSevenDaysWater:'一周用水(吨)',
        powerConsumption:'用水量',
        ton: "吨",
        enterDeviceName: "请输入设备名称",
        enterDeviceSerialNumber: "请输入设备序列号",
        enterDeviceName: "请输入设备名称",
        integratorRequired: "集成商不能为空",
        enterDeviceSerialNumber: "请输入设备序列号",
        characterLength: "长度在 1 到 10 个字符"
      }
    }
}
  