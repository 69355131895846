/*
 * @Author: Terry
 * @Date: 2023-12-0315:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2024-03-29 21:52:41
 * @Description: file content
 * @FilePath: \auxface_web\src\views\corp\lang\en.jsx
 */
export default {
  corpSafetytrain: {
    router:{
      safetyTrainingEnterprise: 'Safety Training - Enterprise Edition',
      courseCenter: 'Course Center',
      classificationManagement: 'Classification Management',
      paperManagement: 'Paper Management',
      questionBankManagement: 'Question Bank Management'
    },
    classCenter: {
      title:'Classification Details',
      categoryManagement: 'Classification Management',
      addCategory: 'Add Category',
      applicablePersonnel: 'Applicable Personnel',
      selectApplicablePersonnel: 'Select Applicable Personnel',
      search: 'Search',
      detailInformation: 'Detail Information',
      delete: 'Delete',
      noRemark: 'No Remark',
      confirmDeleteCategory: 'Confirm to delete this category?',
      deleteConfirmation: 'Deletion Confirmation',
      confirm: 'Confirm',
      cancel: 'Cancel',
      sort: 'Sort',
      category: 'Category',
      enterCategoryName: 'Enter category name',
      applicablePersonnel: 'Applicable Personnel',
      selectApplicablePersonnel: 'Select Applicable Personnel',
      remark: 'Remark',
      remarkPlaceholder: 'Enter remark here',
      nameRequired: 'Category name is required',
      sortRequired: 'Please select sorting position',
      workerRoleRequired: 'Applicable personnel is required',
      sort: 'Sort',
      name: 'Name',
      workerRole: 'Applicable Personnel',
      remark: 'Remark',
      detail: 'Detail',
      delete: 'Delete',
    },
    questionBank: {
      title:'Question Bank Management',
      questionBankManagement: 'Question Bank Management',
      addQuestion: 'Add Question',
      selectCategory: 'Select Category:',
      selectCategoryPlaceholder: 'Select Category',
      enterQuestionName: 'Enter question name',
      enterQuestionNamePlaceholder: 'Enter question name',
      search: 'Search',
      questionName: 'Question Name',
      authorName: 'Author',
      category: 'Category',
      uploadTime: 'Upload Time',
      delete: 'Delete',
      confirmDelete: 'Confirm to delete this question?',
      deleteConfirmation: 'Deletion Confirmation',
      confirm: 'Confirm',
      cancel: 'Cancel',
      questionName: 'Question Name',
      enterQuestionName: 'Enter question name',
      image: "Image:",
      uploadSuccess: "Upload Success",
      author: "Author:",
      enterAuthorName: "Enter author name",
      category: "Category:",
      selectCategory: "Select Category",
      questionType: "Question Type:",
      singleChoice: "Single Choice",
      multipleChoice: "Multiple Choice",
      options: "Question Options:",
      addOption: "Add Option",
      delete: "Delete",
      answer: "Question Answer:",
      enterQuestionName: "Enter question name",
      lengthBetween1And50: "Length between 1 and 50 characters",
      enterAuthorName: "Enter author name",
      lengthBetween1And10: "Length between 1 and 10 characters",
      enterOptions: "Enter options",
      enterScore: "Enter score",
    },
    paper: {
      title:'Paper Details',
      paperManagement: 'Paper Management',
      addPaper: 'Add Paper',
      examName: "Paper Name:",
      enterExamName: "Enter paper name",
      classification: "Category:",
      selectClassification: "Select Category",
      search: "Search",
      questionData: 'Question Data',
      delete: 'Delete',
      examName: 'Paper Name',
      authorName: 'Author',
      classUuid: 'Category ID',
      detail: 'Detail',
      uploadTime: 'Upload Time',
      delete: 'Delete',
      confirmDelete: 'Confirm to delete this paper?',
      deleteConfirmation: 'Deletion Confirmation',
      confirm: 'Confirm',
      cancel: 'Cancel',
      examName: 'Paper Name',
      enterExamName: 'Enter paper name',
      author: 'Author',
      enterAuthorName: 'Enter author name',
      category: 'Category',
      selectCategory: 'Select Category',
      totalScore: 'Total Score',
      passingScore: 'Passing Score',
      enterPassingScore: 'Enter content',
      enterExamName: 'Enter paper name',
      examNameLength: 'Length between 1 and 50 characters',
      selectCategory: 'Select Category',
      examDetailData: 'Paper Question Detailed Data',
      syncSubject: 'Sync Questions',
      addSubject: 'Add Questions',
      enterQuestionName: 'Enter question name',
      questionScore: 'Question Score',
      enterQuestionScore: 'Enter question score',
      questionType: 'Question Type',
      selectQuestionType: 'Select question type',
      search: 'Search',
      image: "Image",
      uploadTime: "Upload Time",
      singleChoice: "Single Choice",
      multipleChoice: "Multiple Choice",
      uploadTime: "Upload Time",
      delete: "Delete",
      singleChoice: "Single Choice",
      multipleChoice: "Multiple Choice",
      confirmDeleteQuestion: "Confirm to delete this question?",
      deleteConfirmation: "Deletion Confirmation",
      confirm: "Confirm",
      cancel: "Cancel",
      score: "Score",
      answer: "Question Answer:",
      questionNameLength: "Length between 1 and 50 characters",
      enterAuthorName: "Enter author name",
      authorNameLength: "Length between 1 and 10 characters",
      enterOptions: "Enter options",
      enterScore: "Enter score",
      addOption:'Add Option',
      questionName: "Question Name",
      questionStatus: "Question Type",
      classification: "Question Category",
      batchSelectScore: "Batch Select Score",
      score: "Score",
      selectClassification: "Select Category",
      questionType: 'Question Type',
      multipleChoice: 'Multiple Choice',
      singleChoice: 'Single Choice',
      author: 'Author',
      score: 'Score',
      add :'Add',
      image: "Image",
      singleChoice: 'Single Choice',
      multipleChoice: 'Multiple Choice',
      selectQuestionToAdd: 'Select question to add'
    },
    courseCenter: {
      detailTitle: 'Course Catalog Details',
      title: 'Course Center Details',
      courseCenter: 'Course Center',
      courseAdd: 'Add Course',
      search: 'Search',
      selectCategory: 'Select Category',
      selectApplicablePerson: 'Select Applicable Person',
      enterCourseName: 'Enter course name',
      noDescription: 'No Description',
      delete: 'Delete',
      sort: 'Sort',
      courseName: 'Course Name',
      image: 'Image',
      category: 'Category',
      applicablePerson: 'Applicable Personnel',
      publishTime: 'Publish Time',
      courseDescription: 'Course Description',
      catalogInfo: 'Catalog Information',
      confirmDeleteCourse: 'Confirm to delete this course?',
      deleteConfirmation: 'Deletion Confirmation',
      confirm: 'Confirm',
      cancel: 'Cancel',
      courseImage: 'Course Image',
      enterCourseDescription: 'Enter course description',
      courseNameRequired: 'Course name is required',
      sortRequired: 'Please select sorting position',
      categoryRequired: 'Course category is required',
      imageRequired: 'Image is required',
      applicablePersonRequired: 'Applicable personnel is required',
      courseDescriptionRequired: 'Course description is required',
      changeVideo: 'Change Video',
      uploadFailed: 'Upload Failed',
      invalidVideoFormat: 'Please upload the correct video format',
      videoSizeExceeded: 'Uploaded video size cannot exceed 1024M!',
      uploadLimit: 'Currently limited to upload 1 file',
      uploadOnlyDocument: 'Uploaded file can only be in document format!',
      documentSizeExceeded: 'Uploaded document size cannot exceed 5MB!',
      changeDocument: 'Change Document',
      deleteConfirmationMessage: 'Confirm to delete this course?',
      deleteConfirmationTitle: 'Deletion Confirmation',
      chapterName: 'Chapter Name',
      fileType: 'Type',
      describe: 'Content Description',
      publishTime: 'Publish Time',
      remark: 'Remark',
      selectCategoryPlaceholder: 'Select paper category',
      enterExamNamePlaceholder: 'Enter paper name',
      examCategory: 'Paper Category',
      examName: 'Paper Name',
      sync: 'Sync',
      chapterName: 'Chapter Name:',
      enterChapterName: 'Enter chapter name',
      fileType: 'Type:',
      selectType: 'Select type',
      sort: 'Sort:',
      syncChapterExam: 'Chapter paper can be synchronized',
      chapterExam: 'Chapter Paper:',
      syncExam: 'Sync Paper',
      minStudyTime: 'Minimum Study Time:',
      minutes: 'mins',
      seconds: 'secs',
      contentIntro: 'Content Description:',
      enterContentIntro: 'Enter content description',
      media: 'Media:',
      remark: 'Remark:',
      enterRemark: 'Enter remark',
      chapterNameRequired: 'Chapter name is required',
      contentIntroRequired: 'Content description is required',
      sortRequired: 'Please select sorting position',
      mediaRequired: 'Media is required',
      courseTypeRequired: 'Course type is required',
      minStudyTimeRequired: 'Minimum study time is required',
    }
  }
}