/*
 * @Descripttion: 视频监控板块路由列表
 * @version: 
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Terry
 * @LastEditTime: 2024-08-29 21:53:33
 */ 
export  default  [
  { name: 'player', path: '/modules/video/player', component: ()=>import("@/views/video/player/Index.vue"), meta: {title: 'video.router.playPage'} },
  { name: 'playBack', path: '/modules/video/playBack', component: ()=>import("@/views/video/camera/playBack/Index.vue"), meta: {title: 'video.router.monitorPlayback'} },
  { name: 'cloudBack', path: '/modules/video/cloudBack', component: ()=>import("@/views/video/camera/playBack/Cloud.vue"), meta: {title: 'video.router.cloudBack'} },
  {
    path: '/modules/video/',
    name: 'videoIndex',
    component: ()=>import("@/views/Index.vue"),
    meta: {title: 'video.router.videoMonitoring'},
    redirect: { path: '/modules/video/' },
    children: [
      { name: 'home', path: '/', component: ()=>import("@/views/video/home/Index.vue") ,meta: {title: 'video.router.home',breadcrumb: false} },
      { name: 'camera', path: 'camera/list', component: ()=>import("@/views/video/camera/Index.vue") ,meta: {title: 'video.router.camera'} },
      { name: 'cameraChannel', path: 'camera/channel', component: ()=>import("@/views/video/camera/Channel.vue") ,meta: {title: 'video.router.channel'} },
      { name: 'config', path: 'config', component: ()=>import("@/views/video/account/Index.vue"), meta: {title: 'video.router.config'} },
    ]
  }
];
