/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2024-01-22 18:40:56
 * @Description: file content
 * @FilePath: \auxface_web\src\views\platform\lang\en.jsx
 */
export default {
  platform:{
    router: {
      title: 'Edan Smart Platform',
      projectCreateTitle: 'Add Project',
      projectListTitle: 'Project List',
      accountListTitle: 'Account Management',
      corpAuditTitle: 'Corporate Audit',
      corpListTitle: 'Corporate List',
      governmentListTitle: 'Government Accounts',
      userCenterTitle: 'User Center',
      scaffoldManagement: 'Scaffold Management',
    },
    auth:{
      accountLabel: "Account",
      passwordLabel: "Password",
      bigTitle:"Edan Smart Platform",
      welcome:"Hello, Welcome to",
      title:"Edan Smart Platform",
      loginBtn:"Log in",
      account:"Account",
      password:"Password",
      forget:"Forgot",
      notAccount:"Don't have an account?",
      register:"Register",
      accountTips:"Please enter account/phone number",
      passwordTips:"Please enter password",
      accountLogin:"Login",
      scanLogin:"Scan to Login",
      leftTip1:"Official WeChat",
      leftTip2:"Scan using WeChat",
      leftTip3:'or search for the official account "Construction Industry"',
      rightTip1:"WeChat Mini Program",
      rightTip2:"Scan using WeChat",
      rightTip3:'or search for the official account "Smart Construction Site Mobile End"',
      accountRequired:'Account cannot be empty',
      pwdRequired:'Password cannot be empty',
      pwdLen:'Password length is between 6-16 characters',
      pwdAgain:'Please enter password again',
      phoneTips:'Please enter phone number',
      rePwdTips:'Please enter password again',
      verifyTips:'Please enter verification code',
      getVerify:'code',
      login:'Log in',
      resetBtn:'Reset Password',
      qrCodeFail:'QR Code expired',
      refresh:'Refresh',
      hasAccount:'Already have an account?',
      registerBtn:'Register Account',
      slideTips:'Slide to the right',
    },
    project: {
      name: 'Project Name',
      nameTips: 'Please enter the project name',
      searchBtn: 'Search',
      rate: 'Attendance Rate',
      enterPrj: 'Enter Project',
      noPermissions: 'No permission for this project',
      unjointed: 'Unjointed',
      normal: 'Normal',
      member: 'Member',
      memberTips: 'Member joint exception',
      attendance: 'Attendance',
      team: 'Team',
      errorTips: 'Error joint',
      corpName: 'General Contracting Unit',
      code: 'Project Code',
      linkMan: 'Contact Person',
      linkPhone: 'Contact Phone',
      teamCount: 'On-site teams',
      attendanceCount: 'Today’s attendance/Total number',
      error: 'Real-name system joint exception (clickable)',
      // create
      recordNumber: 'Project Investment Filing Number',
      enterRecordNumber: 'Please enter the project investment filing number',
      enterName: 'Please enter project name',
      enterLinkMan: 'Please enter contact person name',
      enterLinkPhone: 'Please enter contact person phone',
      address: 'Project Address',
      enterAddress: 'Please enter project address',
      area: 'Area',
      selectCategory: 'Select project category',
      participatingUnits: 'Participating Units',
      companyName: 'Company Name',
      enterCompanyName: 'Please enter company name',
      delete: 'Delete',
      participatingType: 'Participating Type',
      selectParticipatingType: 'Select participating type',
      createNewUnit: 'Create New Participating Unit',
      categoryLabel: 'Project Category',
      builderLicense: 'Construction Permit',
      builderLicenseNum: 'Construction Permit Number:',
      enterBuilderLicenseNum: 'Please enter the construction permit',
      builderLicenseImg: 'Construction Permit Image:',
      addBuilderLicense: 'Add construction permit',
      modules: 'Modules in use',
      add: 'Add',
      selectProjectStatus: 'Select project status',
      enterProjectAddress: 'Please enter project address',
      enterContactName: 'Please enter contact person name',
      enterContactPhone: 'Please enter contact person phone',
      selectModule: 'Select module',
    },
    home:{
      welcomeBack: 'Welcome back,',
      welcomeMessage: 'Welcome back to the Smart Building Console',
      overviewTitle: 'Cost - Overview',
      recharge: 'Recharge',
      noticeTitle: 'Announcements - Notices',
      noNotice: 'No notifications for now~',
      notice1: '12312',
      notice2: '12312',
      notice3: '12312',
      more: 'More',
      recentExpensesTitle: 'Cost - Recent Expenses',
      currentMonthExpenses: 'Current month expenses',
      compareLastMonth: 'Compare to last month',
      viewMoreExpenseDetails: 'View more expense details',
      recentOperationsTitle: 'Recent Operations'
    },
    user: {
      title: 'Security Settings',
      accountName: 'Account name',
      accountNameNote: '(cannot be changed after setting)',
      setAccount: 'Set',
      passwordManagement: 'Password Management',
      changePassword: 'Change',
      weChatID: 'WeChat ID',
      bind: 'Bind',
      phoneNumber: 'Phone number',
      unbindWeChat: 'Unbind WeChat',
      scanQrCode: 'Scan WeChat QR code to unbind',
      verifyUnbinding: 'Unbind verification',
      unbindPhone: 'Unbind phone',
      phoneNumber: 'Phone number',
      enterPhoneNumber: 'Please enter phone number',
      verificationCode: 'Verification code',
      getVerificationCode: 'code',
      slideRight: 'Slide to the right',
      enterVerificationCode: 'Please enter verification code',
      confirm: 'Confirm',
      phoneRequired: 'Phone number cannot be empty',
      bindPhone: 'Bind phone',
      phoneNumber: 'Phone number',
      verificationCode: 'Verification code',
      enterPhoneNumber: 'Please enter phone number',
      enterVerificationCode: 'Please enter verification code',
      getVerificationCode: 'code',
      set: 'Set',
      slideRight: 'Slide to the right',
      wxBinding: "WeChat binding",
      wxScan: "Scan WeChat QR code to bind",
      bindingVerification: "Binding verification",
      accountName: "Account name",
      enterAccountName: "Please enter account name",
      cancel: "Cancel",
      set: "Set",
      accountRequired: "Account cannot be empty",
      changePassword: "Change password",
      password: "Password",
      enterPassword: "Please enter the password you want to change",
      confirmPassword: "Please confirm password",
      change: "Change",
      accountConfirmation: "Account cannot be changed after setting, do you want to use {account}?",
      accountReminder: "Account setting reminder",
      confirm: "Confirm",
    },
    modules:{
      selectApp: 'Please select the application you want to operate',
      openNewPage: 'Open a new page',
      reloginMessage: 'Please re-login due to long inactivity',
    },
    corp: {
      unifiedSocialCreditCode: 'Unified Social Credit Code',
      editInfo: 'Edit Information',
      cancelEdit: 'Cancel Edit',
      labelName: 'Company Name',
      labelCreditCode: 'Unified Social Credit Code',
      labelRegistrationType: 'Company Registration Type',
      labelCapitalCurrency: 'Capital Currency',
      registeredCapital: 'Registered Capital (Ten Thousand)',
      paidCapital: 'Paid-up Capital (Ten Thousand)',
      registerDate: 'Registration Date',
      establishDate: 'Establishment Date',
      placeholderRegisteredCapital: 'Please enter registered capital, in ten thousand',
      placeholderPaidCapital: 'Please enter paid-up capital, in ten thousand',
      selectDateTime: 'Select date and time',
      placeholderRegistrationType: 'Please select company registration type',
      placeholderCapitalCurrency: 'Please select capital currency',
      legalRepresentativeName: 'Legal Representative Name',
      legalRepresentativeDuty: 'Legal Representative Duty',
      enterLegalRepresentativeDuty: 'Please enter the legal representative duty',
      legalRepresentativeTitle: 'Legal Representative Title',
      enterLegalRepresentativeTitle: 'Please enter the legal representative title',
      legalRepresentativeIdCardType: 'Legal Representative ID Card Type',
      selectIdCardType: 'Select ID card type',
      legalRepresentativeIdCardNumber: 'Legal Representative ID Card Number',
      enterLegalRepresentativeIdCardNumber: 'Please enter the legal representative ID card number',
      contactPersonName: 'Contact Person Name',
      enterContactPersonName: 'Please enter contact person name',
      contactPersonPhone: 'Contact Person Phone',
      enterContactPersonPhone: 'Please enter contact person phone',
      officePhone: 'Office Phone',
      enterOfficePhone: 'Please enter office phone',
      faxNumber: 'Fax Number',
      enterFaxNumber: 'Please enter fax number',
      zipCode: 'Postal Code',
      enterZipCode: 'Please enter postal code',
      companyEmail: 'Company Email',
      enterCompanyEmail: 'Please enter company email',
      companyWebsite: 'Company Website',
      enterCompanyWebsite: 'Please enter company website',
      businessLicense: 'Business License',
      submitNow: 'Submit Now',
      emailFormat: 'Please enter a correct email address',
      invalidDate: "You can't select a date past today"
    }
  }
}