<template>
    <div  ref="page" style="height: auto;" >
      <div class="fixed-btn"
        :style="divStyle"
        ref="content"
        >
        <virtualScroll
          @scrollChange="scrollChange"
          :tableWidth="tableWidth"
          :width="width"
        ></virtualScroll>
        <div class="flex">
          <div class="left"><slot></slot></div>
          <div class="right">
            <el-pagination
              background
              :page-sizes="[10, 20, 30]"
              :current-page="page"
              :page-size="limit"
              @size-change="sizeChange"
              @current-change="currentChange"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
              <span style="margin">
                前往
                <el-input
                  class="self_input"
                  size="mini"
                  @keyup.enter.native="toPage"
                  v-model="pages"
                ></el-input
                >页
                <span @click="toPage" class="go">前往</span>
              </span>
            </el-pagination>
          </div>
        </div>
      </div>
      <div :style="{'height':height+ 'px'}"></div>
  </div>
</template>

<script>
import virtualScroll from "../components/virtualScroll";
import elementResizeDetectorMaker from "element-resize-detector";
export default {
  name: "pages",
  components: { virtualScroll },
  props: {
    width: {
      type: [String, Number],
      default: 0,
    },
    tableWidth: {
      type: [String, Number],
      default: 0,
    },
    limit: {
      type: Number,
      default: 30,
    },
    page: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      height :0,
      pages: 1,
      divStyle:{},
    };
  },
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar.opened;
    },
  },
  methods: {
    scrollChange(x) {
      this.$emit("scrollChange", x);
    },
    sizeChange(e) {
      this.$emit("sizeChange", e);
    },
    currentChange(e) {
      this.pages = e;
      this.$emit("currentChange", e);
    },
    toPage() {
      let page = this.total / this.limit;
      let reslove = page == parseInt(page) ? page : parseInt(page) + 1;
      if (this.pages > reslove) {
        this.pages = reslove;
      }
      this.$emit("update:page", Number(this.pages));
    },
    getContainerkWidth(){
        const erd = elementResizeDetectorMaker(), that = this;
        this.$nextTick(() => {
          this.height = this.$refs.content.offsetHeight;
          erd.listenTo(this.getParents(document.getElementById('self-table'),'el-card__body'), (element) => {
            let left = element.getBoundingClientRect().left + 'px',width=element.offsetWidth + 'px',bottom='20px';
            this.divStyle = { width,left,bottom} ;
            this.divStyle['z-index'] = 10;
          })
        })
      },
      getParents(element, className) {
      //dom.getAttribute('class')==dom.className，两者等价
      var returnParentElement = null;
      function getpNode(element, className) {
        //创建父级节点的类数组
        let pClassList = element.parentNode.getAttribute('class');
        let pNode = element.parentNode;
        if (!pClassList) {
          //如果未找到类名数组，就是父类无类名，则再次递归
          getpNode(pNode, className);
        } else if (pClassList && pClassList.indexOf(className) < 0) {
          //如果父类的类名中没有预期类名，则再次递归
          getpNode(pNode, className);
        } else if (pClassList && pClassList.indexOf(className) > -1) {
          returnParentElement = pNode;
        }
      }
      getpNode(element, className);
      return returnParentElement;
    },
  },
  created(){
    this.getContainerkWidth();
  },
};
</script>

<style lang="scss" scoped>
.self_input {
  width: 48px;
  margin: 0 5px;
}
.go {
  background-color: #66b1ff;
  padding: 0 15px;
  line-height: 28px;
  height: 28px;
  border-radius: 3px;
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    background: #66b1ff;
    border-color: #66b1ff;
    color: #fff;
  }
}
.sidebar {
  left: 210px !important;
}
.fixed-btn {
  background-color: #fff;
  position: fixed;
  right: 0;
  z-index: 99;
  padding: 15px 0 20px 0;
  transition: left 0.2s ease-in-out;
  .flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .right {
    display: flex;
    align-items: center;
    margin-right: 30px;
    &-item {
      // margin-left: 10px;
      font-size: 15px;
      color: #252525;
      span {
        font-weight: bold;
      }
    }
  }
}
</style>
