/*
 * @Descripttion: 
 * @version: 
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Terry
 * @LastEditTime: 2021-12-16 19:48:03
 */ 


export default [
  {
    path: '/integrator',
    name: 'integratorIndex',
    component: ()=>import("@/views/Index.vue"),
    meta: {title:'硬件集成商'},
    redirect: { path: '/integrator' },
    children: [
      {name: 'integratorProject',path :'project',component: ()=>import("@/views/integrator/project/Index.vue")},
      {name: 'integratorProjectApply',path :'project/apply',component: ()=>import("@/views/integrator/project/Apply.vue")},
      //测试项目
      {name: 'integratorTestProject',path :'testProject',component: ()=>import("@/views/integrator/testProject/Index.vue")},
      //人脸设备对接
      {name: 'integratorMachine',path :'machine/list',component: ()=>import("@/views/integrator/machine/Index.vue")},
      {name: 'integratorMachineStaff',path :'machine/staff',component: ()=>import("@/views/integrator/machine/Staff.vue")},
      {name: 'integratorMachineAttendance',path :'machine/attendance',component: ()=>import("@/views/integrator/machine/Attendance.vue")},
      //集成商扬尘管理
      {name: 'integratorDustList',path :'dust/list',component: ()=>import("@/views/integrator/dust/Index.vue")},
      {name: 'integratorDustDetail',path: 'dust/detail',component: ()=>import("@/views/integrator/dust/Details.vue")},
      //集成商电表管理
      {name: 'integratorElectricList',path :'electric/list',component: ()=>import("@/views/integrator/electric/Index.vue")},
      {name: 'integratorElectricDetails',path :'electric/details',component: ()=>import("@/views/integrator/electric/Details.vue")},
      //集成商水表管理
      {name: 'integratorWaterList',path :'water/list',component: ()=>import("@/views/integrator/water/Index.vue")},
      {name: 'integratorWaterDetails',path :'water/details',component: ()=>import("@/views/integrator/water/Details.vue")},
      //集成商升降机管理
      {name: 'integratorElevator',path :'elevator/list',component: ()=>import("@/views/integrator/elevator/Index.vue")},
      {name: 'integratorElevatorDetail',path :'elevator/details',component: ()=>import("@/views/integrator/elevator/Details.vue")},
      //集成商塔吊管理
      {name: 'integratorTowerCrane',path :'towerCrane/list',component: ()=>import("@/views/integrator/towerCrane/Index.vue")},
      {name: 'integratorTowerCraneDetail',path :'towerCrane/details',component: ()=>import("@/views/integrator/towerCrane/Details.vue")},
    ]
  },
];



