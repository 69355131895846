/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-09 18:23:41
 * @Description: file content
 * @FilePath: \auxface_web\src\views\safetytrain\lang\zh-cn.jsx
 */
export default {
  safetytrain:{
    router:{
      safetyTraining: '安全培训',
      userClassroomManagement: '用户课堂管理',
      examManagement: '考试管理',
      examPersonnelManagement: '考试人员管理',
      bannerManagement: '轮播图管理'
    },
    dateOverView: {
      projectCode: '项目编号：',
      totalWorkers: '总人数',
      totalTeams: '班组数',
      examWorkers: '考试人数',
      totalExamsToday: '今日总考试次数',
      totalExams: '总考试数',
      passRate: '通过率',
      examTrend: '考试次数趋势图',
    },
    userCenter: {
      title:'用户课堂管理详情',
      classroomManagement: '用户课堂管理',
      courseName: '课程名称',
      enterCourseName: '请输入课程名称',
      category: '分类',
      selectCategory: '请选择分类',
      applicableUsers: '适用人员',
      selectApplicableUsers: '请选择适用人员',
      search: '查询',
      noRemark: '无备注',
      addedCourses: '已添加课程',
      addCoursesForUnselectedUsers: '为未选择课程的用户进行课程添加',
      unselectedUsers: '未选课程人员',
      filterKeyword: '输入关键字进行过滤',
      selectedUsers: '已选课程人员',
      noData: '暂无数据',
      courseName: '课程名称',
      category: '分类',
      applicableUsers: '适用人员',
      selectionCount: '选择人数',
      remark: '备注',
    },
    exam:{
      examManagement: '考试管理',
      passed: '合格',
      failed: '未合格',
      category: '分类：',
      selectCategory: '请选择分类',
      team: '班组：',
      selectTeam: '请选择所在班组',
      noScore: '暂无成绩',
      name: '姓名',
      examName: '考试名称',
      team: '班组',
      examCategory: '考试分类',
      score: '成绩',
      search: '查询',
    },
    noExam:{
      userManagement: '未考试人员管理',
      name: '姓名:',
      enterName: '请输入姓名',
      search: '查询',
      name: '姓名',
      totalExams: '总考试',
      completedExams: '已考试',
      pendingExams: '未考试',
      enterCourseName: '请输入课程名称',
      search: '查询',
      courseName: '课程名称',
    },
    banner:{
      title:'轮播图管理详情',
      carouselManagement: '轮播图管理',
      addImage: '添加图片',
      detailInfo: '详细信息',
      delete: '删除',
      sort: '排序',
      image: '图片',
      remark: '备注',
      detailInfo: '详细信息',
      confirmDelete: '是否确认删除此轮播图?',
      deleteConfirmation: '删除确认',
      confirm: '确定',
      cancel: '取消',
      selectSortPosition: '请选择排序位置',
      addImage: '请添加图片',  
      sort: '排序：',
      courseImage: '课程图片：',
      remark: '备注：',
      remarkPlaceholder: '备注',
    },
    classCenter: {
      trainings: '安全培训',
      categoryManagement: '分类管理',
      addCategory: '添加分类',
      applicablePersonnel: '适用人员',
      selectApplicablePersonnel: '请选择适用人员',
      search: '查询'
    }
  }
}
  