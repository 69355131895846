/*
 * @Author: Terry
 * @Date: 2023-12-03 17:10:00
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-09 15:49:51
 * @Description: file content
 * @FilePath: \auxface_web\src\views\mobileAttendance\lang\zh-tw.jsx
 */

export default {
  mobileAttendance:{
    router: {
      mobileAttendance: '移動考勤',
      attendanceManagement: '考勤管理',
      faceAttendance: '人臉考勤'
    },
    
    region: {
      header: '圍欄列表',
      display: '圍欄展示',
      createPolygon: '新建圖形',
      startEditing: '開始編輯',
      endEditing: '結束編輯',
      saveFence: '保存圍欄',
      cancelSelection: '取消選中',
      delete: '刪除',
      deleteConfirm: '此操作將永久刪除該操作，是否繼續？',
      prompt: '提示',
      confirm: '確定',
      cancel: '取消',
      cancelDelete: '已取消刪除',
      searchPlaceholder: '輸入地址名稱搜索位置',
      title: '區域信息',
      nameLabel: '區域名稱：',
      namePlaceholder: '請輸入區域名稱',
      remarkLabel: '備註：',
      remarkPlaceholder: '請輸入備註',
      updateButton: '更新',
      addButton: '添加'
    },
    attendance:{
      title:'考勤管理詳情',
      unselectedPersonnel:'未選打卡區域人員',
      management: '考勤管理',
      query: '查詢',
      noRemark: '無備註',
      details:'考勤管理詳情',
      fenceName: '圍欄名稱',
      selectCount: '選擇人數',
      remark: '備註',
      enterFenceName: '請輸入圍欄名稱',
      addPunchForUnselected: '為未選擇打卡區域的用戶進行打卡添加',
      filterKeyword: '輸入關鍵字進行過濾',
      selectedPersonnel: '已選打卡區域人員',
      noData: '暫無數據',
    },
    face:{
      workerName: '人員名稱',
      attendance: '人臉考勤',
      workerName: '人員名稱',
      enterWorkerName: '請輸入人員名稱',
      query: '查詢',
      name: '姓名',
      originalImage: '原圖',
      randomImage: '隨機後圖'
    }
  }
}
  