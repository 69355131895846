<!--
 * @Descripttion: 
 * @version: v1
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-02-09 11:20:35
--> 
<template>
   <div v-if="image">
        <el-upload 
            v-has='has'
            class="avatar-uploader"
            :action="uploadUrl()"
            :headers="myHeader()"
            :show-file-list="false"
            :accept="accept"
            :before-upload="checkImage"
            :on-error="uploadError"
            :on-success="(response, file, fileList) =>{uploadSuccess(response)}"
            >
            <el-button type="primary" v-has='has'>更改图片</el-button>
        </el-upload>
        <el-image 
            class="avatar"
            :src="image" 
            :preview-src-list="[image]">
        </el-image>
    </div>
    <div v-else>
        <el-upload 
            v-has='has'
            class="avatar-uploader"
            :action="uploadUrl()"
            :headers="myHeader()"
            :accept="accept"
            :show-file-list="false"
            :before-upload="checkImage"
            :on-error="uploadError"
            :on-success="(response, file, fileList) =>{uploadSuccess(response)}"
            >
            <!-- :on-success="uploadSuccess()" -->
            <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-image 
            v-if="!checkBtn(has)"
            class="avatar"
            src="" >
        </el-image>
    </div>
</template>

<style lang="scss" >
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .icon{
    display: block;
    font-size: 8rem;
    color: #67C23A;
    margin: auto;
    // margin-left: calc(54% - 8rem);
  }
</style>


<script>
import { decrypt } from '@/utils/aes';
// import { img } from "../api/common"
export default {
    data() {
        return {
            load:'',
        }
  },
  name: 'imgUploader',
  // 传入子组件的参数写到props
  props: {
        image:String,
        has:String,
        accept:{
          type:String,
          default:'image/jpeg,image/jpg,image/png,image/gif'
        },
        
  },
  methods:{
        // uploadUrl(){
        //    return '/api/'+this.api.common.upload.img;
        // },
        uploadUrl(){
           return '/api/'+ 'common/admin/v1/upload/img';
        },
        myHeader(){
           return {"Authorization" : sessionStorage.getItem('auxfaceToken'),'uuid':sessionStorage.getItem('uuid'),'puuid':sessionStorage.getItem('puuid')}
        },
        //图片解密
        uploadSuccess(response){
            this.load.close();
            response.data = decrypt(response.data);
            console.log(response.data)
            this.$emit('upload-success',response);
        },
        uploadError(){
            this.load.close();
            this.$message.error('上传失败');
        },
        checkImage(file) {  
            this.load = this.loading();
            const isJPG = file.type === 'image/jpeg';
            const isPng = file.type === 'image/png';
            const isLt3M = file.size / 1024 / 1024 < 3;
            // if (!isJPG && !isPng && !isDocument) {
            //     this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
            //     this.load.close();
            //     return false;
            // }
            if (!isLt3M) {
                this.$message.error('上传图片大小不能超过 3MB!');
                this.load.close();
                return false;
            }
            return true;
        },
  },
}
</script>

