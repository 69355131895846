<!--
 * @Descripttion: 
 * @version: 
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Terry
 * @LastEditTime: 2024-02-23 10:57:19
-->
<template>
  <el-menu
    :default-active="defaultActive"
    @select="handleSelect"
    class="el-menu-vertical-demo"
    :router="true"
    background-color="#fff"
    text-color="rgba(0, 0, 0, 0.65)"
    active-text-color="#1890ff"
    ref="aside"
    :collapse="isCollapse"
  >
    <div disabled class="pjName-box">
      <div class="pjName" v-if="!isCollapse">{{ $t('global.aside') }}</div>
      <div class="storage-button" @click="handleShrink" v-if="!isCollapse">
        <i class="iconfont ali-icon-shouqi-"></i>
      </div>
      <div class="storage-button" @click="handleShrink" v-else>
        <i class="iconfont ali-icon-zhankai-"></i>
      </div>
    </div>

    <fragment v-for="(value, key) in list" :key="key">
      <el-menu-item
        v-if="!value.children"
        :default-active="value.pageUrl"
        :index="value.pageUrl"
      >
        <i :class="value.icon"></i>
        <span slot="title">{{ value.name }}</span>
      </el-menu-item>
      <el-submenu v-else :index="value.pageUrl">
        <template slot="title">
          <i :class="value.icon"></i>
          <span slot="title">{{ value.name }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            v-for="(childrenV, childrenK) in value.children"
            :index="childrenV.pageUrl"
            :key="childrenK"
          >
            <i :class="childrenV.icon"></i>
            <span slot="title">{{ childrenV.name }}</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </fragment>
  </el-menu>
</template>

<style scoped>
>>> .el-menu-item .is-active {
  background-color: #e6f7ff !important;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}
>>> .el-menu--collapse {
  width: 60px;
}
>>> .el-icon-arrow-right {
  display: none !important;
}
>>> .el-menu-item-group__title {
  padding: 0 0 0 20px;
}
.pjName-box {
  position: sticky;
  width: 100%;
  top: 0px;
  background: white;
  z-index: 999;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.pjName {
  color: #050001 !important;
  width: 70%;
  padding: 0 20px;
  height: 56px;
  text-align: center;
  line-height: 56px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.storage-button {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
.storage-button:hover {
  background: #999;
}
.iconfont {
  font-size: 1em;
  line-height: 30px;
}
</style>
<script>
export default {
  data() {
    return {
      list: [],
      pjName: "",
      defaultActive: "/platform/home",
      buttonShow: true,
      textShow: true,
      isCollapse: false,
      isMain: false,
    };
  },
  watch: {
    $route(to, from) {
      this.getMenuList(to.path);
      // this.getNavName(to.path);
      this.getUrl(to.path);
    },
  },
  methods: {
    handleShrink() {
      this.isCollapse = !this.isCollapse
      this.$emit("upMain", this.isCollapse);
      this.$store.commit('upLeftStaus', this.isCollapse)
    },

    getUrl(url) {
      this.defaultActive = url;
      setTimeout(() => {
        if (!this.$refs.aside) {
          this.getUrl(url);
        } else {
          this.$refs.aside.activeIndex = url;
        }
      }, 100);
    },
    handleSelect(key) {
      if (this.$route.path == key) {
        var prev = this.$parent.$parent.$parent.$parent.$refs.routerView;
        prev.tableLoad = true;
        prev.pageLoad = true;
        prev.getPageData();
      }
    },
    getMenuList(path) {
      var path = path.split("/"),
        list = sessionStorage.getItem(path[1] + "Aside");
      this.list = JSON.parse(list);
    },
    getNavName(path) {
      let split = path.split("/");
      let pjName = sessionStorage.getItem("projectName");
      if (split[1] == "modules") {
        this.pjName = sessionStorage.getItem("projectName");
      } else {
        this.pjName = "导航栏";
      }
    },
  },
  mounted() {
    document.querySelector('.pjName-box').addEventListener('transitionEnd',function(){
        console.log('过度结束')
    })
  },
  created() {
    let url = this.$route.path;
    this.getMenuList(url);
    // this.getNavName(url);
    this.getUrl(url);
  },
};
</script>
