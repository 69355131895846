/*
 * @Descripttion: 
 * @version: 
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-09 18:13:52
 */ 


export default [
  {
    path: '/modules/mobileAttendance/',
    name: 'index',
    component: ()=>import("@/views/Index.vue"),
    meta: {title: 'mobileAttendance.router.mobileAttendance'},
    redirect: { path: '/modules/mobileAttendance/' },
    children: [
      {name: 'region', path: 'region', component: ()=>import("@/views/mobileAttendance/region/Index.vue")},
      {name: 'attendance', path: 'attendance', component: ()=>import("@/views/mobileAttendance/attendance/Index.vue"), meta: {title: 'mobileAttendance.router.attendanceManagement'}},
      {name: 'face', path: 'face', component: ()=>import("@/views/mobileAttendance/face/Index.vue"), meta: {title: 'mobileAttendance.router.faceAttendance'}},
      {name: 'select', path: 'select', component: ()=>import("@/views/mobileAttendance/select/Index.vue")},
    ]
    
  }
];

