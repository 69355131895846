export default {
  safetytrain:{
    router:{
      safetyTraining: 'Safety Training',
      userClassroomManagement: 'User Classroom Management',
      examManagement: 'Exam Management',
      examPersonnelManagement: 'Exam Personnel Management',
      bannerManagement: 'Banner Management'
    },
    dateOverView: {
      projectCode: 'Project Code: ',
      totalWorkers: 'Total Workers',
      totalTeams: 'Total Teams',
      examWorkers: 'Exam Workers',
      totalExamsToday: 'Total Exams Today',
      totalExams: 'Total Exams',
      passRate: 'Pass Rate',
      examTrend: 'Exam Trend Chart',
    },
    userCenter: {
      title:'User Classroom Management Details',
      classroomManagement: 'User Classroom Management',
      courseName: 'Course Name',
      enterCourseName: 'Enter Course Name',
      category: 'Category',
      selectCategory: 'Select Category',
      applicableUsers: 'Applicable Users',
      selectApplicableUsers: 'Select Applicable Users',
      search: 'Search',
      noRemark: 'No Remark',
      addedCourses: 'Added Courses',
      addCoursesForUnselectedUsers: 'Add Courses for Unselected Users',
      unselectedUsers: 'Unselected Users',
      filterKeyword: 'Enter keyword to filter',
      selectedUsers: 'Selected Users',
      noData: 'No Data',
      courseName: 'Course Name',
      category: 'Category',
      applicableUsers: 'Applicable Users',
      selectionCount: 'Selection Count',
      remark: 'Remark',
    },
    exam:{
      examManagement: 'Exam Management',
      passed: 'Pass',
      failed: 'Fail',
      category: 'Category:',
      selectCategory: 'Select Category',
      team: 'Team:',
      selectTeam: 'Select Team',
      noScore: 'No Score Yet',
      name: 'Name',
      examName: 'Exam Name',
      team: 'Team',
      examCategory: 'Exam Category',
      score: 'Score',
      search: 'Search',
    },
    noExam:{
      userManagement: 'Unexamined Personnel Management',
      name: 'Name:',
      enterName: 'Enter Name',
      search: 'Search',
      name: 'Name',
      totalExams: 'Total Exams',
      completedExams: 'Completed Exams',
      pendingExams: 'Pending Exams',
      enterCourseName: 'Enter Course Name',
      search: 'Search',
      courseName: 'Course Name',
    },
    banner:{
      title:'Banner Management Details',
      carouselManagement: 'Banner Management',
      addImage: 'Add Image',
      detailInfo: 'Detail Information',
      delete: 'Delete',
      sort: 'Sorting',
      image: 'Image',
      remark: 'Remark',
      detailInfo: 'Detail Information',
      confirmDelete: 'Are you sure you want to delete this banner?',
      deleteConfirmation: 'Delete Confirmation',
      confirm: 'Confirm',
      cancel: 'Cancel',
      selectSortPosition: 'Select Sorting Position',
      addImage: 'Please add image',  
      sort: 'Sort:',
      courseImage: 'Course Image:',
      remark: 'Remark:',
      remarkPlaceholder: 'Remark',
    },
    classCenter: {
      trainings: 'Safety Trainings',
      categoryManagement: 'Category Management',
      addCategory: 'Add Category',
      applicablePersonnel: 'Applicable Personnel',
      selectApplicablePersonnel: 'Select Applicable Personnel',
      search: 'Search'
    }
  }
}