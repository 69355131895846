/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2024-08-05 16:34:42
 * @Description: file content
 * @FilePath: \auxface_web\src\lang\en.js
 */

var global = {
  global:{
    title:'Smart Building Integrated Management Platform',
    logout:'Logout',
    loading:'Loading...',
    prjName: 'Smart Building Integrated Management Platform',
    exit: 'Exit Login',
    back: 'Back to Project',
    btnName: 'Open New Page',
    prompt: 'Prompt',
    confirm: 'Confirm',
    cancel: 'Cancel',
    logoutConfirm: 'Do you want to logout?',
    logoutSuccess: 'Logout successful',
    aside: 'Navigation',
    userInactive: 'Due to user inactivity, please log in again',
    deleteConfirmation: "Are you sure you want to delete this data? This action cannot be undone. Please proceed with caution!",
    delete: "Delete Confirm",
    destroy: "Delete",
    confirmClose: "Confirm close?",
    searchBtn: "Search",
    view:'View',
    selectPlaceholder:'please select',
    inputPlaceholder:'please enter',
  }  
}
  
var langList = require.context('@/views/', true, /\en.jsx$/),lang = {};

langList.keys().forEach(element => {
  var value = langList(element)
  if(value.default){
    lang = {...lang , ...value.default,...global};
  }
})

export default lang;
