/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2024-08-05 16:34:57
 * @Description: file content
 * @FilePath: \auxface_web\src\lang\zh-tw.js
 */



var global = {
  global:{
    title: 'Edan Smart Platform',
    logout: '登出',
    loading: '載入中',
    prjName: 'Edan Smart Platform',
    exit: '退出登錄',
    back: '返回項目',
    btnName: '打開新頁面',
    prompt: '提示',
    confirm: '確定',
    cancel: '取消',
    logoutConfirm: '請問您是否需要退出登錄',
    logoutSuccess: '退出成功',
    aside: '導航欄',
    userInactive: '由於用戶長時間沒有操作，請重新登錄',
    deleteConfirmation: "是否確認刪除此數據，刪除後無法恢復請慎重操作！",
    delete: "刪除確認",
    destroy: "刪除",
    confirmClose: '確認關閉？',
    searchBtn: '查詢',
    view:'查看',
    selectPlaceholder:'請選擇',
    inputPlaceholder:'請輸入',
  } 
}
  
var langList = require.context('@/views/', true, /\zh-tw.jsx$/),lang = {};

langList.keys().forEach(element => {
  var value = langList(element)
  if(value.default){
    lang = {...lang , ...value.default,...global};
  }
})

export default lang;
