/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2024-08-14 23:23:32
 * @Description: file content
 * @FilePath: \auxface_web\src\views\video\lang\en.jsx
 */
export default {
  video:{
    router:{
      playPage: 'Play Page',  // 播放页面
      monitorPlayback: 'Monitoring Playback',  // 监控回放
      videoMonitoring: 'Video Monitoring',  // 视频监控
      accountSettings: 'Account Settings',  // 账号设置
      camera: 'Camera Management',  // 摄像头管理
      channel:'channel',
      cloudBack:'cloudBack',
      home:'home',
    },
    account: {
      accountSettings: 'Account Settings',  // 账号设置
      addCameraAccount: 'Add Camera Account',  // 添加摄像头账号
      accountName: 'Account Name',  // 账号名称
      enterAccountName: 'Enter Account Name',  // 请输入账号名称
      search: 'Search',  // 查询
      noRemark: 'No Remark',  // 无备注
      delete: 'Delete',  // 删除
      accountName: 'Account Name',  // 账号名称
      remark: 'Remark',  // 备注
      confirmDelete: 'Are you sure to delete this account?',  // 是否确认删除此账号?
      deleteConfirmation: 'Delete Confirmation',  // 删除确认
      confirm: 'Confirm',  // 确定
      cancel: 'Cancel',
      accountName: 'Account Name',  // 账号名称
      enterAccountName: 'Enter Account Name',  // 请输入账号名称
      enterAppKey: 'Enter App Key',  // 请输入账号appkey
      enterSecret: 'Enter Secret',  // 请输入账号Secret
      accountNameRequired: 'Camera account cannot be empty',  // 摄像头账号不能为空
      appKeyRequired: 'AppKey cannot be empty',  // AppKey不能为空
      secretRequired: 'Secret cannot be empty'  // Secret不能为空
    },
    camera: {
      addDevice: 'Add Device',  // 设备添加
      deviceName: 'Device Name',  // 设备名称
      enterDeviceName: 'Enter Device Name',  // 请输入设备名称
      accountConfig: 'Account Configuration',  // 账号配置
      selectAccountConfig: 'Select Account Configuration',  // 请选择账号配置
      selectModel: 'Select Model',  // 请选择机型
      search: 'Search',  // 查询
      deviceName: 'Device Name',  // 设备名称
      deviceSerialNumber: 'Device Serial Number',  // 设备序列号
      channelNumber: 'Channel Number',  // 通道号
      model: 'Model',  // 机型
      onlineStatus: 'Online Status',  // 在线状态
      online: 'Online',  // 在线
      offline: 'Offline',  // 离线
      retrieve: 'Retrieve',  // 重新获取
      nonNvrDevice: 'Non-NVR Device',  // 非NVR设备
      playback: 'Playback',  // 回放
      section: 'Section',  // 所属板块
      accountConfig: 'Account Configuration',  // 账号配置
      display: 'Display',  // 是否显示
      remark: 'Remark',  // 备注
      delete: 'Delete',  // 删除
      nvr: 'NVR',
      bulletCamera: 'Bullet Camera',  // 枪机
      domeCamera: 'Dome Camera',  // 球机
      normalCamera: 'Normal Camera',  // 普通摄像头
      personnelManagement: 'Personnel Management',  // 人员管理
      dustDevice: 'Dust Device',  // 扬尘设备
      towerCraneDevice: 'Tower Crane Device',  // 塔吊设备
      elevatorDevice: 'Elevator Device',  // 升降机设备
      confirmDeleteDevice: 'Are you sure to delete this device?',  // 是否确认删除此设备?
      confirmation: 'Confirmation',  // 提示
      confirm: 'Confirm',  // 确定
      cancel: 'Cancel',
      serialNumber: 'Device Serial Number',  // 设备序列码
      serialNumberPlaceholder: 'Enter a 9-digit serial number',  // 请输入9位字母或数字的序列号
      name: 'Device Name',  // 设备名称
      namePlaceholder: 'Device Name',
      verificationCode: 'Verification Code',  // 设备验证码
      verificationCodePlaceholder: 'Enter verification code',  // 请输入设备验证码
      accountConfiguration: 'Account Configuration',  // 账号配置
      selectAccountConfiguration: 'Select Account Configuration',  // 请选择账号配置
      belongingSection: 'Belonging Section',  // 所属板块
      selectBelongingSection: 'Select Belonging Section',  // 请选择所属板块
      machineType: 'Machine Type',  // 机器类型
      selectMachineType: 'Select Machine Type',  // 请选择机器类型
      remark: 'Remark',  // 备注
      remarkPlaceholder: 'Enter remark'  // 请输入备注
    },
    player: {
      goBack: 'Go Back',  // 返回
      screen: 'Screen',  // 屏幕
      clearAllCameras: 'Clear All Cameras',  // 一键清除所有摄像头
      confirmDeleteAllCameras: 'Are you sure to delete all cameras?',  // 是否确认删除全部摄像头?
      deleteConfirmation: 'Delete Confirmation',  // 删除确认
      confirm: 'Confirm',  // 确定
      cancel: 'Cancel',
      dragCameras: 'Drag cameras from the left list',  // 请拖放左侧列表摄像头
      dragCamerasHere: 'Drag cameras here to play',  // 请把摄像头拖到此处播放
      videoAlreadyInPlaylist: 'The video is already in the playlist, please do not place it again'  // 该视频已经在播放列表了，请勿重复放置
    },
  }
}
  
