<!--
 * @Descripttion: 
 * @version: 
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Terry
 * @LastEditTime: 2022-09-05 18:39:56
--> 
<template>
    <el-row>
        <el-col :span="24" class="t-c font-size-13 text-gray" style="line-height:60px;" >
            ©版权所有  <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2020130674号-1</a>
        </el-col>
    </el-row>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
    data() {
        return {
        };
  },
  name: 'navbar',
  // 传入子组件的参数写到props
  props: {
  },
  methods:{
  },
  mounted(){
  }
}
</script>


