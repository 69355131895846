/*
 * @Author: Terry
 * @Date: 2023-12-03 17:10:00
 * @LastEditors: Terry
 * @LastEditTime: 2024-06-02 19:44:30
 * @Description: file content
 * @FilePath: \auxface_web\src\views\certificate\lang\en.jsx
 */

export default {
  confinedSapce:{
    router:{
    }
  }
}