/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-14 19:00:06
 * @Description: file content
 * @FilePath: \auxface_web\src\views\equipment\lang\zh-tw.jsx
 */
export default {
  equipment:{
    router: {
      equipmentSafety: '設備安全',
      elevatorManagement: '升降機管理',
      towerCraneManagement: '塔吊管理',
      scaffoldManagement: '脚手架管理',
    },    
    towerCrane: {
      title:'塔吊管理詳情',
      towerCraneManagement: "塔吊管理",
      confirmDeleteDevice: "是否確認刪除此設備?",
      deleteConfirmation: "刪除確認",
      confirm: "確定",
      cancel: "取消",
      integrator: "集成商",
      deviceName: "設備名稱",
      onlineStatus: "在線狀態",
      totalHeight: "總高度(米)",
      hoistingWeight: "吊重重量(噸)",
      span: "幅度(米)",
      height: "高度(米)",
      rotationAngle: "回轉角度(度)",
      ratedHoistingWeight: "額定吊重(t)",
      momentPercentage: "力矩百分比(%)",
      windSpeed: "風速(m/s)",
      forwardTiltAngle: "前傾角(度)",
      deviceCode: "設備編碼",
      deviceLocation: "設備位置",
      remark: "備註",
      uploadTime: "上傳時間",
      detailData: "詳細數據",
      delete: "刪除",
      addDevice: "添加設備",
      enterDeviceName: "請輸入設備名稱",
      integrator: "集成商",
      selectIntegrator: "請選擇集成商",
      search: "查詢",
      offline: "離線",
      online: "在線",
      noData: "無數據",
      longitude: "經度",
      latitude: "緯度",
      detailData: "詳細數據",
      delete: "刪除",
      deviceName: "設備名稱",
      enterDeviceName: "請輸入設備名稱",
      deviceCode: "設備編碼",
      enterDeviceCode: "請輸入設備編碼",
      integrator: "集成商",
      selectIntegrator: "請選擇集成商",
      cameraBinding: "攝像頭綁定",
      bindCamera: "請綁定攝像頭",
      deviceLongitude: "設備經度",
      enterDeviceLongitude: "請輸入設備經度",
      deviceLatitude: "設備緯度",
      enterDeviceLatitude: "請輸入設備緯度",
      deviceArmLength: "設備臂長",
      enterDeviceArmLength: "請輸入設備前臂臂長",
      remarks: "備註",
      enterRemarks: "請輸入備註",
      nameLength: '長度在 1 到 50 個字符',
      integratorRequired: '集成商不能為空',
      enterDeviceCode: '請輸入設備編碼',
      codeLength: '長度在 1 到 10 個字符',
      enterLongitude: '請輸入經度',
      selectLatitude: '請選擇緯度',
      enterArmLength: '請輸入臂長',
      deviceDetails: "設備詳細信息",
      date: "日期",
      to: "至",
      startDate: "開始日期",
      endDate: "結束日期",
      search: "查詢"
      },
    elevator: {
      title: "升降機管理詳情",
      management: "升降機管理",
      addDevice:'添加設備',
      enterDeviceName: "請輸入設備名稱",
      integrator: "集成商",
      selectIntegrator: "請選擇集成商",
      search: "查詢",
      offline: "離線",
      online: "在線",
      noData: "無數據",
      closed: "關閉",
      opened: "開啟",
      notInstalled: "未安裝",
      installed: "已安裝",
      longitude: "經度",
      latitude: "緯度",
      detailData: "詳細數據",
      delete: "刪除",
      confirmDeleteDevice: "是否確認刪除此設備?",
      deleteConfirmation: "刪除確認",
      confirm: "確定",
      cancel: "取消",
      integrator: "集成商",
      deviceName: "設備名稱",
      onlineStatus: "在線狀態",
      totalHeight: "總高度(米)",
      loadWeight: "載重量(噸)",
      height: "高度(米)",
      runningSpeed: "運行速度(m/s)",
      windSpeed: "風速(m/s)",
      frontDoorStatus: "前門狀態",
      backDoorStatus: "後門狀態",
      faceRecognition: "人臉識別",
      deviceCode: "設備編碼",
      remarks: "備註",
      deviceLocation: "設備位置",
      uploadTime: "上傳時間",
      details: "詳細數據",
      delete: "刪除",
      date: "日期",
      to: "至",
      startDate: "開始日期",
      endDate: "結束日期",
      search: "查詢",
      deviceDetails: "設備詳細信息",
      deviceName: "設備名稱",
      enterDeviceName: "請輸入設備名稱",
      deviceCode: "設備編碼",
      enterDeviceCode: "請輸入設備編碼",
      integrator: "集成商",
      selectIntegrator: "請選擇集成商",
      cameraBinding: "攝像頭綁定",
      bindCamera: "請綁定攝像頭",
      deviceLongitude: "設備經度",
      enterDeviceLongitude: "請輸入設備經度",
      deviceLatitude: "設備緯度",
      enterDeviceLatitude: "請輸入設備緯度",
      deviceHeight: "設備高度",
      enterDeviceHeight: "請輸入設備高度",
      enterDeviceName: '請輸入設備名稱',
      nameLength: '長度在 1 到 50 個字符',
      integratorRequired: '集成商不能為空',
      enterDeviceCode: '請輸入設備編號',
      enterDeviceHeight: '請輸入設備高度',
      enterDeviceLatitude: '請輸入設備緯度',
      enterDeviceLongitude: '請輸入設備經度'
    },
    scaffold:{
      search:'查詢',
      management:'腳手架管理',
      addDevice:'添加設備',
      enterDeviceName:"請輸入設備名稱",
      onlineStatus:'聯網狀態',
      deviceId:'設備序列號',
      deviceName:'設備名稱',
      weight:'軸壓力(kn)',
      spanX:'水平x位移(mm)',
      spanY:'水平y位移(mm)',
      dipX:'水平x傾角(°)',
      dipY:'水平y傾角(°)',
      height:'高度',
      down:'沉降(mm)',
      electric:'電池電壓',
      windSpeed:'風速(m/s)',
      alarmStatus:'報警狀態',
      inDate:'上傳時間',
      details:'信息詳情',
      delete:'刪除',
      title:'腳手架詳情',
      detailData: "詳細數據",
      offline: "離線",
      online: "在線",
      confirmDeleteDevice: "是否確認刪除此設備?",
      deleteConfirmation: "刪除確認",
      confirm: "確定",
      cancel: "取消",
      date: "日期",
      to: "至",
      startDate: "開始日期",
      endDate: "結束日期",
      search: "查詢",
      deviceDetails:'腳手架詳情',
      enterDeviceName: "請輸入設備名稱",
      deviceCode: "設備編碼",
      enterDeviceCode: "請輸入設備編碼",
      integrator: "集成商",
      selectIntegrator: "請選擇集成商",
      deviceLongitude: "設備經度",
      enterDeviceLongitude: "請輸入設備經度",
      deviceLatitude: "設備緯度",
      enterDeviceLatitude: "請輸入設備緯度",
      description:'備註',
      enterDescription:'請輸入備註',
      normal:'正常',
      horizontalDisplacementXAlarm:'水平位移X軸報警',
      verticalDisplacementAlarm:'垂直位移報警',
      angleAlarm:'角度報警',
      loadAlarm:'承重報警',
      horizontalDisplacementYAlarm:'水平位移Y軸報警',
    }
  }
}
