/*
 * @Descripttion: 
 * @version: 
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Terry
 * @LastEditTime: 2024-06-13 20:18:55
 */ 
export  default  [
  //万能表单
  // {name: 'form', path: '/modules/certificate/form', component: ()=>import("@/views/certificate/form/Index.vue"), meta: {title: 'certificate.router.template'}},
  {
    path: '/modules/certificate/',
    name: 'certificateIndex',
    component: ()=>import("@/views/Index.vue"),
    redirect: { path: '/modules/certificate/' },
    meta: {title: 'certificate.router.certificateSystemName'},
    // 嵌套路由 》》 对应着Home组件中的router-view
    children: [
      //分类
      {name: 'certificateTemplate', path: 'template', component: ()=>import("@/views/certificate/template/Index.vue"), meta: {title: 'certificate.router.template'}},

      {name: 'certificateMachineDigitizationIndex', path: 'machineDigitization/index', component: ()=>import("@/views/certificate/machineDigitization/Index.vue"), meta: {title: 'certificate.router.machineDigitizationIndex'}},
      {name: 'certificateMachineDigitizationType', path: 'machineDigitization/type', component: ()=>import("@/views/certificate/machineDigitization/Type.vue"), meta: {title: 'certificate.router.machineDigitizationType'}},
      {name: 'certificateMachineDigitizationTemplate', path: 'machineDigitization/template', component: ()=>import("@/views/certificate/machineDigitization/Template.vue"), meta: {title: 'certificate.router.machineDigitizationTemplate'}},
      {name: 'certificateMachineDigitizationAudit', path: 'machineDigitization/audit', component: ()=>import("@/views/certificate/machineDigitization/Audit.vue"), meta: {title: 'certificate.router.machineDigitizationAudit'}},
      
      // 设备电子证书
      {name: 'certificateMachineList', path: 'machine/index', component: ()=>import("@/views/certificate/machine/Index.vue"), meta: {title: 'certificate.router.machine'}},
      {name: 'certificateMachinePermission', path: 'machine/permission', component: ()=>import("@/views/certificate/machine/Permission.vue"), meta: {title: 'certificate.router.machinePermission'}},
      // 人员行为许可
      {name: 'certificateRiskTemplate', path: 'risk/template', component: ()=>import("@/views/certificate/risk/Template.vue"), meta: {title: 'certificate.router.riskTemplate'}},
      {name: 'certificateRiskList', path: 'risk/index', component: ()=>import("@/views/certificate/risk/Index.vue"), meta: {title: 'certificate.router.risk'}},
      {name: 'certificateRiskAudit', path: 'risk/audit', component: ()=>import("@/views/certificate/risk/Audit.vue"), meta: {title: 'certificate.router.riskAudit'}},
      {name: 'certificateRiskRevoke', path: 'risk/revoke', component: ()=>import("@/views/certificate/risk/Revoke.vue"), meta: {title: 'certificate.router.revoke'}},
      // 培训签到
      {name: 'certificateTrain', path: 'train', component: ()=>import("@/views/certificate/train/Index.vue"), meta: {title: 'certificate.router.train'}},
    ]
    
  }
];




