/*
 * @Author: Terry
 * @Date: 2023-12-03 17:10:00
 * @LastEditors: Terry
 * @LastEditTime: 2024-04-27 11:16:09
 * @Description: file content
 * @FilePath: \auxface_web\src\views\certification\lang\en.jsx
 */

export default {
  "certification": {
    "router": {
      "personnelRealNameSystem": "Personnel Real Name System",
      "personnelList": "Personnel List",
      "personnelAudit": "Personnel Audit",
      "teamManagement": "Team Management",
      "deviceManagement": "Device Management",
      "attendanceManagement": "Attendance Management",
      "governmentIntegrationProgress": "Government Integration Progress",
      "salaryList": "Salary List",
      "salaryBackup": "Salary Backup",
      "pendingDailyWorks": "Pending Daily Works",
      "pendingMonthlyWorks": "Pending Monthly Works"
    },
    "home": {
      "loadingText": "Loading...",
      "projectCode": "Project Code",
      "visualizationPanel": "Visualization Smart Panel",
      "presentPersonnel": "Present Personnel",
      "managementPersonnel": "Management Personnel",
      "laborPersonnel": "Labor Personnel",
      "pendingPersonnel": "Pending Personnel Entry",
      "totalAttendance": "Total Attendance",
      "totalTeams": "Total Teams",
      "dailyAttendanceStatistics": "Daily Attendance Statistics",
      "units": "units",
      "constructionUnitName": "Construction Unit Name",
      "constructionType": "Construction Type",
      "totalPersonnel": "Total Personnel",
      "attendancePersonnel": "Attendance Personnel",
      "notAttendancePersonnel": "Not Attendance Personnel",
      "attendanceRate": "Attendance Rate",
      "status": "Status",
      "present": "Present",
      "pendingEntry": "Pending Entry",
      "dailyAttendanceTrend": "Daily Attendance Trend Chart",
      "labalWorkers": "Labor Personnel",
      "labalAdmin": "Management Personnel"
    },
    "team": {
      "teamManagement": "Team Management",
      "addTeam": "Add Team",
      "presentTeams": "Present Teams",
      "exitedTeams": "Exited Teams",
      "workType": "Work Type",
      "selectUnit": "Select Unit",
      "name": "Name",
      "teamLeader": "Team Leader",
      "search": "Search",
      "enterTeamName": "Enter Team Name",
      "teamName": "Team Name",
      "enterTeamLeaderName": "Enter Team Leader Name",
      "noData": "No Data",
      "notSet": "Not Set",
      "yes": "Yes",
      "no": "No",
      "enter": "Enter",
      "exit": "Exit",
      "confirmExitTeam": "Are you sure to confirm the team to exit?",
      "viewStaff": "View Staff",
      "responsiblePersonName": "Team Leader",
      "memberCount": "Team Members Count",
      "attendanceCount": "Attendance Count",
      "isSync": "Sync to Government Platform",
      "entryTime": "Entry Time",
      "exitTime": "Exit Time",
      "qrCode": "QR Code",
      "corpName": "Corporation Name",
      "selectCorp": "Select Corporation",
      "syncToGovPlatform": "Sync to Government Platform",
      "contractFile": "Contract File",
      "selectWorkType": "Select Team Work Type",
      "uploadContractFile": "Contract File must be uploaded",
      "selectSyncToGovPlatform": "Select whether to upload to the government platform",
      "notSyncToGovPlatformMessage": "The current team is selected as 'Not Sync to Gov. Platform'. This means that (Team Information, Member Information, Member Attendance) will not be synchronized to the government platform",
      "notSyncToGovPlatformTitle": "Team not sync to Gov. Platform",
      "syncToGovPlatformMessage": "The current team selects 'Confirm Sync to Government Platform'. This means that (Team Information, Member Information, Member Attendance) will be synchronized to the government platform",
      "syncToGovPlatformTitle": "Team confirms sync to Gov. Platform",
      "confirm": "Confirm",
      "cancel": "Cancel",
      "getRefreshQrCode": "Get/Refresh QR Code",
      "qrCodeNoNeedSave": "QR Code does not need to be saved",
      "teamName": "Team Name",
      "placeholderTeamName": "Enter Team Name",
      "workType": "Team Work Type",
      "placeholderWorkType": "Select Work Type",
      "corpName": "Corporation Name",
      "placeholderCorpName": "Enter Corporation Name",
      "remark": "Team Remark",
      "entryExitInfo": "Entry & Exit Information",
      "placeholderRemark": "Remark",
      "entryInfo": "Entry Information:",
      "entryTime": "Entry Time",
      "notEntered": "Not Entered",
      "exitInfo": "Exit Information:",
      "exitTime": "Exit Time",
      "notExited": "Not Exited",
      "responsiblePersonInfo": "Team Leader Information:",
      "name": "Name",
      "notSet": "Not Set",
      "contactNumber": "Contact Number:",
      "idNumber": "ID Number:",
      "enterTeamName": "Enter Team Name",
      "entry": "Team Entry",
      "exitTeam": "Team Exit",
      "entryAttachment": "Entry Attachment",
      "exitAttachment": "Exit Attachment",
      "requiredUrl": "Please upload exit attachment",
      "prompt": "Prompt",
      "confirm": "Confirm",
      "cancel": "Cancel",
      "title": "Team Management Details"
    },
    "staff": {
      "isTeamLeader": "Yes",
      "notTeamLeader": "No",
      "personnelList": "Personnel List",
      "entry": "Entered",
      "none": "Not Entered",
      "exit": "Exited",
      "workType": "Work Type",
      "selectWorkType": "Select Work Type",
      "noting": "None",
      "workerRole": "Worker Role",
      "selectWorkerRole": "Select Worker Role",
      "personnelName": "Personnel Name",
      "enterPersonnelName": "Enter Personnel Name",
      "search": "Search",
      "excelExport": "Excel Export",
      "confirmEntry": "Confirm entry of this user",
      "confirmExit": "Are you sure to allow this user to exit? This operation cannot be undone, please handle with caution!",
      "exitBtn": "Exit",
      "entryBtn": "Entry",
      "confirmSetLeader": "Are you sure to set this user as a team leader",
      "setLeader": "Set Team Leader",
      "confirmLossLeader": "Are you sure to cancel this user as a team leader",
      "lossLeader": "Cancel Team Leader",
      "admin": "Admin",
      "nonAdmin": "Non-Admin",
      "male": "Male",
      "female": "Female",
      "notVerified": "Not Verified",
      "view": "View",
      "nothing": "Nothing",
      "edit": "Edit",
      "notSet": "Not Set",
      "notExited": "Not Exited",
      "avatar": "Avatar",
      "name": "Name",
      "teamName": "Team Name",
      "workerType": "Worker Type",
      "workType": "Work Type",
      "managementPosition": "Management Position",
      "gender": "Gender",
      "age": "Age",
      "contract": "Labor Contract",
      "payManagement": "Pay Management",
      "teamLeader": "Team Leader",
      "entryTime": "Entry Time",
      "exitTime": "Exit Time",
      "notSelectedUser": "User Not Selected",
      "prompt": "Prompt",
      "confirm": "Confirm",
      "cancel": "Cancel",
      "contractRuleSettings": "Contract Rule Settings",
      "contractPeriodType": "Contract Period Type",
      "periodTypePlaceholder": "Period Type",
      "fixedTermContract": "Fixed Term Contract",
      "workBasedContract": "Work-based Contract",
      "startDate": "Start Date",
      "endDate": "End Date",
      "selectDate": "Select Date & Time",
      "unit": "Unit of Measurement",
      "selectUnit": "Select Unit",
      "unitPrice": "Unit Price",
      "selectUnitPrice": "Select Unit Price",
      "save": "Save Now",
      "contractAttachments": "Contract Attachments",
      "deleteAttachment": "Delete Attachment",
      "modifyAttachment": "Modify Attachment",
      "selectContractPeriodType": "Select Contract Period Type",
      "selectStartDate": "Select Start Date",
      "selectEndDate": "Select End Date",
      "uploadError": "Upload Error",
      "uploadImageFormat": "Uploaded image must be in JPG or PNG format!",
      "uploadImageSize": "Uploaded image size cannot exceed 3MB!",
      "selectStartDateFirst": "Start date selection required",
      "endDateEarlierThanStartDate": "End date cannot be earlier than start date",
      "confirmClose": "Confirm close?",
      "attachmentView": "Attachment View",
      "entryAttachment": "Entry Attachment",
      "exitAttachment": "Exit Attachment",
      "notUploaded": "Not Uploaded",
      "personnelExport": "Personnel Export",
      "corpBelong": "Corporation Belong",
      "selectCorp": "Select Corporation",
      "export": "Export",
      "cancel": "Cancel",
      "attendanceDateRequired": "Attendance Date required",
      "corpBelongRequired": "Corporation Belong required",
      "personnelDetails": "Personnel Details Data",
      "photo": "ID Photo / Avatar",
      "idCardNumberLabel": "ID Number:",
      "genderLabel": "Gender:",
      "male": "Male",
      "female": "Female",
      "nationLabel": "Nation:",
      "birthdayLabel": "Birthday:",
      "nativePlaceLabel": "Native Place:",
      "issuingAuthorityLabel": "Issuing Authority:",
      "validFromLabel": "Valid from:",
      "validUntilLabel": "Valid until:",
      "medicalHistoryLabel": "Medical History:",
      "no": "No",
      "yes": "Yes",
      "phoneNumberLabel": "Phone Number:",
      "emergencyContactLabel": "Emergency Contact:",
      "emergencyContactPhoneLabel": "Emergency Contact Phone:",
      "notCertified": "This user is not verified",
      "careerHistory": "Career History",
      "projectName": "Project Name",
      "participatingUnit": "Participating Unit",
      "teamName": "Team Name",
      "workType": "Work Type",
      "labourContract": "Labor Contract",
      "attachment": "Attachment",
      "notUploaded": "Not Uploaded",
      "entryTime": "Entry Time",
      "exitTime": "Exit Time",
      "notExited": "Not Exited",
      "qualificationInformation": "Qualification Information",
      "certificateName": "Certificate Name",
      "certificateNumber": "Certificate Number",
      "certificateCategory": "Certificate Category",
      "certificateType": "Certificate Type",
      "confirmingDepartment": "Confirming Department",
      "jobType": "Job Type",
      "firstIssuanceDate": "First Issuance Date",
      "certificateValidityStart": "Certificate Validity Start",
      "certificateValidityEnd": "Certificate Validity End",
      "issuingAuthority": "Issuing Authority",
      "salaryManagement": "Salary Management",
      "compensationMethod": "Compensation Method",
      "selectCompensationMethod": "Select Compensation Method",
      "calculateByHour": "Calculate by Hour",
      "calculateByDay": "Calculate by Day",
      "calculateByMonth": "Calculate by Month",
      "salaryUnitPrice": "Salary Unit Price (RMB)",
      "enterSalaryUnitPrice": "Enter Salary Unit Price",
      "workingDays": "Working Days (Days)",
      "enterWorkingDays": "Enter Working Days",
      "enterWorkingDaysPerMonth": "Working Days per Month",
      "personnelBankCardManagement": "Personnel Bank Card Management",
      "bank": "Bank",
      "selectBank": "Select Bank",
      "bankBranch": "Bank Branch",
      "enterBankBranch": "Enter Bank Branch",
      "bankCardNumber": "Bank Card Number",
      "enterBankCardNumber": "Enter Bank Card Number",
      "submit": "Submit",
      "selectCompensationMethod": "Select Compensation Method",
      "enterSalaryUnitPrice": "Enter Salary Unit Price"
    },
    "attendance": {
      "attendanceRecord": "Attendance Record",
      "enteredPersonnel": "Entered Personnel",
      "exitedPersonnel": "Exited Personnel",
      "personnelName": "Personnel Name",
      "enterPersonnelName": "Enter Personnel Name",
      "workType": "Work Type",
      "selectWorkType": "Select Work Type",
      "workerType": "Worker Type",
      "selectWorkerType": "Select Worker Type",
      "attendanceMonth": "Attendance Month",
      "selectMonth": "Select Month",
      "search": "Search",
      "excelExport": "Excel Export",
      "userName": "User Name",
      "teamName": "Team Name",
      "attendanceTotalDays": "Attendance Total Days",
      "firstEntryTime": "First Entry",
      "notEntered": "Not Entered",
      "lastExitTime": "Last Exit",
      "notExited": "Not Exited",
      "totalTime": "Total",
      "noAttendance": "No Attendance on that day",
      "entryDays": "Entry Days",
      "noAttendanceDays": "No Attendance Days",
      "entryCount": "Entry Count",
      "exitCount": "Exit Count",
      "visitSource": "Visit Source",
      "exportAttendance": "Export Attendance",
      "corp": "Corporation",
      "selectCorp": "Select Corporation",
      "exportMonth": "Export Month",
      "selectMonth": "Select Month",
      "exportType": "Export Type",
      "personnelHours": "Personnel Hours",
      "attendanceDetails": "Attendance Details",
      "export": "Export",
      "cancel": "Cancel",
      "dateRequired": "Attendance Date required",
      "corpRequired": "Corporation required"
    },
    "audit": {
      "pendingTitle": "Pending Audit Personnel",
      "pending": "Pending",
      "notPass": "Not Passed",
      "workType": "Work Type",
      "selectWorkType": "Select Work Type",
      "workerRole": "Worker Role",
      "selectWorkerRole": "Select Worker Role",
      "manageType": "Management Position",
      "selectManageType": "Select Management Position",
      "workerName": "Worker Name",
      "enterWorkerName": "Enter Worker Name",
      "search": "Search",
      "alertTitle": "Please check single or multiple personnel for audit. After passing, the personnel will be in the pending entry status. You can also click the 'Pass and Entry' button to directly put the personnel in the entry status",
      "confirmPassAndEnter": "Are you sure to pass the personnel audit and allow this personnel to enter? The entered personnel can directly enter via the device",
      "passAndEnter": "Pass and Entry",
      "confirmPass": "Are you sure to pass the personnel audit? After passing, the personnel will become a project personnel",
      "pass": "Pass",
      "confirmReject": "Are you sure to reject the personnel audit? After rejection, the personnel need to reapply",
      "reject": "Reject",
      "nonManager": "Non-Management Personnel",
      "male": "Male",
      "female": "Female",
      "notVerified": "Not Verified",
      "rejected": "Rejected",
      "corpName": "Corporation Name",
      "avatar": "Avatar",
      "workerName": "Worker Name",
      "teamName": "Team Name",
      "workerRole": "Worker Role",
      "workType": "Work Type",
      "manageType": "Management Position",
      "gender": "Gender",
      "age": "Age",
      "status": "Audit Result",
      "notSelectUser": "User Not Selected",
      "prompt": "Prompt",
      "confirm": "Confirm",
      "cancel": "Cancel"
    },
    "machineManage": {
      lastHeartDate:'last machine heartBeat',
      "title": "Device Management Details",
      "deviceManagement": "Device Management",
      "deviceAdd": "Device Add",
      "deviceName": "Device Name",
      "enterDeviceName": "Enter Device Name",
      "onlineStatus": "Online Status",
      "selectStatus": "Select",
      "online": "Online",
      "offline": "Offline",
      "search": "Search",
      "syncFailureAlert": "When the device fails to synchronize multiple times, and the personnel change their avatar, please reset the device before syncing data again",
      "noRemark": "No Remark",
      "dataSync": "Data Sync",
      "syncFailureUsers": "Failure to Sync Personnel",
      "reset": "Reset",
      "deviceName": "Device Name",
      "remark": "Remark",
      "deviceCode": "Device Code",
      "onlineStatus": "Online Status",
      "deviceUsers": "Device Users",
      "faceSync": "Face Sync",
      "reset": "Reset",
      "delete": "Delete",
      "confirm": "Confirm",
      "cancel": "Cancel",
      "resetConfirm": "Reset Confirmation",
      "deleteConfirm": "Delete Confirmation",
      "confirm": "Confirm",
      "cancel": "Cancel",
      "resetConfirmation": "All personnel in the device will be cleared. Are you sure?",
      "deleteConfirmation": "Are you sure to delete this attendance device?",
      "syncFailedUsers": "Failed to sync personnel",
      "loadingText": "Loading",
      "userLabel": "User Name",
      "commandLabel": "Command Issuance Time",
      "remarkLabel": "Remark",
      "failType0": "Failed to remove personnel. Please try again",
      "failType1": "Failed to issue personnel. Please ask the user to change the avatar and re-issue the face data",
      "failType2": "Failed to issue personnel. Please ask the user to verify the ID and re-issue the face data",
      "deviceName": "Device Name",
      "deviceNamePlaceholder": "Enter Device Name",
      "deviceCode": "Device Code",
      "deviceCodePlaceholder": "Enter Device Code",
      "integrator": "Integrator",
      "integratorPlaceholder": "Select",
      "deviceDirection": "Device Direction",
      "directionIn": "Entry",
      "directionOut": "Exit",
      "remark": "Remark",
      "remarkPlaceholder": "Remark",
      "machineNameRequired": "Device Name required",
      "deviceIdRequired": "Device Code required",
      "directionRequired": "Entry Direction required",
      "integratorRequired": "Integrator required"
    }, 
    "governmentStatus": {
      "governmentAbnormalConnection": "Government Abnormal Connection",
      "team": "Team:",
      "member": "Personnel:",
      "attendance": "Today's Attendance:",
      "connectionStatus": "Connection Status (Success/Total)",
      "teamConnectionAbnormal": "Team Connection Abnormal",
      'workerConnectionAbnormal': 'Abnormal worker connection',
      'attendanceConnectionAbnormal': 'Abnormal attendance connection',
      'noGovernmentConnection': 'This project is not currently connected to the government system. Please contact the administrator if connection is needed.',
      'syncTeam': 'Sync team',
      'syncWorker': 'Sync worker',
      'syncAttendance': 'Sync attendance',
      'syncMachine': 'Sync device',
      'syncInProgress': 'Sync in progress. If there is no change for a long time, please click the sync button above again.',
      'notSynced': 'Not synced. Please click the sync button above to sync.',
      'uploadFailed': 'Upload failed',
      'uploading': 'Uploading, please wait patiently',
      'prompt': 'Prompt',
      'cancel': 'Cancel',
      'confirm': 'Confirm',
      'contractor': 'Contractor',
      'teamConnection': 'Team connection',
      'workerBasicInfo': 'Basic worker information',
      'workerDataEntry': 'Worker data entry to project',
      'attendanceData': 'Personnel attendance data',
      'salaryAccount': 'Salary distribution account',
      'trainingInfo': 'Personnel training information',
      'workerPersonalInfo': 'Worker personal information',
      'faceDevice': 'Face device',
      'workerSalarySlip': 'Worker salary slip',
      'getProjectHash': 'Get project hash value',
      'getPersonnelHashData': 'Get personnel hash data',
      'teamName': 'Team name',
      'name': 'Name',
      'governmentType': 'Government type',
      'attendanceTime': 'Attendance time',
      'errorReason': 'Error reason',
      'connectionTime': 'Connection time',
      'uploadStatus': 'Upload status'
    },
  }
}
  