/*
 * @Descripttion: 
 * @version: 
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Terry
 * @LastEditTime: 2024-05-18 17:36:01
 */ 
// 引入
import axios from 'axios'
import router from '../router/index';
import { Message } from 'element-ui';
import { decrypt , encrypt } from '../utils/aes';

// 设置基准路径
axios.defaults.baseURL = '/api';
axios.defaults.timeout = 30000;

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  if(process.env.VUE_APP_BASE_AES=='true'){
    if(config.method=='get'){
      if(config.params !=undefined){
        config.params = {encryptData : encrypt(JSON.stringify(config.params))}
      }
    }else if(config.method!='get'){
      config.data = {encryptData : encrypt(JSON.stringify(config.data))}
    }
  }

  if(config['url']==undefined) return Promise.reject();
  // 在发送请求之前做些什么?手动传递token
  // 1.获取token
  var token = sessionStorage.getItem('auxfaceToken'),
  uuid = sessionStorage.getItem('uuid'),
  puuid = sessionStorage.getItem('puuid'),
  cuuid = sessionStorage.getItem('corpCode'),
  lang = localStorage.getItem('language');
  if (token) {
    // 2.必须在请求头中使用 Authorization 字段提供 token 令牌
    config.headers['Authorization'] = token;
    config.headers['uuid'] = uuid;
    config.headers['lang'] = lang;
    if(puuid)config.headers['puuid'] = puuid;
    if(cuuid)config.headers['corpCode'] = cuuid;
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  if (response.data.code==10001){
    // var lang = localStorage.getItem('language');
    sessionStorage.clear();
    // sessionStorage.setItem('language',lang);
    router.push({name:'login'});
    return Promise.reject();
  }
  if(response.data.message !=''){
    if(response.data.code==0 ){
      Message.success(response.data.message);
    }
    if(response.data.code!=0){
      Message.error(response.data.message);
      return Promise.reject();
    }
  }
   response.data.data = decrypt(response.data.data)
  // // 对响应数据做点什么
  return response.data
}, function (error) {
  
 if(error!=undefined) Message.error('网络错误');
  // 对响应错误做点什么
  return Promise.reject(error)
})

export default axios
