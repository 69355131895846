/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2024-02-27 15:44:35
 * @Description: file content
 * @FilePath: \auxface_web\src\views\platform\lang\zh-cn.jsx
 */
export default {
  platform:{
    router:{
      title: '智慧建筑后台',
      projectCreateTitle: '添加项目',
      projectListTitle: '项目列表',
      accountListTitle: '账号管理',
      corpAuditTitle: '企业审核',
      corpListTitle: '企业列表',
      governmentListTitle: '政府账号',
      userCenterTitle: '个人中心',
      scaffoldManagement:'脚手架管理',
    },
    auth:{
      accountLabel: "账号",
      passwordLabel: "密码",
      bigTitle:"智慧建筑一体化管理后台",
      welcome:"你好，欢迎使用",
      title:"智慧建筑一体化管理后台",
      loginBtn:"登陆",
      account:"账号",
      password:"密码",
      forget:"忘记密码?",
      notAccount:"没有账号?",
      register:"注册账号",
      accountTips:"请输入账号/手机号码",
      passwordTips:"请输入密码",
      accountLogin:"账号登陆",
      scanLogin:"扫码登录",
      leftTip1:"官方微信",
      leftTip2:"微信扫描二维码",
      leftTip3:'或搜索公众号"建采会"',
      rightTip1:"微信小程序",
      rightTip2:"微信扫描二维码",
      rightTip3:'或搜索公众号"智慧工地手机端"',
      accountRequired:'账号不能为空',
      pwdRequired:'密码不能为空',
      pwdLen:'密码长度在6-16位',
      pwdAgain:'请再次输入密码',
      phoneTips:'请输入手机号码',
      rePwdTips:'请再次输入密码',
      verifyTips:'请输入验证码',
      getVerify:'获取验证码',
      login:'登陆账号',
      resetBtn:'重置密码',
      qrCodeFail:'二维码失效',
      refresh:'刷新',
      hasAccount:'已有账号？',
      registerBtn:'注册账号',
      slideTips:'向右滑',
    },
    project: {
      name: '项目名称',
      nameTips: '请输入项目名称',
      searchBtn: '查询',
      rate: '出勤率',
      enterPrj: '进入项目',
      noPermissions: '该项目没有无权限',
      unjointed: '未对接',
      normal: '无异常',
      member: '人员',
      memberTips: '人对接异常',
      attendance: '考勤',
      team: '班组',
      errorTips: '条对接异常',
      corpName: '总承包单位',
      code: '项目编号',
      linkMan: '联系人',
      linkPhone: '联系电话',
      teamCount: '在场班组',
      attendanceCount: '今日考勤数/总人数',
      error: '实名制对接异常（可点击）',
      // create
      recordNumber: '项目投资备案编号',
      enterRecordNumber: '请输入项目投资备案编号',
      enterName: '请输入项目名称',
      enterLinkMan: '请输入联系人姓名',
      enterLinkPhone: '请输入联系人电话',
      address: '项目地址',
      enterAddress: '请输入项目地址',
      area: '所属区域',
      selectCategory: '请选择项目分类',
      participatingUnits: '参建单位',
      companyName: '企业名称',
      enterCompanyName: '请输入企业名称',
      delete: '删除',
      participatingType: '参建类型',
      selectParticipatingType: '请选择参建类型',
      createNewUnit: '新建参建单位',
      categoryLabel: '项目分类',
      builderLicense: '施工许可证',
      builderLicenseNum: '施工许可证号：',
      enterBuilderLicenseNum: '请输入施工许可证',
      builderLicenseImg: '施工许可证图：',
      addBuilderLicense: '新增施工许可证',
      modules: '使用模块',
      add: '添加',
      selectProjectStatus: '请选择项目状态',
      enterProjectAddress: '请输入项目地址',
      enterContactName: '请输入联系人姓名',
      enterContactPhone: '请输入联系人电话',
      selectModule: '请选择模块',
    },
    home:{
      welcomeBack: '欢迎回来，',
      welcomeMessage: '欢迎您回到智慧建筑控制台',
      overviewTitle: '费用 - 概览',
      recharge: '充值',
      noticeTitle: '公告 - 通知',
      noNotice: '暂无通知~',
      notice1: '12312',
      notice2: '12312',
      notice3: '12312',
      more: '更多',
      recentExpensesTitle: '费用 - 最近消费',
      currentMonthExpenses: '本月消费',
      compareLastMonth: '对比上月',
      viewMoreExpenseDetails: '查看更多消费明细',
      recentOperationsTitle: '最近操作'
    },
    user: {
      title: '安全设置',
      accountName: '帐号名称',
      accountNameNote: '（设置后无法更改）',
      setAccount: '设置',  
      passwordManagement: '密码管理',
      changePassword: '修改',
      weChatID: '微信号',
      bind: '绑定',
      phoneNumber: '手机号',
      unbindWeChat: '解绑微信',
      scanQrCode: '微信扫描二维码即可解绑',
      verifyUnbinding: '解绑验证',
      unbindPhone: '解绑手机',
      phoneNumber: '手机号码',
      enterPhoneNumber: '请输入手机号码',
      verificationCode: '验证码',
      enterVerificationCode: '请输入验证码',
      getVerificationCode: '获取验证码',
      slideRight: '向右滑动',
      enterVerificationCode: '请输入验证码',
      confirm: '确认',
      phoneRequired: '手机号码不能为空',
      bindPhone: '绑定手机', // Translation for the dialog title
      phoneNumber: '手机号码', // Translation for label
      verificationCode: '验证码', // Translation for label
      enterPhoneNumber: '请输入手机号码', // Translation for placeholder
      enterVerificationCode: '请输入验证码', // Translation for placeholder
      getVerificationCode: '获取验证码', // Translation for button text
      set: '设置', // Translation for button text
      slideRight: '向右滑动', // Translation for slide hint
      wxBinding: "微信绑定",
      wxScan: "微信扫描二维码即可绑定",
      bindingVerification: "绑定验证",
      accountName: "账号名称",
      enterAccountName: "请输入帐号名称",
      cancel: "取消",
      set: "设置",
      accountRequired: "帐号不能为空",
      changePassword: "修改密码",
      password: "密码",
      enterPassword: "请输入你要修改的密码",
      confirmPassword: "请确认密码",
      cancel: "取消",
      change: "修改",
      accountConfirmation: "帐号设置后不能修改，是否使用 {account}?",
      accountReminder: "账号设置提醒",
      confirm: "确定",
      cancel: "取消"
    },
    modules:{
      selectApp: '请选择您要操作的应用',
      openNewPage: '打开新页面',
      reloginMessage: '由于用户长时间没有操作，请重新登录',
    },
    corp: {
      loadingText: '拼命加载中',
      unifiedSocialCreditCode: '统一社会信用代码',
      editInfo: '信息编辑',
      cancelEdit: '取消编辑',
      labelName: '企业名称',
      labelCreditCode: '统一社会信用代码',
      labelRegistrationType: '企业登记注册类型',
      labelCapitalCurrency: '资本币种',
      registeredCapital: '注册资本(万)',
      paidCapital: '实收资本(万)',
      registerDate: '注册日期',
      establishDate: '成立日期',
      placeholderRegisteredCapital: '请输入注册资本，单位万',
      placeholderPaidCapital: '请输入实收资本，单位万',
      selectDateTime: '选择日期时间',
      placeholderRegistrationType: '请选择企业登记注册类型',
      placeholderCapitalCurrency: '请选择资本币种',
      legalRepresentativeName: '法定代表人姓名',
      legalRepresentativeDuty: '法定代表人职务',
      enterLegalRepresentativeDuty: '请输入法定代表人职务',
      legalRepresentativeTitle: '法定代表人职称',
      enterLegalRepresentativeTitle: '请输入法定代表人职称',
      legalRepresentativeIdCardType: '法定代表人证件类型',
      selectIdCardType: '请选择证件类型',
      legalRepresentativeIdCardNumber: '法定代表人证件号码',
      enterLegalRepresentativeIdCardNumber: '请输入法定代表人证件号码',
      contactPersonName: '联系人姓名',
      enterContactPersonName: '请输入联系人姓名',
      contactPersonPhone: '联系人电话',
      enterContactPersonPhone: '请输入联系人电话',
      officePhone: '办公电话',
      enterOfficePhone: '请输入办公电话',
      faxNumber: '传真号码',
      enterFaxNumber: '请输入传真号码',
      zipCode: '邮政编码',
      enterZipCode: '请输入邮政编码',
      companyEmail: '企业邮箱',
      enterCompanyEmail: '请输入企业邮箱',
      companyWebsite: '企业网址',
      enterCompanyWebsite: '请输入企业网址',
      businessLicense: '营业执照',
      submitNow: '立即提交',
      emailFormat: '请输入正确的邮箱号码',
      invalidDate: '不能选择今天以后的日期'
    }
  }
}
  