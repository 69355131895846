/*
 * @Descripttion: 
 * @version: 
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-09 17:56:46
 */ 
export  default  [
  {
    path: '/modules/certification/',
    name: 'certificationIndex',
    component: ()=>import("@/views/Index.vue"),
    redirect: { path: '/modules/certification/' },
    meta: {title: 'certification.router.personnelRealNameSystem'},
    // 嵌套路由 》》 对应着Home组件中的router-viewa
    children: [
      // 嵌套路由 》》 对应着Home组件中的router-view
      {name: 'home', path: '/', component: ()=>import("@/views/certification/home/Index.vue"), meta: {breadcrumb: false}},
      // 员工管理
      {name: 'staffList', path: 'staff/list', component: ()=>import("@/views/certification/staff/Index.vue"), meta: {title: 'certification.router.personnelList'}},
      {name: 'auditList', path: 'staff/audit', component: ()=>import("@/views/certification/staff/Audit.vue"), meta: {title: 'certification.router.personnelAudit'}},
      {name: 'clock', path: 'attendance/clock', component: ()=>import("@/views/certification/attendance/ClockIn.vue")},
      {name: 'staffDetailInfo', path: 'staff/detail/info', component: ()=>import("@/views/certification/staff/Info.vue")},
      // 班组管理
      {name: 'team', path: 'team', component: ()=>import("@/views/certification/team/Index.vue"), meta: {title: 'certification.router.teamManagement'}},
      //考勤机管理
      {name: 'machineManage', path: 'machineManage', component: ()=>import("@/views/certification/machineManage/Index.vue"), meta: {title: 'certification.router.deviceManagement'}},
      //考勤管理页面
      {name: 'attendance', path: 'attendance', component: ()=>import("@/views/certification/attendance/Index.vue"), meta: {title: 'certification.router.attendanceManagement'}},
      // 政府对接进度
      {name: 'governmentStatus', path: 'governmentStatus', component: ()=>import("@/views/certification/governmentStatus/Index.vue"), meta: {title: 'certification.router.governmentIntegrationProgress'}},
      //工资管理
      {name: 'payList', path: 'pay/list', component: ()=>import("@/views/certification/pay/Index.vue"), meta: {title: 'certification.router.salaryList'}},
      {name: 'payBack', path: 'pay/back', component: ()=>import("@/views/certification/pay/Back.vue"), meta: {title: 'certification.router.salaryBackup'}},
      //分账管理
      {name: 'ledgerDailyWork', path: 'ledger/dailyWork', component: ()=>import("@/views/certification/ledger/dailyWork.vue"), meta: {title: 'certification.router.pendingDailyWorks'}},
      {name: 'ledgerMonthlyWork', path: 'ledger/monthlyWork', component: ()=>import("@/views/certification/ledger/monthlyWork.vue"), meta: {title: 'certification.router.pendingMonthlyWorks'}},
      {name: 'monthlyWorkDetail', path: 'ledger/details/monthlyWorkDetail', component: ()=>import("@/views/certification/ledger/details/monthlyWorkDetail.vue")},
    ]
    
  }
];
