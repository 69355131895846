/*
 * @Author: Terry
 * @Date: 2022-06-02 20:57:17
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-09 18:20:33
 * @Description: file content
 * @FilePath: \auxface_web\src\views\greenSite\router.js
 */

export default [
  {
    path: '/modules/greenSite',
    name: 'grrenSiteIndex',
    component: ()=>import("@/views/Index.vue"),
    meta: {title: 'greenSite.router.greenSite'},
    redirect: { path: '/modules/greenSite' },
    children: [
      {name: 'dustList', path: 'dust/list', component: ()=>import("@/views/greenSite/dust/Index.vue"), meta: {title: 'greenSite.router.dustDevices'}},
      {name: 'dustDetail', path: 'dust/detail', component: ()=>import("@/views/greenSite/dust/Details.vue")},
      {name: 'electricList', path: 'electric/list', component: ()=>import("@/views/greenSite/electric/Index.vue"), meta: {title: 'greenSite.router.electricMeterManagement'}},
      {name: 'electricDetail', path: 'electric/detail', component: ()=>import("@/views/greenSite/electric/Details.vue")},
      {name: 'waterList', path: 'water/list', component: ()=>import("@/views/greenSite/water/Index.vue"), meta: {title: 'greenSite.router.waterMeterManagement'}},
      {name: 'waterDetail', path: 'water/detail', component: ()=>import("@/views/greenSite/water/Details.vue")},
    ]
  },
];



