/*
 * @Author: Terry
 * @Date: 2022-06-02 20:57:17
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-09 18:30:39
 * @Description: file content
 * @FilePath: \auxface_web\src\views\corp\router.js
 */
export default [
  {
    path: '/corpSafetytrain',
    name: 'corpIndex',
    component: ()=>import("@/views/Index.vue"),
    redirect: { path: '/corpSafetytrain' },
    meta: {title: 'corpSafetytrain.router.safetyTrainingEnterprise'},
    children: [
      {name: 'course', path: 'center', component: ()=>import("@/views/corp/courseCenter/Index.vue"), meta: {title: 'corpSafetytrain.router.courseCenter'}},
      {name: 'courseCenterDetails', path: 'centerDetails', component: ()=>import("@/views/corp/courseCenter/Details.vue")},
      {name: 'classCenter', path: 'classCenter', component: ()=>import("@/views/corp/classCenter/Index.vue"), meta: {title: 'corpSafetytrain.router.classificationManagement'}},
      {name: 'paper', path: 'paper', component: ()=>import("@/views/corp/paper/Index.vue"), meta: {title: 'corpSafetytrain.router.paperManagement'}},
      {name: 'paperQuestion', path: 'paperQuestion', component: ()=>import("@/views/corp/paper/PaperQuestion.vue")},
      {name: 'questionBank', path: 'questionBank', component: ()=>import("@/views/corp/questionBank/Index.vue"), meta: {title: 'corpSafetytrain.router.questionBankManagement'}},
    ]
  },
];