<!--
 * @Descripttion: 
 * @version: 
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Terry
 * @LastEditTime: 2024-09-02 11:20:01
-->
<template>
  <div>
    <el-row>
      <el-col :span="18" class="hidden-md-and-down">
        <span
          @click="goHome"
          class="header-logo m-l-30"
        >
          <span class="logo-text float-left m-l-10">{{prjName}}</span>
        </span>
      </el-col>
      <el-col :span="6">
        <span class="float-right m-r-25 dropdown">
          <i
          v-if="moduleChooseShow"
          @click="moduleShow = !moduleShow"
          class="el-icon-menu  menu"></i>
          <!-- 添加切换一个多语言切换按钮-开始 -->
            <el-dropdown class="lang"  trigger="click" @command="changeLang">
              <span class="el-dropdown-link">
                {{curLang}}<span class="el-icon-arrow-down el-icon--right"></span>
              </span>
              <el-dropdown-menu slot="dropdown">
                <span v-for="(item , key ) in lang"  :key="key" >
                  <el-dropdown-item :command="item">{{ item.label }}</el-dropdown-item>
                </span>
              </el-dropdown-menu>
            </el-dropdown>
        <!-- 添加切换一个多语言切换按钮-结束 -->
          <i
            :title="back"
            v-if="backPrjShow"
            @click="toProjectList()"
            class="iconfont ali-icon-fanhui1 back"
          ></i>
          <span class="exit"   :title="exit" @click="logout()">
            <i
              class="el-icon-switch-button"
              ></i>
              <span class="text">{{$t('global.logout')}}</span>
          </span>
        </span>
      </el-col>
    </el-row>
    <div class="mask" v-if="moduleShow" @click="moduleShow = false">
      <div class="module">
        <div
          class="list"
          v-loading="pageLoad"
          :element-loading-text="$t('global.loading')"
        >
            <vue-context-menu
              class="context-menu"
              :contextMenuData="contextMenuData"
              @toModule="openNew()"
            ></vue-context-menu>
          <div
            v-for="(value, key) in list"
            :key="key"
            :class="[key === index ? 'active' : '','moduleList']"
            @click="toModule(value.module , 1)"
            @contextmenu.prevent="rightShow(value.module, 2,key)"
          >
            <div
              :class="'iconfont ali-icon-' + value.icon + ' moudule-icon'"
            ></div>
            <strong class="module-title">{{ value.name }}</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getList } from "../views/platform/api/module"
import { getModuleAside } from "../views/platform/api/menu"
export default {
  data() {
    return {
      curLang:'简体中文',
      prjName: this.$t('global.prjName'),
      //右侧头像
      index: null,
      avatar: "",
      moduleChooseShow: true,
      backPrjShow: true,
      exit: this.$t('global.exit'),
      back: this.$t('global.back'),
      moduleShow: false,
      list: [],
      pageLoad: true,
      //菜单
      contextMenuData: {
        axis: {
          x: null,
          y: null,
        },
        menulists: [
          {
            fnHandler: "toModule",
            btnName: this.$t('global.btnName'),
          },
        ],
      },
      module: "",
      status: null,
      lang:[
        {
          label:'简体中文',
          command:'zh-cn',
        },
        {
          label:'繁体中文',
          command:'zh-tw',
        },
        {
          label:'English',
          command:'en',
        },
        // {
        //   label:'English',
        //   command:'en',
        // },
      ],
    };
  },
  watch: {
    "$route.path"(to) {
      this.getPrjName(); 
      this.handelNavStatus();
      if (this.moduleChooseShow) this.choosed(to);
    },
  },
  methods: {
    goHome(){
      this.$router.push({ name: process.env.VUE_APP_HOME })
    },
    changeLang(command) {
      localStorage.setItem('language',command.command);
      this.$i18n.locale = command.command
      // 刷新当前页面
      location.reload();
    },
    chekcLang(){
      var lang = localStorage.getItem('language');
      console.log(lang);
      this.lang.forEach(item=>{
        if(item.command == lang) this.curLang = item.label;
      })
    },
    choosed(path) {
      this.list.forEach((element, index) => {
        if (path.indexOf(element.module) > -1) {
          this.index = index;
        }
      });
    },
    handelNavStatus() {
      var path = this.$route.path.split("/");
      //在平台页面没有返回项目按钮和模块选择按钮
      if (path[1] == "platform") {
        this.backPrjShow = false;
        this.moduleChooseShow = false;
        let corpCode = sessionStorage.getItem("myCorpCode");
        sessionStorage.setItem("corpCode", corpCode);
      } else if (path[1] == "modules") {
        // 模块选择页面没有模块选择按钮
        this.backPrjShow = true;
        this.moduleChooseShow = false;
        //进入项目后有模块选择按钮
        if (path[2] != undefined && path[2] != "") {
          this.moduleChooseShow = true;
          return;
        }
      } else {
        //企业版和集成商只需要返回按钮
        this.backPrjShow = true;
        this.moduleChooseShow = false;
      }
      this.moduleShow = false;
    },
    //退出登录
    logout() {
      this.$confirm(this.$t('global.logoutConfirm'), this.$t('global.prompt'), {
          confirmButtonText: this.$t('global.confirm'),
          cancelButtonText: this.$t('global.cancel'),
          type: 'warning'
        }).then(() => {
          // var lang = localStorage.getItem('language');
          sessionStorage.clear();
          // sessionStorage.setItem('language',lang);
          this.$router.push({ name: "login" });
          this.$message({ type: 'success', message: this.$t('global.logoutSuccess') });
        })
    },
    toProjectList() {
      this.$router.push({ name: "projectList" });
    },
    getPageData() {
        getList()
          .then((res) => {
            this.list = res.data;
            this.choosed(this.$route.path);
          })
          .finally(() => {
            this.pageLoad = false;
          });
    },
    //右键打开新页面
    rightShow(module, status) {
      this.module = module;
      this.status = status;
      event.preventDefault();
      var x = event.clientX;
      var y = event.clientY;
      this.contextMenuData.axis = {
        x,
        y,
      };
      
    },
    openNew() {
      this.toModule(this.module,this.status);
    },
    toModule(module, status) {
      var load = this.loading();
      getModuleAside({ module: module })
      .then((res) => {
        var aside = res.data["aside"],
        button = res.data["button"];
        sessionStorage.setItem("module",module);
        sessionStorage.setItem("modulesButtonType", JSON.stringify(button));
        sessionStorage.setItem("modulesAside", JSON.stringify(aside));
        
        var url = aside[0].pageUrl || aside[0]['children'][0].pageUrl;
        if(url.indexOf('http')>-1){
          var token = sessionStorage.getItem('auxfaceToken'),
          uuid = sessionStorage.getItem('uuid'),
          puuid = sessionStorage.getItem('puuid'),
          cuuid = sessionStorage.getItem('corpCode');
          url = url + "?token=" + token + "&uuid=" + uuid  + "&prjCode=" + puuid + "&corpCode=" + cuuid 
          window.open(url, "_blank"); 
          return;
        }
        console.log(status);
          // if (aside[0].children) url = aside[0].children.pageUrl;
          if (status == 2) {
            let routeUrl = this.$router.resolve({ path: url });
            window.open(routeUrl.href, "_blank");
          } else {
            this.$router.push({ path: url });
          }
        })
        .finally(() => {
          load.close();
        });
    },
    getPrjName(){
      let prjName = sessionStorage.getItem("projectName");
      this.prjName = prjName ? prjName : this.$t('global.prjName');
    }
  },
  mounted() {
    if(this.$route.path.indexOf('module') >-1){
      this.getPageData();
    }
    this.chekcLang();
    this.handelNavStatus();
    this.getPrjName(); 
    this.avatar = sessionStorage.getItem("avatarUrl");
  },
};
</script>

<style lang="scss" scoped>
.project-name,
.module-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  display: block;
  float: left;
  width: 90%;
}
.mask {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}
.dropdown {
  height: 60px;
  display: flex;
  align-items: center;
}
.header-logo {
  cursor: pointer;
  align-items: center;
  display: flex;
  width: 100%;
}
.header-logo img {
  width: 40px;
}
.logo-text {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 60px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.prjChoosed,
.module-choose {
  width: 100%;
  z-index: 99999;
  cursor: pointer;
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.8);
}
.exit {
  font-size: 20px;
  color: white;
  cursor: pointer;
  color: rgb(211, 220, 244);
  i{
    margin-right: 5px;
  }
  &:hover{
    color: #eb4141;
  }
  .text{
    font-weight: 300;
    font-size: 16px;
  }
}
.menu {
  font-size: 32px;
  color: rgb(211, 220, 244);
  cursor: pointer;
  margin-right: 25px;
  &:hover{
  background-color: #cecdcd;
  }
}
.lang{
  font-weight: bold;
  font-size: 17px;
  color: rgb(211, 220, 244);
  cursor: pointer;
  margin-right: 20px;
  }

.back {
  font-size: 20px;
  color: rgb(211, 220, 244);
  cursor: pointer;
  margin-right: 25px;
  &:hover {
  color: #4d5a85;
}
}

.module {
  position: absolute;
  width: 300px;
  right: 70px;
  padding: 15px;
  z-index: -1;
  background: white;
  box-shadow: 0 4px 8px 0 #999;
  border-radius: 6px;
  border: 1px solid #edeeee;
}
.list {
  width: 100%;
  min-height:150px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.moduleList {
  float: left;
  margin-bottom: 25px;
  cursor: pointer;
  border-radius: 15px;
  width: 29%;
  height: 80px;
  text-align: center;
  // margin-right: 0;
  &.active,
  &:hover {
    background: #edeeee;
  }
}
.moudule-icon {
  color: #2d8be9;
  line-height: 50px;
  font-size: 25px;
}
.module-title {
  color: #646a73;
  font-size: 13px;
}
.context-menu {
  padding-left: 0;
  font-family: "微软雅黑";
}
 /deep/ .btn-wrapper-simple {
  height: 30px !important;
}
 /deep/ .vue-contextmenu-listWrapper .context-menu-list {
  margin: 0 !important;
}
 /deep/ .nav-name-right {
    text-align:center !important;
  margin: 0 26px !important;
  height: auto !important;
  line-height:25px !important
}
 /deep/ .vue-contextmenu-listWrapper .context-menu-list {
  margin: 0 !important;
}
</style>