/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-09 16:25:57
 * @Description: file content
 * @FilePath: \auxface_web\src\views\corp\lang\zh-tw.jsx
 */
export default {
  corpSafetytrain: {
    router: {
      safetyTrainingEnterprise: '安全培訓-企業版',
      courseCenter: '課程中心',
      classificationManagement: '分類管理',
      paperManagement: '試卷管理',
      questionBankManagement: '題庫管理'
    },    
    classCenter: {
      title: '分類詳情',
      categoryManagement: '分類管理',
      addCategory: '添加分類',
      applicablePersonnel: '適用人員',
      selectApplicablePersonnel: '請選擇適用人員',
      search: '查詢',
      detailInformation: '詳細信息',
      delete: '刪除',
      noRemark: '無備註',
      confirmDeleteCategory: '是否確認刪除此分類？',
      deleteConfirmation: '刪除確認',
      confirm: '確定',
      cancel: '取消',
      sort: '排序',
      category: '分類',
      enterCategoryName: '請輸入分類名稱',
      applicablePersonnel: '適用人員',
      selectApplicablePersonnel: '請選擇適用人員',
      remark: '備註',
      remarkPlaceholder: '在此填寫備註',
      nameRequired: '分類名稱不能為空',
      sortRequired: '請選擇排序位置',
      workerRoleRequired: '適用人員不能為空',
      sort: '排序',
      name: '名字',
      workerRole: '適用人員',
      remark: '備註',
      detail: '詳細信息',
      delete: '刪除',
    },
    questionBank: {
      title: '題庫管理',
      questionBankManagement: '題庫管理',
      addQuestion: '添加題目',
      selectCategory: '請選擇分類：',
      selectCategoryPlaceholder: '請選擇分類',
      enterQuestionName: '請輸入題目名稱',
      enterQuestionNamePlaceholder: '請輸入題目名稱',
      search: '查詢',
      questionName: '題目名稱',
      authorName: '出題人',
      category: '分類',
      uploadTime: '上傳時間',
      delete: '刪除',
      confirmDelete: '是否確認刪除此題目？',
      deleteConfirmation: '刪除確認',
      confirm: '確定',
      cancel: '取消',
      questionName: '題目名稱',
      enterQuestionName: '請輸入題目名稱',
      image: "圖片：",
      uploadSuccess: "上傳成功",
      author: "出題人：",
      enterAuthorName: "請輸入出題人名稱",
      category: "分類：",
      selectCategory: "請選擇分類",
      questionType: "題目類型：",
      singleChoice: "單選",
      multipleChoice: "多選",
      options: "題目選項：",
      addOption: "添加選項",
      delete: "刪除",
      answer: "題目答案：",
      enterQuestionName: "請輸入題目名稱",
      lengthBetween1And50: "長度在 1 到 50 個字符",
      enterAuthorName: "請輸入出題人名稱",
      lengthBetween1And10: "長度在 1 到 10 個字符",
      enterOptions: "請輸入選項",
      enterScore: "請輸入分數",
    },
    paper: {
      title: '試卷詳情',
      paperManagement: '試卷管理',
      addPaper: '添加試卷',
      examName: "試卷名稱：",
      enterExamName: "請輸入試卷名稱",
      classification: "分類：",
      selectClassification: "請選擇分類",
      search: "查詢",
      questionData: '題目數據',
      delete: '刪除',
      examName: '試卷名稱',
      authorName: '作者',
      classUuid: '所屬分類',
      detail: '詳細信息',
      uploadTime: '上傳時間',
      delete: '刪除',
      confirmDelete: '是否確認刪除此試卷？',
      deleteConfirmation: '刪除確認',
      confirm: '確定',
      cancel: '取消',
      examName: '試卷名稱',
      enterExamName: '請輸入試卷名稱',
      author: '作者',
      enterAuthorName: '請輸入作者名稱',
      category: '分類',
      selectCategory: '請選擇分類',
      totalScore: '試卷總分',
      passingScore: '試卷及格分',
      enterPassingScore: '請輸入內容',
      enterExamName: '請輸入試卷名稱',
      examNameLength: '長度在 1 到 50 個字符',
      selectCategory: '請選擇分類',
      examDetailData: '試卷題目詳細數據',
      syncSubject: '同步題目',
      addSubject: '添加題目',
      enterQuestionName: '請輸入題目名稱',
      questionScore: '題目分值',
      enterQuestionScore: '請輸入題目分值',
      questionType: '題目類型',
      selectQuestionType: '請選擇題目類型',
      search: '查詢',
      image: "圖片",
      uploadTime: "上傳時間",
      singleChoice: "單選題",
      multipleChoice: "多選題",
      uploadTime: "上傳時間",
      delete: "刪除",
      singleChoice: "單選題",
      multipleChoice: "多選題",
      confirmDeleteQuestion: "是否確認刪除此試題目？",
      deleteConfirmation: "刪除確認",
      confirm: "確定",
      cancel: "取消",
      score: "分值",
      answer: "題目答案：",
      questionNameLength: "長度在 1 到 50 個字符",
      enterAuthorName: "請輸入出題人名稱",
      authorNameLength: "長度在 1 到 10 個字符",
      enterOptions: "請輸入選項",
      enterScore: "請輸入分值",
      addOption:'添加選項',
      questionName: "題目名稱",
      questionStatus: "題目類型",
      classification: "題目分類",
      batchSelectScore: "批量選擇分值",
      score: "分值",
      selectClassification: "請選擇分類",
      questionType: '題目類型',
      multipleChoice: '多選題',
      singleChoice: '單選題',
      author: '出題人',
      score: '分值',
      add :'添加',
      image: "圖片",
      singleChoice: '單選',
      multipleChoice: '多選',
      selectQuestionToAdd: '請選擇待添加的題目'
    },
    courseCenter: {
      detailTitle: '課程目錄詳情',
      title: '課程中心詳情',
      courseCenter: '課程中心',
      courseAdd: '添加課程',
      search: '搜索',
      selectCategory: '選擇分類',
      selectApplicablePerson: '選擇適用人員',
      enterCourseName: '請輸入課程名稱',
      noDescription: '無描述',
      delete: '刪除',
      sort: '排序',
      courseName: '課程名稱',
      image: '圖片',
      category: '分類',
      applicablePerson: '適用人員',
      publishTime: '發布時間',
      courseDescription: '課程描述',
      catalogInfo: '目錄信息',
      confirmDeleteCourse: '是否確認刪除此課程？',
      deleteConfirmation: '刪除確認',
      confirm: '確定',
      cancel: '取消',
      courseImage: '課程圖片',
      enterCourseDescription: '請輸入課程描述',
      courseNameRequired: '課程名稱不能為空',
      sortRequired: '請選擇排序位置',
      categoryRequired: '課程分類不能為空',
      imageRequired: '圖片不能為空',
      applicablePersonRequired: '適用人員不能為空',
      courseDescriptionRequired: '課程描述不能為空',
      changeVideo: '更改視頻',
      uploadFailed: '上傳失敗',
      invalidVideoFormat: '請上傳正確的視頻格式',
      videoSizeExceeded: '上傳視頻大小不能超過1024M！',
      uploadLimit: '當前限制上傳1個文件',
      uploadOnlyDocument: '上傳文件只能是文檔格式！',
      documentSizeExceeded: '上傳文檔大小不能超過5MB！',
      changeDocument: '更改文檔',
      deleteConfirmationMessage: '是否確認刪除此課程？',
      deleteConfirmationTitle: '刪除確認',
      chapterName: '章節名稱',
      fileType: '類型',
      describe: '內容介紹',
      publishTime: '發布時間',
      remark: '備註',
      selectCategoryPlaceholder: '請選擇試卷分類',
      enterExamNamePlaceholder: '請輸入試卷名稱',
      examCategory: '試卷分類',
      examName: '試卷名稱',
      sync: '同步',
      chapterName: '章節名稱：',
      enterChapterName: '請輸入章節名稱',
      fileType: '類型：',
      selectType: '請選擇類型',
      sort: '排序：',
      syncChapterExam: '章節試卷可進行同步',
      chapterExam: '章節試卷：',
      syncExam: '試卷同步',
      minStudyTime: '最少學習時間：',
      minutes: '分',
      seconds: '秒',
      contentIntro: '內容介紹：',
      enterContentIntro: '請輸入內容介紹',
      media: '視頻圖文：',
      remark: '備註：',
      enterRemark: '請輸入備註',
      chapterNameRequired: '章節名稱不能為空',
      contentIntroRequired: '內容介紹不能為空',
      sortRequired: '請選擇排序位置',
      mediaRequired: '視頻圖文不能為空',
      courseTypeRequired: '課程類型不能為空',
      minStudyTimeRequired: '最少學習時間不能為空',
    },
  }
}