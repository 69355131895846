/*
 * @Descripttion: 
 * @version: 
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-04 16:57:06
 */

import Vue from "vue";
import VueRouter from "vue-router";


Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}

export function autoLoadModule(){
    return new Promise((resolve,reject)=>{
        //自动导入router.js 和API.js文件
        var modelRoutes = require.context('@/views/', true, /(api|router)\.js$/),
        // router=[], api = commonApi;
        router=[], api = {};

        modelRoutes.keys().forEach(element => {
            var value = modelRoutes(element)
            if(value.default){ 
                router = [...router,...value.default]
            }else if(JSON.stringify(value)!='{}'){
                api = {...api,...value}
            }
        });
        var router = new VueRouter({
            mode: "history",
            base: process.env.BASE_URL,
            routes:router
        });
        var data = {
           'router' : router,
           'api' : api,
        }
        resolve(data);
    })
}
