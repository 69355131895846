/*
 * @Descripttion: 
 * @version: v1
 * @Author: Terry
 * @Date: 2021-12-10 18:52:54
 * @LastEditors: your name
 * @LastEditTime: 2022-11-21 15:55:46
 */

//正式版本
var domain = process.env.VUE_APP_BASE_WSS;
// var domain = 'ws://192.168.65.128:';
const BI={
    'BI':{
        'certification':domain + '/certification',
        'greenSite':domain + '/greenSite',
        'elevator':domain + '/elevator',
        'towerCrane':domain + '/towerCrane',
    }
}
module.exports =  BI;