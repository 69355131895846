/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2024-02-15 18:13:35
 * @Description: file content
 * @FilePath: \auxface_web\src\views\platform\lang\zh-tw.jsx
 */
export default {
  platform:{
    router: {
      title: 'Edan Smart Platform',
      projectCreateTitle: '添加項目',
      projectListTitle: '項目列表',
      accountListTitle: '帳號管理',
      corpAuditTitle: '企業審核',
      corpListTitle: '企業列表',
      governmentListTitle: '政府帳號',
      userCenterTitle: '個人中心',
      scaffoldManagement:'脚手架管理',
    },
    auth:{
      accountLabel: "賬號",
      passwordLabel: "密碼",
      bigTitle:"Edan Smart Platform",
      welcome:"你好，歡迎使用",
      title:"Edan Smart Platform",
      loginBtn:"登錄",
      account:"賬號",
      password:"密碼",
      forget:"忘記密碼?",
      notAccount:"沒有賬號?",
      register:"註冊賬號",
      accountTips:"請輸入賬號/手機號碼",
      passwordTips:"請輸入密碼",
      accountLogin:"賬號登錄",
      scanLogin:"掃碼登錄",
      leftTip1:"官方微信",
      leftTip2:"微信掃描二維碼",
      leftTip3:'或搜索公眾號"建采會"',
      rightTip1:"微信小程序",
      rightTip2:"微信掃描二維碼",
      rightTip3:'或搜索公眾號"智慧工地手機端"',
      accountRequired:'賬號不能為空',
      pwdRequired:'密碼不能為空',
      pwdLen:'密碼長度在6-16位',
      pwdAgain:'請再次輸入密碼',
      phoneTips:'請輸入手機號碼',
      rePwdTips:'請再次輸入密碼',
      verifyTips:'請輸入驗證碼',
      getVerify:'獲取驗證碼',
      login:'登錄賬號',
      resetBtn:'重置密碼',
      qrCodeFail:'二維碼失效',
      refresh:'刷新',
      hasAccount:'已有賬號？',
      registerBtn:'註冊賬號',
      slideTips:'向右滑',
    },
    project: {
      name: '項目名稱',
      nameTips: '請輸入項目名稱',
      searchBtn: '查詢',
      rate: '出勤率',
      enterPrj: '進入項目',
      noPermissions: '該項目沒有無權限',
      unjointed: '未對接',
      normal: '無異常',
      member: '人員',
      memberTips: '人對接異常',
      attendance: '考勤',
      team: '班組',
      errorTips: '條對接異常',
      corpName: '總承包單位',
      code: '項目編號',
      linkMan: '聯繫人',
      linkPhone: '聯繫電話',
      teamCount: '在場班組',
      attendanceCount: '今日考勤數/總人數',
      error: '實名制對接異常（可點擊）',
      // create
      recordNumber: '項目投資備案編號',
      enterRecordNumber: '請輸入項目投資備案編號',
      enterName: '請輸入項目名稱',
      enterLinkMan: '請輸入聯繫人姓名',
      enterLinkPhone: '請輸入聯繫人電話',
      address: '項目地址',
      enterAddress: '請輸入項目地址',
      area: '所屬區域',
      selectCategory: '請選擇項目分類',
      participatingUnits: '參建單位',
      companyName: '企業名稱',
      enterCompanyName: '請輸入企業名稱',
      delete: '刪除',
      participatingType: '參建類型',
      selectParticipatingType: '請選擇參建類型',
      createNewUnit: '新建參建單位',
      categoryLabel: '項目分類',
      builderLicense: '施工許可證',
      builderLicenseNum: '施工許可證號：',
      enterBuilderLicenseNum: '請輸入施工許可證',
      builderLicenseImg: '施工許可證圖：',
      addBuilderLicense: '新增施工許可證',
      modules: '使用模塊',
      add: '添加',
      selectProjectStatus: '請選擇項目狀態',
      enterProjectAddress: '請輸入項目地址',
      enterContactName: '請輸入聯繫人姓名',
      enterContactPhone: '請輸入聯繫人電話',
      selectModule: '請選擇模塊',
    },
    home:{
      welcomeBack: '歡迎回來，',
      welcomeMessage: '歡迎您回到Edan Smart Platform',
      overviewTitle: '費用 - 概覽',
      recharge: '充值',
      noticeTitle: '公告 - 通知',
      noNotice: '暫無通知~',
      notice1: '12312',
      notice2: '12312',
      notice3: '12312',
      more: '更多',
      recentExpensesTitle: '費用 - 最近消費',
      currentMonthExpenses: '本月消費',
      compareLastMonth: '對比上月',
      viewMoreExpenseDetails: '查看更多消費明細',
      recentOperationsTitle: '最近操作'
    },
    user: {
      title: '安全設置',
      accountName: '帳號名稱',
      accountNameNote: '（設置後無法更改）',
      setAccount: '設置',
      passwordManagement: '密碼管理',
      changePassword: '修改',
      weChatID: '微信號',
      bind: '綁定',
      phoneNumber: '手機號',
      unbindWeChat: '解綁微信',
      scanQrCode: '微信掃描二維碼即可解綁',
      verifyUnbinding: '解綁驗證',
      unbindPhone: '解綁手機',
      phoneNumber: '手機號碼',
      enterPhoneNumber: '請輸入手機號碼',
      verificationCode: '驗證碼',
      getVerificationCode: '獲取驗證碼',
      slideRight: '向右滑動',
      enterVerificationCode: '請輸入驗證碼',
      confirm: '確認',
      phoneRequired: '手機號碼不能為空',
      bindPhone: '綁定手機',
      phoneNumber: '手機號碼',
      verificationCode: '驗證碼',
      enterPhoneNumber: '請輸入手機號碼',
      enterVerificationCode: '請輸入驗證碼',
      getVerificationCode: '獲取驗證碼',
      set: '設置',
      slideRight: '向右滑動',
      wxBinding: "微信綁定",
      wxScan: "微信掃描二維碼即可綁定",
      bindingVerification: "綁定驗證",
      accountName: "帳號名稱",
      enterAccountName: "請輸入帳號名稱",
      cancel: "取消",
      set: "設置",
      accountRequired: "帳號不能為空",
      changePassword: "修改密碼",
      password: "密碼",
      enterPassword: "請輸入你要修改的密碼",
      confirmPassword: "請確認密碼",
      change: "修改",
      accountConfirmation: "帳號設置後不能修改，是否使用 {account}?",
      accountReminder: "帳號設置提醒",
      confirm: "確定",
    },
    modules:{
      selectApp: '請選擇您要操作的應用',
      openNewPage: '打開新頁面',
      reloginMessage: '由於使用者長時間沒有操作，請重新登錄',
    },
    corp: {
      unifiedSocialCreditCode: '統一社會信用代碼',
      editInfo: '信息編輯',
      cancelEdit: '取消編輯',
      labelName: '企業名稱',
      labelCreditCode: '統一社會信用代碼',
      labelRegistrationType: '企業登記註冊類型',
      labelCapitalCurrency: '資本幣種',
      registeredCapital: '註冊資本(萬)',
      paidCapital: '實收資本(萬)',
      registerDate: '註冊日期',
      establishDate: '成立日期',
      placeholderRegisteredCapital: '請輸入註冊資本，單位萬',
      placeholderPaidCapital: '請輸入實收資本，單位萬',
      selectDateTime: '選擇日期時間',
      placeholderRegistrationType: '請選擇企業登記註冊類型',
      placeholderCapitalCurrency: '請選擇資本幣種',
      legalRepresentativeName: '法定代表人姓名',
      legalRepresentativeDuty: '法定代表人職務',
      enterLegalRepresentativeDuty: '請輸入法定代表人職務',
      legalRepresentativeTitle: '法定代表人職稱',
      enterLegalRepresentativeTitle: '請輸入法定代表人職稱',
      legalRepresentativeIdCardType: '法定代表人證件類型',
      selectIdCardType: '請選擇證件類型',
      legalRepresentativeIdCardNumber: '法定代表人證件號碼',
      enterLegalRepresentativeIdCardNumber: '請輸入法定代表人證件號碼',
      contactPersonName: '聯繫人姓名',
      enterContactPersonName: '請輸入聯繫人姓名',
      contactPersonPhone: '聯繫人電話',
      enterContactPersonPhone: '請輸入聯繫人電話',
      officePhone: '辦公電話',
      enterOfficePhone: '請輸入辦公電話',
      faxNumber: '傳真號碼',
      enterFaxNumber: '請輸入傳真號碼',
      zipCode: '郵政編碼',
      enterZipCode: '請輸入郵政編碼',
      companyEmail: '企業郵箱',
      enterCompanyEmail: '請輸入企業郵箱',
      companyWebsite: '企業網址',
      enterCompanyWebsite: '請輸入企業網址',
      businessLicense: '營業執照',
      submitNow: '立即提交',
      emailFormat: '請輸入正確的郵箱號碼',
      invalidDate: '不能選擇今天以後的日期'
    }
  }
}
  