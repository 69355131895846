/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-09 15:57:47
 * @Description: file content
 * @FilePath: \auxface_web\src\views\greenSite\lang\zh-tw.jsx
 */
export default {
  greenSite:{
    router: {
      greenSite: '綠色工地',
      dustDevices: '揚塵設備',
      electricMeterManagement: '電表管理',
      waterMeterManagement: '水表管理'
    },    
    dust: {
      dustDeviceManagement: '揚塵設備管理',
      addDevice: '設備添加',
      enterDeviceName: '請輸入設備名稱',
      integrator: '集成商',
      selectIntegrator: '請選擇集成商',
      search: '查詢',
      online: '在線',
      onlineStatus: '在線狀態',
      detail:"詳細數據",
      offline: '離線',
      noRemark: '無備註',
      noData: '無數據',
      northWind: '北風',
      northeastByEast: '東北偏東',
      northeast: '正東北',
      northeastByNorth: '東北偏北',
      eastWind: '東風',
      southeastByEast: '東南偏東',
      southeast: '正東南',
      southeastBySouth: '東南偏南',
      southWind: '南風',
      southwestBySouth: '西南偏南',
      southwest: '正西南',
      southwestByWest: '西南偏西',
      westWind: '西風',
      northwestByWest: '西北偏西',
      northwest: '正西北',
      northwestByNorth: '西北偏北',
      detailData: '詳細數據',
      remove: '刪除',
      deviceName: '設備名稱',
      deviceNamePlaceholder: '請輸入設備名稱',
      deviceSerialNumber: '設備序列號',
      deviceSerialNumberPlaceholder: '請輸入設備序列號',
      integratorPlaceholder: '請選擇集成商',
      remark: '備註',
      remarkPlaceholder: '請輸入備註',
      deviceNameRequired: '請輸入設備名稱',
      deviceNameLength: '長度在 1 到 40 個字符',
      integratorRequired: '集成商不能為空',
      deviceSerialNumberRequired: '請輸入設備序列號',
      deviceSerialNumberLength: '長度在 1 到 40 個字符',
      noise: '噪音(dB)',
      windDirect: '風向',
      windSpeed: '風速(m/s)',
      temp: '溫度(℃)',
      humid: '濕度(%RH)',
      atoms: '氣壓(kpa)',
      inDate: '上傳時間',
      confirmDeleteDevice: '是否確認刪除此設備?',
      deleteConfirmation: '刪除確認',
      confirm: '確定',
      cancel: '取消',
      title:'揚塵設備管理詳情',
      deviceDetails: '設備詳細信息',
      dateLabel: '日期：',
      dateSeparator: '至',
      startDatePlaceholder: '開始日期',
      endDatePlaceholder: '結束日期',
      searchButton: '查詢'
      },
      electric:{
        title:'電錶管理詳情',
        electricMeterManagement: '電錶檢測管理',
        selectIntegrator: '請選擇集成商',
        search: '查詢' ,
        electricMeterManagement: '電錶檢測管理',
        addEquipment: '設備添加',
        offline: '離線',
        online: '在線',
        noRemark: '無備註',
        noData: '無數據',
        detailData: '詳細數據',
        delete: '刪除',
        integrator: "集成商",
        deviceName: "設備名稱",
        deviceSerialNumber: "設備序列號",
        onlineStatus: "在線狀態",
        remark: "備註",
        electricMeterReading: "電錶讀數(kWh)",
        uploadTime: "上傳時間",
        confirmDeleteDevice: "是否確認刪除此設備?",
        deleteConfirmation: "刪除確認",
        confirm: "確定",
        cancel: "取消",
        enterDeviceName: "請輸入設備名稱",
        enterDeviceSerialNumber: "請輸入設備序列號",
        nameLength: "長度在 1 到 40 個字符",
        integratorRequired: "集成商不能為空",
        enterDeviceSerialNumber: "請輸入設備序列號",
        codeLength: "長度在 1 到 10 個字符",
        deviceDetails: "設備詳細信息",
        date: "日期：",
        to: "至",
        startDate: "開始日期",
        endDate: "結束日期",
        search: "查詢",
        recentSevenDaysElectricity: "最近七天用電(kWh)",
        powerConsumption: "用電量",
        todayElectricity: "今日用電 {reading} kWh",
        deviceName: "設備名稱",
        enterDeviceName: "請輸入設備名稱"
      },
      water: {
        title:'水錶詳情',
        waterMeterManagement: "水錶檢測管理",
        addEquipment: "設備添加",
        deviceName: "設備名稱",
        enterDeviceName: "請輸入設備名稱",
        integrator: "集成商",
        selectIntegrator: "請選擇集成商",
        search: "查詢",
        offline: "離線",
        online: "在線",
        noRemark: "無備註",
        noData: "無數據",
        detailData: "詳細數據",
        deviceSerialNumber: "設備序列號",
        onlineStatus: "在線狀態",
        remark: "備註",
        waterMeterReading: "水錶讀數",
        uploadTime: "上傳時間",
        delete: "刪除",
        confirmDeleteDevice: "是否確認刪除此設備?",
        deleteConfirmation: "刪除確認",
        confirm: "確定",
        cancel: "取消",
        deviceDetails: "設備詳細信息",
        date: "日期：",
        to: "至",
        startDate: "開始日期",
        endDate: "結束日期",
        search: "查詢",
        todayWater: "今日用水 {reading} 吨",
        recentSevenDaysWater:'一周用水(吨)',
        powerConsumption:'用水量',
        ton: "吨",
        enterDeviceSerialNumber: "請輸入設備序列號",
        characterLength: "長度在 1 到 10 個字符"
      }
    }
}
  