/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-09 16:18:53
 * @Description: file content
 * @FilePath: \auxface_web\src\views\projectInfo\lang\zh-tw.jsx
 */
export default {
  projectInfo:{
    router: {
      projectInformation: '項目信息',
      permissionManagement: '權限管理',
      participatingUnits: '參建單位',
      constructionPermit: '施工許可證',
      projectSettings: '項目設置',
      integratorList: '集成商列表',
      integratorApproval: '集成商審批',
      governmentIntegration: '政府對接',
      salesSettings: '銷售設置'
    },    
    project: {
      projectCode: "項目編號",
      projectEdit: "項目編輯",
      cancelEdit: "取消編輯",
      projectDelete: "項目刪除",
      projectName: "項目名稱",
      enterProjectName: "請輸入項目名稱",
      contractorCorpName: "總承包單位名稱",
      unifiedSocialCreditCode: "統一社會信用代碼",
      projectCategory: "項目分類",
      selectProjectCategory: "請選擇項目分類",
      projectLocation: "項目所在地",
      projectAddress: "項目地址",
      totalInvestment: "總投資(萬元)",
      unitInTenThousand: "單位：萬元",
      totalArea: "總面積(平方米)",
      unitInSquareMeter: "單位：平方米",
      totalLength: "總長度(米)",
      unitInMeter: "單位：米",
      constructionLandPlanningPermitNumber: "建設用地規劃許可證編號",
      constructionProjectPlanningPermitNumber: "建設工程規劃許可證編號",
      startDate: "開工日期",
      selectDate: "選擇日期",
      completeDate: "竣工日期",
      contactPerson: "聯繫人",
      contactPersonName: "聯繫人姓名",
      contactPersonPhone: "聯繫人電話",
      projectStatus: "項目狀態",
      selectProjectStatus: "請選擇項目狀態",
      projectUsage: "工程用途",
      selectProjectUsage: "請選擇工程用途",
      projectScale: "建設規模",
      selectProjectScale: "請選擇建設規模",
      constructionNature: "建設性質",
      selectConstructionNature: "請選擇建設性質",
      approvalLevel: "立項級別",
      selectApprovalLevel: "請選擇立項級別",
      approvalNumber: "立項文號",
      projectIntroduction: "項目簡介",
      submit: "立即提交",
      projectStatusRequired: "項目狀態不能為空",
      projectNameRequired: "項目名稱不能為空",
      onlyNumbersAllowed: "只能填寫數字",
      confirmDeleteProject: "確認把該項目刪除？刪除後項目無法恢復並且不可逆，請謹慎操作此操作。",
      warning: "提示",
      confirm: "確定",
      cancel: "取消",
      warning: '警告：項目刪除無法撤銷！請慎重操作！',
      warningTips: '該操作將永久刪除項目 {projectName} 的數據，同時取消項目參建單位的關聯。',
      confirmation: '為防止意外，確認繼續操作請輸入該項目的名稱和編號：',
      prjName: '項目名稱：{projectName}',
      prjCode: '項目編號：{projectCode}',
      enterProjectName: '請輸入項目名稱',
      enterProjectCode: '請輸入項目編號',
      projectNameRequired: '項目名稱不能為空',
      projectCodeRequired: '項目編號不能為空',
      deleteProject: '刪除項目',
      cancel: '取 消',
      deleteProject: '刪 除',
      nameRequired: '請輸入項目名稱',
      codeRequired: '請輸入項目編號',
      contractorCorpName: '總承包單位名稱',
      contractorCorpCode: '統一社會信用代碼',
      category: '項目分類',
      area: '項目所在地',
      address: '項目地址',
      invest: '總投資(萬元)',
      buildingArea: '總面積(平方米)',
      buildingLength: '總長度(米)',
      buildPlanNum: '建設用地規劃許可證號',
      prjPlanNum: '建設工程規劃許可證號',
      startDate: '開工日期',
      completeDate: '竣工日期',
      linkMan: '聯繫人',
      linkPhone: '聯繫人電話',
      prjStatus: '項目狀態',
      prjNum: '工程用途',
      prjSize: '建設規模',
      propertyNum: '建設性質',
      approvalLevelNum: '立項級別',
      approvalNum: '立項文號',
      description: '項目簡介',
      preparation: '籌備中',
      notCompleted: '未竣工'
    },
    projectCorp: {
      participatingUnits: '參建單位',
      addParticipatingUnit: '添加參建單位',
      entered: '已進場',
      wait: '待進場',
      exit: '已退場',
      corpType: '參建單位類型',
      select: '請選擇',
      search: '查詢',
      alertDescription: '如需更換總承包單位或更改企業名稱，請聯繫管理員進行更換',
      randomPersonSelect: '隨機人員選擇',
      entry: '進場',
      notEntry: '未進場',
      exit: '退場',
      notExit: '未退場',
      exitConfirmation: '確認將參建單位離場？離場後該項目人員班組均會自動離場並且不可逆，請謹慎操作',
      participatingUnit: '參建單位',
      unifiedSocialCode: '統一社會碼',
      corpType: '參建單位類型',
      randomButton: '隨機按鈕',
      permissionGroup: '權限組',
      entryTime: '進場時間',
      exitTime: '退場時間',
      confirmation: '提示',
      cancel: '取消',
      addParticipatingUnit: '添加參建單位',
      participatingUnitDetails: '參建單位詳細',
      add: '添加',
      participatingUnit: '參建單位',
      enterpriseName: '企業名稱',
      enterEnterpriseName: '請輸入企業名稱',
      unifiedSocialCreditCode: '統一社會信用代碼',
      enterUnifiedSocialCreditCode: '請輸入統一社會信用代碼',
      participatingEnterpriseType: '參建企業類型',
      projectCategory: '項目分類',
      projectLeaderName: '項目負責人名稱',
      projectLeaderIDNumber: '項目負責人證件號碼',
      projectLeaderPhoneNumber: '項目負責人聯繫電話',
      enterpriseNameRequired: '企業名稱不能為空',
      unifiedSocialCreditCodeRequired: '統一社會信用代碼不能為空',
      participatingEnterpriseTypeRequired: '請選擇參建企業類型',
      confirmEntry: '確認進場',
      updatePermissionGroup: '更新權限組',
      permissionGroupTips: '權限組（如未有權限組選擇，請到《權限管理》處添加):',
      selectPermissionGroup: '請選擇權限組',
      entry: '進場',
      update: '更新',
      permissionGroupRequired: '權限組不能為空',
      addRandomPerson: '添加隨機人員',
      faceRandomButton: '人臉隨機按鈕',
      addOption: '添加選項',
      availableCountAndValidity: '可用人數及有效期',
      enterAvailableCount: '輸入可用人數',
      selectDate: '選擇日期',
      delete: '刪除',
      enterEnterpriseName: '請輸入企業名稱',
      enterUnifiedSocialCreditCode: '請輸入統一社會信用代碼',
      search: '查詢',
      loading: '加載中',
      customize: '自定義',
      enterpriseName: '企業名稱',
      unifiedSocialCreditCode: '社會統一碼',
      select: '選擇',
      confirm: '確認',
    },
    builderLicense: {
      title: '施工許可證詳情',
      builderLicense: '施工許可證',
      add: '添加施工許可證',
      certificate: '施工許可證證書',
      number: '施工許可證號碼',
      remark: '備註',
      delete: '刪除',
      confirmDelete: '是否確認刪除此施工許可證？',
      deleteConfirmation: '刪除確認',
      confirm: '確定',
      cancel: '取消',
      number: '施工許可證編號',
      enterNumber: '請輸入施工許可證編號',
      certificate: '施工許可證',
      remark: '備註',
      enterRemark: '請輸入備註',
      numberRequired: '施工許可證編號不能為空',
      certificateRequired: '施工許可證證書不能為空',
    },
    rule: {
      ruleList: '權限組列表',
      addRule: '添加權限組',
      available: '可用',
      disabled: '禁用',
      view: '查看',
      userManagement: '人員管理',
      delete: '刪除',
      corpName: '所屬企業',
      ruleName: '權限組名稱',
      ruleStatus: '權限組狀態',
      viewRule: '查看權限組',
      userManagement: '人員管理',
      confirmDelete: '刪除權限組組內人員將無法享有權限，是否繼續？',
      warning: '提示',
      confirm: '確定',
      cancel: '取消',
      cancelDelete: '已取消刪除',
      editRule: '權限組編輯',
      addRule: '權限組添加',
      corpName: '所屬企業',
      selectCorp: '請選擇所屬單位',
      ruleName: '權限組名稱',
      enterRuleName: '請輸入權限組名稱',
      ruleStatus: '狀態',
      havePermissions: '擁有權限',
      update: '更新',
      create: '創建',
      selectCorp: '所屬單位必須選擇',
      enterRuleName: '請輸入權限組名稱',
      selectPermissions: '請至少選擇一個權限',
      searchToAdd: '請輸入用戶名稱或手機號碼搜索人員進行添加',
      noData: '暫無數據',
      roleMembers: '權限組成員',
    },
    saleSettting: {
      salesDealer: '項目歸屬銷售/經銷商',
      bindSales: '綁定銷售人員',
      select: '請選擇',
      purchaseQuantity: '設備購買數量',
      normalFaceDevice: '普通人臉設備',
      temperatureFaceDevice: '測溫人臉設備',
      normalDustDevice: '普通揚塵設備',
      dust3CDevice: '3C揚塵設備',
      certificationIntegration: '實名制對接數量',
      dustIntegration: '揚塵對接數量',
      videoIntegration: '視頻對接',
      enterQuantity: '請輸入該項目購買了多少台設備',
      availableModules: '可用模塊',
      save: '保存',
    },
    integrator: {
      management: '集成商管理',
      integratorName: '集成商名稱',
      enterIntegratorName: '請輸入集成商名稱',
      includeDevice: '包含設備',
      selectIncludeDevice: '請選擇包含設備',
      search: '查詢',
      corpCode: '企業統一碼',
      corpName: '企業名稱',
      accessDevice: '接入設備',
      managementApply: '集成商審批',
      pass: '通過',
      reject: '拒絕',
      approval: '審批',
      confirmPass: '確認通過該集成商嗎？通過後集成商將可以通過接口獲取人員數據，上報設備數據',
      prompt: '提示',
      confirm: '確定',
      cancel: '取消',
    },
    setting:{
      title: '人員加入規則',
      description: '輸入框內輸入0則視為不使用該規則，關閉實名制後年齡限制只對已實名人員生效',
      requireRealName: '加入班組人員是否需要實名制',
      childAgeRestriction: '童工不允許加入班組，童工指未滿',
      maleRetirementAge: '男性退休不允許加入班組，男性退休年齡',
      femaleRetirementAge: '女性退休不允許加入班組，女性退休年齡',
      yearsOld: '周歲',
      saveButton: '保存',
      requireRealNameRequired: '加入班組不能為空',
      childAgeRequired: '請輸入童工年齡',
      maleRetirementAgeRequired: '請輸入退休年齡',
      femaleRetirementAgeRequired: '請輸入退休年齡'
    },
  }
}