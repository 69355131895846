/*
 * @Author: Terry
 * @Date: 2022-06-02 20:57:17
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-09 18:23:36
 * @Description: file content
 * @FilePath: \auxface_web\src\views\safetytrain\router.js
 */
export default [
    {
      path: '/modules/safetytrain',
      name: 'safetytrainIndex',
      component: ()=>import("@/views/Index.vue"),
      meta: {title: 'safetytrain.router.safetyTraining'},
      redirect: { path: '/modules/safetytrain' },
      children: [
        {name: 'dateOverView', path: 'dateOverView', component: ()=>import("@/views/safetytrain/dateOverview/Index.vue"), meta: {breadcrumb: false}},
        {name: 'userCenter', path: 'userCenter', component: ()=>import("@/views/safetytrain/userCenter/Index.vue"), meta: {title: 'safetytrain.router.userClassroomManagement'}},
        {name: 'exam', path: 'exam', component: ()=>import("@/views/safetytrain/examination/Index.vue"), meta: {title: 'safetytrain.router.examManagement'}},
        {name: 'noexam', path: 'noexam', component: ()=>import("@/views/safetytrain/noExamination/Index.vue"), meta: {title: 'safetytrain.router.examPersonnelManagement'}},
        {name: 'banner', path: 'banner', component: ()=>import("@/views/safetytrain/banner/Index.vue"), meta: {title: 'safetytrain.router.bannerManagement'}},
        {name: 'paper', path: 'paper', component: ()=>import("@/views/corp/paper/Index.vue")},
        {name: 'paperQuestion', path: 'paperQuestion', component: ()=>import("@/views/corp/paper/PaperQuestion.vue")},
        {name: 'questionBank', path: 'questionBank', component: ()=>import("@/views/corp/questionBank/Index.vue")},
        {name: 'classCenter', path: 'classCenter', component: ()=>import("@/views/corp/classCenter/Index.vue")},
        {name: 'courseCenter', path: 'courseCenter', component: ()=>import("@/views/corp/courseCenter/Index.vue")},
        {name: 'courseCenterDetails', path: 'courseCenter/details', component: ()=>import("@/views/corp/courseCenter/Details.vue")},
      ]
    }, 
  ];