/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2024-05-16 13:02:59
 * @Description: file content
 * @FilePath: \auxface_web\src\views\BI\lang\zh-cn.jsx
 */
export default {
    BI:{
    }
}
  