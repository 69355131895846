/*
 * @Author: Healer
 * @Date: 2022-11-22 10:49:31
 * @LastEditTime: 2024-08-15 22:05:43
 * @LastEditors: Terry
 * @Description: 
 */
import { fullScreenContainer, borderBox10, decoration2, decoration8,digitalFlop} from '@jiaminghi/data-view'
const dataVComponents = {
    fullScreenContainer, borderBox10, decoration2, decoration8,digitalFlop,
}
function install(Vue){
    Object.keys(dataVComponents).forEach(key => Vue.use(dataVComponents[key]))
}
export default { install }