/*
 * @Descripttion: 
 * @version: 
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Terry
 * @LastEditTime: 2024-06-13 20:18:55
 */ 
export  default  [
  {
    path: '/modules/confinedSpace/',
    name: 'confinedSpaceIndex',
    component: ()=>import("@/views/Index.vue"),
    redirect: { path: '/modules/confinedSpace/' },
    meta: {title: 'confinedSpace.router.systemName'},
    // 嵌套路由 》》 对应着Home组件中的router-view
    children: [
      //气体检测
      {name: 'gas', path: 'gas', component: ()=>import("@/views/confinedSpace/gas/Index.vue"), meta: {title: 'confinedSpace.router.gas'}},
    ]
    
  }
];




