/*
 * @Author: Terry
 * @Date: 2023-12-03 17:10:00
 * @LastEditors: Terry
 * @LastEditTime: 2024-06-14 18:08:49
 * @Description: file content
 * @FilePath: \auxface_web\src\views\certificate\lang\zh-cn.jsx
 */

export default {
  certificate: {
    router: {
      machineDigitizationType:'设备分类',
      machineDigitizationTemplate:'设备申请模板',
      certificateSystemName: '安全数字化',
      machine: '设备检查表',
      template: '模板管理',
      machinePermission: '权限管理',
      riskTemplate:"高危许可证模板",
      risk: '许可证列表',
      riskAudit: '许可证审批',
      revoke:'注销审批',
      train: '培训签到',
    },
    global: {
      name: '名称',
      selectTips: '请选择',
      addBtn: '添加',
      searchBtn: '查询',
      corpCodeRequired: '请选择所属企业',
      templateCodeRequired: '请选择所属模板',
      typeCodeRequired: '请选择所属分类',
      enterName: '请输入名称',
      corpName: '所属企业',
      remark: '备注',
      noRemark: '无备注',
      remarkPlaceholder: '请输入备注',
      template: '所属模板',
      type: '所属分类',
      status: '状态',
      qrCode: '二维码',
      item: '二级列表',
      exitConfirmation: '退场后将不能重新进场，是否确认？',
      applyDate: '申请时间',
      passBtn: '通过',
      rejectBtn: '拒绝',
      destroyBtn: '移除人员',
      notSelectUser: '未选择用户',
      inDate: '进场时间',
      passed: '使用中',
      wait: '待审核',
      notPass: '已拒绝',
      view: '查看',
      inDate: '创建时间',
      form: '对应表单',
    },
    machineList: {
      expiredTime: '到期时间',
      exit: '退场',
      entry: '已进场',
      exited: '已退场',
      "validityLabel": "检查间隔有效时长(天)",
      "validityPlaceholder": "请输入",
    },
    template: {
      using:'使用中',
      deleted:'已删除',
      type:'有效时长',
      duration:'持续时间',
      expiredTime:'失效时间',
      enterDuration:'请输入持续时间',
      timeSelect:'请选择失效时间',
      exitTime:'延迟注销时间（小时）',
      exitForm:'注销表单',
      enterExitTime:"请输入延迟注销时间"
    },
    machinePermission: {
      confirmPass: '是否通过人员审核，通过后人员将可以进行设备工单申请',
      confirmDestroy: '是否移除人员，移除后人员将无权限进行设备工单处理',
      confirmReject: '是否拒绝人员审核，拒绝后人员需重新申请',
      alertTitle: '请勾选单个或多个人员进行审核，通过后人员将可以进行设备工单申请',
      machineName: '所属设备证书',
    },
    risk: {
      worker:"相关人员",
      inName:"申请人名称",
      expired: "已注销",
      confirmDestroy: '是否移除人员，移除后人员将取消该许可证资格',
      name: '高危工作许可证',
      exitData: '注销申请内容',
      info: '证书信息',
      content: '申请内容',
      expiredTime:'到期时间',
      expiredNotExit:'过期未注销',
      exitTime:"注销时间",
    },
    riskAudit: {
      confirmPass: '是否通过人员审核，通过后将下发许可证书给人员',
      alertTitle:'请勾选单个或多个人员进行审核，通过后人员获取该许可证书 ',
      confirmReject: '请在下方输入拒绝原因，可按回车换行',
      name: '高危工作许可证',
      info: '证书信息',
      content: '申请内容',
      expiredTime: '到期时间',
      rejectReason: '拒绝原因',
    },
    revoke:{
      confirmPass: '通过后该证书将注销，无法复原！',
    },
    train: {
      lecturer:'讲师',
      avatar: '人员头像',
      name: '姓名',
      attendanceTime: '考勤时间',
      entry: '使用中',
      expired: '已过期',
      checkInCount: '考勤人数',
      timeSelect: '选择日期时间',
    },
  },
};