/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-09 23:40:27
 * @Description: file content
 * @FilePath: \auxface_web\src\views\projectInfo\lang\zh-cn.jsx
 */
export default {
  projectInfo:{
    router:{
      projectInformation: '项目信息',
      permissionManagement: '权限管理',
      participatingUnits: '参建单位',
      constructionPermit: '施工许可证',
      projectSettings: '项目设置',
      integratorList: '集成商列表',
      integratorApproval: '集成商审批',
      governmentIntegration: '政府对接',
      salesSettings: '销售设置'
    },
    project: {
      projectCode: "项目编号",
      projectEdit: "项目编辑",
      cancelEdit: "取消编辑",
      projectDelete: "项目删除",
      projectName: "项目名称",
      enterProjectName: "请输入项目名称",
      contractorCorpName: "总承包单位名称",
      unifiedSocialCreditCode: "统一社会信用代码",
      projectCategory: "项目分类",
      selectProjectCategory: "请选择项目分类",
      projectLocation: "项目所在地",
      projectAddress: "项目地址",
      totalInvestment: "总投资(万元)",
      unitInTenThousand: "单位：万元",
      totalArea: "总面积(平方米)",
      unitInSquareMeter: "单位：平方米",
      totalLength: "总长度(米)",
      unitInMeter: "单位：米",
      constructionLandPlanningPermitNumber: "建设用地规划许可证编号",
      constructionProjectPlanningPermitNumber: "建设工程规划许可证编号",
      startDate: "开工日期",
      selectDate: "选择日期",
      completeDate: "竣工日期",
      contactPerson: "联系人",
      contactPersonName: "联系人姓名",
      contactPersonPhone: "联系人电话",
      projectStatus: "项目状态",
      selectProjectStatus: "请选择项目状态",
      projectUsage: "工程用途",
      selectProjectUsage: "请选择工程用途",
      projectScale: "建设规模",
      selectProjectScale: "请选择建设规模",
      constructionNature: "建设性质",
      selectConstructionNature: "请选择建设性质",
      approvalLevel: "立项级别",
      selectApprovalLevel: "请选择立项级别",
      approvalNumber: "立项文号",
      projectIntroduction: "项目简介",
      submit: "立即提交",
      projectStatusRequired: "项目状态不能为空",
      projectNameRequired: "项目名称不能为空",
      onlyNumbersAllowed: "只能填写数字",
      confirmDeleteProject: "确认把该项目删除？删除后项目无法恢复并且不可逆，请谨慎操作此操作。",
      warning: "提示",
      confirm: "确定",
      cancel: "取消",
      warning: '警告：项目删除无法撤销！请慎重操作！',
      warningTips: '该操作将永久删除项目 {projectName} 的数据，同时取消项目参建单位的关联。',
      confirmation: '为防止意外，确认继续操作请输入该项目的名称和编号：',
      prjName: '项目名称：{projectName}',
      prjCode: '项目编号：{projectCode}',
      enterProjectName: '请输入项目名称',
      enterProjectCode: '请输入项目编号',
      projectNameRequired: '项目名称不能为空',
      projectCodeRequired: '项目编号不能为空',
      deleteProject: '删除项目',
      cancel: '取 消',
      deleteProject: '删 除',
      nameRequired: '请输入项目名称',
      codeRequired: '请输入项目编号',
      contractorCorpName: '总承包单位名称',
      contractorCorpCode: '统一社会信用代码',
      category: '项目分类',
      area: '项目所在地',
      address: '项目地址',
      invest: '总投资(万元)',
      buildingArea: '总面积(平方米)',
      buildingLength: '总长度(米)',
      buildPlanNum: '建设用地规划许可证号',
      prjPlanNum: '建设工程规划许可证号',
      startDate: '开工日期',
      completeDate: '竣工日期',
      linkMan: '联系人',
      linkPhone: '联系人电话',
      prjStatus: '项目状态',
      prjNum: '工程用途',
      prjSize: '建设规模',
      propertyNum: '建设性质',
      approvalLevelNum: '立项级别',
      approvalNum: '立项文号',
      description: '项目简介',
      preparation: '筹备中',
      notCompleted: '未竣工',
    },
    projectCorp: {
      participatingUnits: '参建单位',
      addParticipatingUnit: '添加参建单位',
      entered: '已进场',
      wait: '待进场',
      exit: '已退场',
      corpType: '参建单位类型',
      select: '请选择',
      search: '查询',
      alertDescription: '如需更换总承包单位或更改企业名称，请联系管理员进行更换',
      randomPersonSelect: '随机人员选择',
      entry: '进场',
      notEntry: '未进场',
      exit: '退场',
      notExit: '未退场',
      exitConfirmation: '确认把参建单位离场？离场后该项目人员班组均会自动离场并且不可逆，请谨慎操作',
      participatingUnit: '参建单位',
      unifiedSocialCode: '统一社会码',
      corpType: '参建单位类型',
      randomButton: '随机按钮',
      permissionGroup: '权限组',
      entryTime: '进场时间',
      exitTime: '退场时间',
      confirmation: '提示',
      cancel: '取消',
      addParticipatingUnit: '添加参建单位',
      participatingUnitDetails: '参建单位详细',
      add: '添加',
      participatingUnit: '参建单位',
      enterpriseName: '企业名称',
      enterEnterpriseName: '请输入企业名称',
      unifiedSocialCreditCode: '统一社会信用代码',
      enterUnifiedSocialCreditCode: '请输入统一社会信用代码',
      participatingEnterpriseType: '参建企业类型',
      projectCategory: '项目分类',
      projectLeaderName: '项目负责人名称',
      projectLeaderIDNumber: '项目负责人证件号码',
      projectLeaderPhoneNumber: '项目负责人联系电话',
      enterpriseNameRequired: '企业名称不能为空',
      unifiedSocialCreditCodeRequired: '统一社会信用代码不能为空',
      participatingEnterpriseTypeRequired: '请选择参建企业类型',
      confirmEntry: '确认进场',
      updatePermissionGroup: '更新权限组',
      permissionGroupTips: '权限组（如未有权限组选择，请到《权限管理》处添加 ):',
      selectPermissionGroup: '请选择权限组',
      entry: '进场',
      update: '更新',
      permissionGroupRequired: '权限组不能为空',
      addRandomPerson: '添加随机人员',
      faceRandomButton: '人脸随机按钮',
      addOption: '添加选项',
      availableCountAndValidity: '可用人数及有效期',
      enterAvailableCount: '输入可用人数',
      selectDate: '选择日期',
      delete: '删除',
      enterEnterpriseName: '请输入企业名称',
      enterUnifiedSocialCreditCode: '请输入统一社会信用代码',
      search: '查询',
      loading: '拼命加载中',
      customize: '自定义',
      enterpriseName: '企业名称',
      unifiedSocialCreditCode: '社会统一码',
      select: '选择',
      confirm: '确认',
    },
    builderLicense: {
      title:'施工许可证详情',
      builderLicense: '施工许可证',
      add: '添加施工许可证',
      certificate: '施工许可证证书',
      number: '施工许可证号码',
      remark: '备注',
      delete: '删除',
      confirmDelete: '是否确认删除此施工许可证?',
      deleteConfirmation: '删除确认',
      confirm: '确定',
      cancel: '取消',
      number: '施工许可证编号',
      enterNumber: '请输入施工许可证编号',
      certificate: '施工许可证',
      remark: '备注',
      enterRemark: '请输入备注',
      numberRequired: '施工许可证编号不能为空',
      certificateRequired: '施工许可证证书不能为空',
    },
    rule: {
      ruleList: '权限组列表',
      addRule: '添加权限组',
      available: '可用',
      disabled: '禁用',
      view: '查看',
      userManagement: '人员管理',
      delete: '删除',
      corpName: '所属企业',
      ruleName: '权限组名字',
      ruleStatus: '权限组状态',
      viewRule: '查看权限组',
      userManagement: '人员管理',
      confirmDelete: '删除权限组组内人员将无法享有权限，是否继续？',
      warning: '提示',
      confirm: '确定',
      cancel: '取消',
      cancelDelete: '已取消删除',
      editRule: '权限组编辑',
      addRule: '权限组添加',
      corpName: '所属企业',
      selectCorp: '请选择所属单位',
      ruleName: '权限组名称',
      enterRuleName: '请输入权限组名称',
      ruleStatus: '状态',
      havePermissions: '拥有权限',
      update: '更新',
      create: '创建',
      selectCorp: '所属单位必须选择',
      enterRuleName: '请输入权限组名称',
      selectPermissions: '请至少选择一个权限',
      searchToAdd: '请输入用户名称或手机号码搜索人员进行添加',
      noData: '暂无数据',
      roleMembers: '权限组成员',
    },
    saleSettting:{
      salesDealer: '项目归属销售/经销商',
      bindSales: '绑定销售人员',
      select: '请选择',
      purchaseQuantity: '设备购买数量',
      normalFaceDevice: '普通人脸设备',
      temperatureFaceDevice: '测温人脸设备',
      normalDustDevice: '普通扬尘设备',
      dust3CDevice: '3c扬尘设备',
      certificationIntegration: '实名制对接数量',
      dustIntegration: '扬尘对接数量',
      videoIntegration: '视频对接',
      enterQuantity: '请输入该项目购买了多少台设备',
      availableModules: '可用模块',
      save: '保存',
    },
    integrator: {
      management: '集成商管理',
      integratorName: '集成商名称',
      enterIntegratorName: '请输入集成商名称',
      includeDevice: '包含设备',
      selectIncludeDevice: '请选择包含设备',
      search: '查询',
      corpCode: '企业统一码',
      corpName: '企业名称',
      accessDevice: '接入设备',
      managementApply: '集成商审批',
      pass: '通过',
      reject: '拒绝',
      approval: '审批',
      confirmPass: '确认通过该集成商吗？通过后集成商将可以通过接口获取人员数据，上报设备数据',
      prompt: '提示',
      confirm: '确定',
      cancel: '取消',
    },
    setting:{
      title: '人员加入规则',
      description: '输入框内输入0则视为不使用该规则，关闭实名制后年龄限制只对已实名人员生效',
      requireRealName: '加入班组人员是否需要实名制',
      childAgeRestriction: '童工不允许加入班组，童工指未满',
      maleRetirementAge: '男性退休不允许加入班组，男性退休年龄',
      femaleRetirementAge: '女性退休不允许加入班组，女性退休年龄',
      yearsOld: '周岁',
      saveButton: '保存',
      requireRealNameRequired: '加入班组不能为空',
      childAgeRequired: '请输入童工年龄',
      maleRetirementAgeRequired: '请输入退休年龄',
      femaleRetirementAgeRequired: '请输入退休年龄'
    },
  }
}
  