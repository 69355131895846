/*
 * @Descripttion: aes数据传输加密
 * @version: v1
 * @Author: Terry
 * @Date: 2020-06-15 11:57:09
 * @LastEditors: Terry
 * @LastEditTime: 2024-04-18 18:29:44
 */
 import CryptoJS from 'crypto-js';  //引用AES源码js
   function getKey(){
      var uuid = sessionStorage.getItem('uuid') ? sessionStorage.getItem('uuid') :   '',
      puuid = sessionStorage.getItem('puuid') ? sessionStorage.getItem('puuid') : '',
      keyword = puuid+uuid; 
      var keyword = CryptoJS.MD5(keyword).toString();
      const key = CryptoJS.enc.Utf8.parse(keyword);  
      const iv = CryptoJS.enc.Utf8.parse(keyword.slice(0,16));

      console.table([uuid,puuid,keyword,key,iv]);  
      return {'key':key,'iv':iv}
   }
         //解密方法
   export function  decrypt(word) {
         if(process.env.VUE_APP_BASE_AES=='false')   return word;
         if(word==''||word=='{}') return word;
         var {key,iv} = getKey();
         var parsedWordArray = CryptoJS.enc.Base64.parse(word);
         var parsedStr = parsedWordArray.toString(CryptoJS.enc.Utf8);
         let decrypt = CryptoJS.AES.decrypt(parsedStr, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
         let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
         let data = decryptedStr.toString();
         return JSON.parse(data);
      }
      //加密方法
    export function encrypt(word) {
         if(word==''||word=='{}'||word==undefined) return word;
         var {key,iv} = getKey();
         let srcs = CryptoJS.enc.Utf8.parse(word);
         let encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
         var hexStr = encrypted.ciphertext.toString().toUpperCase();
         var oldHexStr = CryptoJS.enc.Hex.parse(hexStr);
         // 将密文转为Base64的字符串
         var base64Str = CryptoJS.enc.Base64.stringify(oldHexStr);

         var wordArray = CryptoJS.enc.Utf8.parse(base64Str);
         var base64 = CryptoJS.enc.Base64.stringify(wordArray);
         
         return base64;
    }