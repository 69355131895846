/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2024-08-20 16:12:34
 * @Description: file content
 * @FilePath: \auxface_web\src\lang\zh-cn.js
 */

var global = {
    global:{
      title:'智慧建筑一体化管理平台',
      logout:'退出',
      loading:'拼命加载中',
      prjName: '智慧建筑一体化管理平台',
      exit: '退出登录',
      back: '返回项目',
      btnName: '打开新页面',
      prompt: '提示',
      confirm: '确定',
      cancel: '取消',
      logoutConfirm: '请问您是否需要退出登录',
      logoutSuccess: '退出成功',
      aside: '导航栏',
      userInactive: '由于用户长时间没有操作,请重新登陆',
      deleteConfirmation: "是否确认删除此数据，删除后无法复原请慎重操作！",
      delete: "删除确认",
      destroy: "删除",
      confirmClose:'确认关闭？',
      searchBtn:'查询',
      view:'查看',
      selectPlaceholder:'请选择',
      inputPlaceholder:'请输入',
    }  
  }
    
  var langList = require.context('@/views/', true, /\zh-cn.jsx$/),lang = {};
  
  langList.keys().forEach(element => {
    var value = langList(element)
    if(value.default){
      lang = {...lang , ...value.default,...global};
    }
  })
  
  export default lang;
  