/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-12 22:22:46
 * @Description: file content
 * @FilePath: \auxface_web\src\views\equipment\lang\zh-cn.jsx
 */
export default {
  equipment:{
    router:{
      equipmentSafety: '设备安全',
      elevatorManagement: '升降机管理',
      towerCraneManagement: '塔吊管理',
      scaffoldManagement: '脚手架管理',
    },
    towerCrane: {
      title:'塔吊管理详情',
      towerCraneManagement: "塔吊管理",
      confirmDeleteDevice: "是否确认删除此设备?",
      deleteConfirmation: "删除确认",
      confirm: "确定",
      cancel: "取消",
      integrator: "集成商",
      deviceName: "设备名称",
      onlineStatus: "在线状态",
      totalHeight: "总高度(米)",
      hoistingWeight: "吊重重量(吨)",
      span: "幅度(米)",
      height: "高度(米)",
      rotationAngle: "回转角度(度)",
      ratedHoistingWeight: "额定吊重(t)",
      momentPercentage: "力矩百分比(%)",
      windSpeed: "风速(m/s)",
      forwardTiltAngle: "前倾角(度)",
      deviceCode: "设备编码",
      deviceLocation: "设备位置",
      remark: "备注",
      uploadTime: "上传时间",
      detailData: "详细数据",
      delete: "删除",
      addDevice: "添加设备",
      enterDeviceName: "请输入设备名称",
      integrator: "集成商",
      selectIntegrator: "请选择集成商",
      search: "查询",
      offline: "离线",
      online: "在线",
      noData: "无数据",
      longitude: "经度",
      latitude: "纬度",
      detailData: "详细数据",
      delete: "删除",
      deviceName: "设备名称",
      enterDeviceName: "请输入设备名称",
      deviceCode: "设备编码",
      enterDeviceCode: "请输入设备编码",
      integrator: "集成商",
      selectIntegrator: "请选择集成商",
      cameraBinding: "摄像头绑定",
      bindCamera: "请绑定摄像头",
      deviceLongitude: "设备经度",
      enterDeviceLongitude: "请输入设备经度",
      deviceLatitude: "设备纬度",
      enterDeviceLatitude: "请输入设备纬度",
      deviceArmLength: "设备臂长",
      enterDeviceArmLength: "请输入设备前臂臂长",
      remarks: "备注",
      enterRemarks: "请输入备注",
      nameLength: '长度在 1 到 50 个字符',
      integratorRequired: '集成商不能为空',
      enterDeviceCode: '请输入设备编码',
      codeLength: '长度在 1 到 10 个字符',
      enterLongitude: '请输入经度',
      selectLatitude: '请选择纬度',
      enterArmLength: '请选择请输入臂长',
      deviceDetails: "设备详细信息",
      date: "日期",
      to: "至",
      startDate: "开始日期",
      endDate: "结束日期",
      search: "查询"
    },
    elevator: {
      title:'升降机管理详情',
      management: "升降机管理",
      addDevice:'添加设备',
      enterDeviceName: "请输入设备名称",
      integrator: "集成商",
      selectIntegrator: "请选择集成商",
      search: "查询",
      offline: "离线",
      online: "在线",
      noData: "无数据",
      closed: "关闭",
      opened: "开启",
      notInstalled: "未安装",
      installed: "已安装",
      longitude: "经度",
      latitude: "纬度",
      detailData: "详细数据",
      delete: "删除",
      confirmDeleteDevice: "是否确认删除此设备?",
      deleteConfirmation: "删除确认",
      confirm: "确定",
      cancel: "取消",
      integrator: "集成商",
      deviceName: "设备名称",
      onlineStatus: "在线状态",
      totalHeight: "总高度(米)",
      loadWeight: "载重量(吨)",
      height: "高度(米)",
      runningSpeed: "运行速度(m/s)",
      windSpeed: "风速(m/s)",
      frontDoorStatus: "前门状态",
      backDoorStatus: "后门状态",
      faceRecognition: "人脸识别",
      deviceCode: "设备编码",
      remarks: "备注",
      deviceLocation: "设备位置",
      uploadTime: "上传时间",
      details: "详细数据",
      delete: "删除", 
      date: "日期",
      to: "至",
      startDate: "开始日期",
      endDate: "结束日期",
      search: "查询",
      deviceDetails: "设备详细信息",
      deviceName: "设备名称",
      enterDeviceName: "请输入设备名称",
      deviceCode: "设备编码",
      enterDeviceCode: "请输入设备编码",
      integrator: "集成商",
      selectIntegrator: "请选择集成商",
      cameraBinding: "摄像头绑定",
      bindCamera: "请绑定摄像头",
      deviceLongitude: "设备经度",
      enterDeviceLongitude: "请输入设备经度",
      deviceLatitude: "设备纬度",
      enterDeviceLatitude: "请输入设备纬度",
      deviceHeight: "设备高度",
      enterDeviceHeight: "请输入设备高度",
      enterDeviceName: '请输入设备名称',
      nameLength: '长度在 1 到 50 个字符',
      integratorRequired: '集成商不能为空',
      enterDeviceCode: '请输入设备编号',
      enterDeviceHeight: '请输入设备高度',
      enterDeviceLatitude: '请输入设备维度',
      enterDeviceLongitude: '请输入设备经度'
    },
    scaffold:{
      search:'查询',
      management:'脚手架管理',
      addDevice:'添加设备',
      enterDeviceName:"请输入设备名称",
      onlineStatus:'联网状态',
      deviceId:'设备序列号',
      deviceName:'设备名称',
      weight:'轴压力(kn)',
      spanX:'水平x位移(mm)',
      spanY:'水平y位移(mm)',
      dipX:'水平x倾角(°)',
      dipY:'水平y倾角(°)',
      height:'高度',
      down:'沉降(mm)',
      electric:'电池电压',
      windSpeed:'风速(m/s)',
      alarmStatus:'报警状态',
      inDate:'上传时间',
      details:'信息详情',
      delete:'删除',
      title:'脚手架详情',
      detailData: "详细数据",
      offline: "离线",
      online: "在线",
      confirmDeleteDevice: "是否确认删除此设备?",
      deleteConfirmation: "删除确认",
      confirm: "确定",
      cancel: "取消",
      date: "日期",
      to: "至",
      startDate: "开始日期",
      endDate: "结束日期",
      search: "查询",
      deviceDetails:'脚手架详情',
      enterDeviceName: "请输入设备名称",
      deviceCode: "设备编码",
      enterDeviceCode: "请输入设备编码",
      integrator: "集成商",
      selectIntegrator: "请选择集成商",
      deviceLongitude: "设备经度",
      enterDeviceLongitude: "请输入设备经度",
      deviceLatitude: "设备纬度",
      enterDeviceLatitude: "请输入设备纬度",
      description:'备注',
      enterDescription:'请输入备注',
      normal:'正常',
      horizontalDisplacementXAlarm:'水平位移X轴报警',
      verticalDisplacementAlarm:'垂直位移报警',
      angleAlarm:'角度报警',
      loadAlarm:'承重报警',
      horizontalDisplacementYAlarm:'水平Y轴位移报警',
    },
  }
}
  