/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2024-08-14 23:23:46
 * @Description: file content
 * @FilePath: \auxface_web\src\views\video\lang\zh-tw.jsx
 */
export default {
  video:{
    router: {
      playPage: '播放頁面',
      monitorPlayback: '監控回放',
      videoMonitoring: '視頻監控',
      accountSettings: '帳號設置',
      camera: '攝像頭管理',
      channel:'錄像機子通道',
      cloudBack:'云回放',
      home:'首頁',
    },    
    account: {
      accountSettings: '賬號設置',
      addCameraAccount: '添加攝像頭賬號',
      accountName: '賬號名稱',
      enterAccountName: '請輸入賬號名稱',
      search: '查詢',
      noRemark: '無備註',
      delete: '刪除',
      accountName: '賬號名稱',
      remark: '備註',
      confirmDelete: '是否確認刪除此賬號?',
      deleteConfirmation: '刪除確認',
      confirm: '確定',
      cancel: '取消',
      accountName: '賬號名稱',
      enterAccountName: '請輸入賬號名稱',
      enterAppKey: '請輸入賬號appkey',
      enterSecret: '請輸入賬號Secret',
      accountNameRequired: '攝像頭賬號不能為空',
      appKeyRequired: 'AppKey不能為空',
      secretRequired: 'Secret不能為空'
    },
    camera: {
      addDevice: '設備添加',
      deviceName: '設備名稱',
      enterDeviceName: '請輸入設備名稱',
      accountConfig: '賬號配置',
      selectAccountConfig: '請選擇賬號配置',
      selectModel: '請選擇機型',
      search: '查詢',
      deviceName: '設備名稱',
      deviceSerialNumber: '設備序列號',
      channelNumber: '通道號',
      model: '機型',
      onlineStatus: '在線狀態',
      online: '在線',
      offline: '離線',
      retrieve: '重新獲取',
      nonNvrDevice: '非NVR設備',
      playback: '回放',
      section: '所屬板塊',
      accountConfig: '賬號配置',
      display: '是否顯示',
      remark: '備註',
      delete: '刪除',
      nvr: 'NVR',
      bulletCamera: '槍機',
      domeCamera: '球機',
      normalCamera: '普通攝像頭',
      personnelManagement: '人員管理',
      dustDevice: '揚塵設備',
      towerCraneDevice: '塔吊設備',
      elevatorDevice: '升降機設備',
      confirmDeleteDevice: '是否確認刪除此設備?',
      confirmation: '提示',
      confirm: '確定',
      cancel: '取消',
      serialNumber: '設備序列碼',
      serialNumberPlaceholder: '請輸入9位字母或數字的序列號',
      name: '設備名稱',
      namePlaceholder: '設備名稱',
      verificationCode: '設備驗證碼',
      verificationCodePlaceholder: '請輸入設備驗證碼',
      accountConfiguration: '賬號配置',
      selectAccountConfiguration: '請選擇賬號配置',
      belongingSection: '所屬板塊',
      selectBelongingSection: '請選擇所屬板塊',
      machineType: '機器類型',
      selectMachineType: '請選擇機器類型',
      remark: '備註',
      remarkPlaceholder: '請輸入備註'
    },
    player: {
      goBack: '返回',
      screen: '屏幕',
      clearAllCameras: '一鍵清除所有攝像頭',
      confirmDeleteAllCameras: '是否確認刪除全部攝像頭?',
      deleteConfirmation: '刪除確認',
      confirm: '確定',
      cancel: '取消',
      dragCameras: '請拖放左側列表攝像頭',
      dragCamerasHere: '請把攝像頭拖到此處播放',
      videoAlreadyInPlaylist: '該視頻已經在播放列表了，請勿重複放置'
    },
  }
}
  