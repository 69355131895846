/*
 * @Author: Terry
 * @Date: 2022-06-02 20:57:17
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-19 18:14:25
 * @Description: file content
 * @FilePath: \auxface_web\src\views\BI\router.js
 */
export default [
  {name: 'BIEdit',path: '/modules/BI/BIEdit',component: ()=>import("@/views/BI/BIEdit/Index.vue"),meta: {title:'BI面板可视化'},},
  {
    path: '/modules/BI',
    name: 'BIIndex',
    component: ()=>import("@/views/BI/Index.vue"),
    meta: {title:'BI面板'},
    redirect: { path: '/modules/BI' },
    children: [
      //編輯器
      // 绿色工地
      {name: 'biGreenSites',path: 'greenSites',component: ()=>import("@/views/BI/greenSites/Index.vue")},
      // 人员管理
      {name: 'biPeopleManagement',path: 'peopleManagement',component: ()=>import("@/views/BI/peopleManagement/Index.vue")},
      // 升降机管理
      {name: 'biElevator',path: 'elevator',component: ()=>import("@/views/BI/elevator/Index.vue")},
      {name: 'biElevatorDetail',path: 'elevator/detail',component: ()=>import("@/views/BI/elevator/Details.vue")},
      // 塔吊管理
      {name: 'biTowerCrane',path: 'towerCrane',component: ()=>import("@/views/BI/towerCrane/Index.vue")},
      {name: 'biTowerCraneDetail',path: 'towerCrane/detail',component: ()=>import("@/views/BI/towerCrane/Details.vue")},
    ]
  }, 
];
