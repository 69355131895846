/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-09 16:00:37
 * @Description: file content
 * @FilePath: \auxface_web\src\views\safetytrain\lang\zh-cn.jsx
 */
export default {
  safetytrain:{
    router: {
      safetyTraining: '安全培訓',
      userClassroomManagement: '用戶課堂管理',
      examManagement: '考試管理',
      examPersonnelManagement: '考試人員管理',
      bannerManagement: '輪播圖管理'
    },    
    dateOverView: {
      projectCode: '項目編號：',
      totalWorkers: '總人數',
      totalTeams: '班組數',
      examWorkers: '考試人數',
      totalExamsToday: '今日總考試次數',
      totalExams: '總考試數',
      passRate: '通過率',
      examTrend: '考試次數趨勢圖',
    },
    userCenter: {
      title:'用戶課堂管理詳情',
      classroomManagement: '用戶課堂管理',
      courseName: '課程名稱',
      enterCourseName: '請輸入課程名稱',
      category: '分類',
      selectCategory: '請選擇分類',
      applicableUsers: '適用人員',
      selectApplicableUsers: '請選擇適用人員',
      search: '查詢',
      noRemark: '無備註',
      addedCourses: '已添加課程',
      addCoursesForUnselectedUsers: '為未選擇課程的用戶進行課程添加',
      unselectedUsers: '未選課程人員',
      filterKeyword: '輸入關鍵字進行過濾',
      selectedUsers: '已選課程人員',
      noData: '暫無數據',
      courseName: '課程名稱',
      category: '分類',
      applicableUsers: '適用人員',
      selectionCount: '選擇人數',
      remark: '備註',
    },
    exam:{
      examManagement: '考試管理',
      passed: '合格',
      failed: '未合格',
      category: '分類：',
      selectCategory: '請選擇分類',
      team: '班組：',
      selectTeam: '請選擇所在班組',
      noScore: '暫無成績',
      name: '姓名',
      examName: '考試名稱',
      team: '班組',
      examCategory: '考試分類',
      score: '成績',
      search: '查詢',
    },
    noExam:{
      userManagement: '未考試人員管理',
      name: '姓名:',
      enterName: '請輸入姓名',
      search: '查詢',
      name: '姓名',
      totalExams: '總考試',
      completedExams: '已考試',
      pendingExams: '未考試',
      enterCourseName: '請輸入課程名稱',
      search: '查詢',
      courseName: '課程名稱',
    },
    banner:{
      title:'輪播圖管理詳情',
      carouselManagement: '輪播圖管理',
      addImage: '添加圖片',
      detailInfo: '詳細信息',
      delete: '刪除',
      sort: '排序',
      image: '圖片',
      remark: '備註',
      detailInfo: '詳細信息',
      confirmDelete: '是否確認刪除此輪播圖?',
      deleteConfirmation: '刪除確認',
      confirm: '確定',
      cancel: '取消',
      selectSortPosition: '請選擇排序位置',
      addImage: '請添加圖片',
      sort: '排序：',
      courseImage: '課程圖片：',
      remark: '備註：',
      remarkPlaceholder: '備註',
    },
      classCenter: {
      trainings: '安全培訓',
      categoryManagement: '分類管理',
      addCategory: '添加分類',
      applicablePersonnel: '適用人員',
      selectApplicablePersonnel: '請選擇適用人員',
      search: '查詢'
    }
  }
}
  