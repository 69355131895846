<!--
 * @Author: Terry
 * @Date: 2022-11-01 18:06:44
 * @LastEditors: Terry
 * @LastEditTime: 2024-09-09 23:12:55
 * @Description: file content
 * @FilePath: \auxface_web\src\components\dialogPopUpV2.vue
-->
<template>
  <transition  name="el-zoom-in-center" @leave="handleLeave" @enter="handleEnter">
    <div class="AUX-preview-main" v-show="dialogShow"  :style="popupStyle"  ref="popupEl">
      <div class="AUX-common-page-header">
        <el-page-header @back="goBack" :content="title" />
        <div class="options">
          <el-button v-if="!disabled && ruleForm.uuid != undefined" type="primary" @click="handleConfirm('update','put')">
            修改</el-button>
          <el-button v-else-if="!disabled" type="primary" @click="handleConfirm('create','post')">
            添加</el-button>
          <el-button  v-else  type="primary" @click="goBack">確認</el-button>
          <el-button @click="goBack">取消</el-button>
        </div>
      </div>
      <div class="preview-main" v-loading="formLoading" element-loading-text="加载中">  
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="m-t-15"
          label-position="right"
        >
          <slot></slot>
        </el-form>
      </div>
    </div>
  </transition>
</template>
<style lang="scss">

</style>
<script>
import axios from "@/utils/axios.js";
export default {
  props: {
    ruleForm: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Object,
      default: () => ({})
    },
  },
  name: "dialogPopUp",
  data() {
    return {
      formLoading:false,
      controller:'',
      func:'',
      title:'',
      dialogShow:false,
      popupStyle:{},
    }
  },
  methods: {
    adjustPopupPosition(e) {
      let myScroll = document.querySelector("#listen")?.getBoundingClientRect()?.top();
      console.log(myScroll);
      return;
      const mainEl = this.$el.closest('.el-main'); // 找到 el-main

      const popupEl = this.$refs.popupEl;

      if (mainEl && popupEl) {
        const mainRect = mainEl.getBoundingClientRect();
        const popupRect = popupEl.getBoundingClientRect();
        console.log('scrollY',window.scrollY);
        console.log(document.documentElement.scrollHeight, window.innerHeight);
        getBoundingClientRect
        const mainTop = mainRect.top + window.scrollY; // 相对于视口的 top + 当前滚动距离
        console.log(mainTop );
        // const top = (mainRect.top + (mainRect.height - popupRect.height) / 2) + window.scrollY;
        const left = window.scrollX;
        this.popupStyle = {
          position: 'absolute',
          top: `${top}px`,
          left: `${left}px`,
          transform: 'none' // 移除自动居中的样式
        };
      }
    },
    changeController(controller){
      this.controller = controller;
    },
    changeTitle(title){
      this.title = title;
    },
    changeFunc(func){
      this.func = func
    },
    show(){
      const container = document.querySelector('.el-main');
      container.addEventListener("scroll", function() {
          console.log("Scroll position:", container.scrollTop);
      });

   
      this.dialogShow = true;
      this.$nextTick(() => {
        this.adjustPopupPosition();
        document.addEventListener('resize', this.adjustPopupPosition);
        document.addEventListener("scroll", this.listenScroll);
      });
    },
    // 返回取消
    goBack() {
      this.dialogShow = false;
    },
    // 添加修改
    handleConfirm(type,method) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          var func  =  this.func || type,
          api = this.module + "/admin/v1/" + this.controller + '/' + func,
          data = {...this.ruleForm};
          this.load = this.loading()
          axios[method](api, data )
            .then((res) => {
              data = {...data,...res.data};
              this.$emit('submit', data );
            })
            .catch(res=>{
              res.message && this.$message.error(res.message);
            })
            .finally(() => {
              this.load.close()
            });
       
        }
      })
    },
    handleLeave(){
      this.$store.commit('changePopUp',true);
      this.$refs.ruleForm?.resetFields()
    },
    handleEnter(){
      this.$store.commit('changePopUp', false);
    }
  },
  mounted(){
    var listUrl = this.$route.path.split("/").filter((item) => {
        return item != "modules" && item != "";
      });
      this.module = listUrl[0];
      this.controller = this.controller || listUrl[1];
      this.title = this.$t(`${this.module}.router.${this.controller}`);
  }
}
</script>
  