/*
 * @Descripttion: 预警中心路由列表
 * @version: v1
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-14 23:35:12
 */ 
export  default  [
{
    path: '/modules/warning/',
    name: 'warningIndex',
    component: () => import("@/views/Index.vue"),
    meta: { title: 'warning.router.warningCenter' },
    redirect: { path: '/modules/warning/' },
    // 嵌套路由 》》 对应着Home组件中的router-view
    children: [
      { name: 'warningOverView', path: 'home', component: () => import("@/views/warning/worker/Index.vue") },
      { name: 'workerWarnListst', path: 'worker/list', component: () => import("@/views/warning/worker/Index.vue"), meta: { title: 'warning.router.personnelWarning' } },
      { name: 'dustWarnList', path: 'dust/list', component: () => import("@/views/warning/dust/Index.vue"), meta: { title: 'warning.router.dustWarning' } },
      { name: 'electricWarnListst', path: 'electric/list', component: () => import("@/views/warning/electric/Index.vue"), meta: { title: 'warning.router.electricWarning' } },
      { name: 'waterWarnListst', path: 'water/list', component: () => import("@/views/warning/water/Index.vue"), meta: { title: 'warning.router.waterWarning' } },
      { name: 'craneWarnListst', path: 'crane/list', component: () => import("@/views/warning/dust/Index.vue") },
    ]
  },
];