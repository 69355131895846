
/*
 * @Author: Terry
 * @Date: 2023-12-03 17:10:00
 * @LastEditors: Terry
 * @LastEditTime: 2024-03-29 21:56:33
 * @Description: file content
 * @FilePath: \auxface_web\src\views\mobileAttendance\lang\en.jsx
 */

export default {
  mobileAttendance:{
    router:{
      mobileAttendance: 'Mobile Attendance',
      attendanceManagement: 'Attendance Management',
      faceAttendance: 'Facial Attendance'
    },
    region: {
      header: 'Fence List',
      display: 'Fence Display',
      createPolygon: 'Create Polygon',
      startEditing: 'Start Editing',
      endEditing: 'End Editing',
      saveFence: 'Save Fence',
      cancelSelection: 'Cancel Selection',
      delete: 'Delete',
      deleteConfirm: 'This operation will permanently delete this operation, do you want to continue?',
      prompt: 'Prompt',
      confirm: 'Confirm',
      cancel: 'Cancel',
      cancelDelete: 'Cancel Deletion',
      searchPlaceholder: 'Enter address name to search location',
      title: 'Area Information',
      nameLabel: 'Area Name:',
      namePlaceholder: 'Enter area name',
      remarkLabel: 'Remark:',
      remarkPlaceholder: 'Enter remark',
      updateButton: 'Update',
      addButton: 'Add'
    },
    attendance:{
      title:'Attendance Management Details',
      unselectedPersonnel:'Unselected Personnel for Clocking Area',
      management: 'Attendance Management',
      query: 'Query',
      noRemark: 'No Remark',
      details:'Attendance Management Details',
      fenceName: 'Fence Name',
      selectCount: 'Select Count',
      remark: 'Remark',
      enterFenceName: 'Enter fence name',
      addPunchForUnselected: 'Add clock-in for users who have not selected the clock-in area',
      filterKeyword: 'Enter keyword to filter',
      selectedPersonnel: 'Selected Clocking Area Personnel',
      noData: 'No data',
 
    },
    face:{
      workerName: 'Worker Name',
      attendance: 'Facial Attendance',
      workerName: 'Worker Name',
      enterWorkerName: 'Enter worker name',
      query: 'Query',
      name: 'Name',
      originalImage: 'Original Image',
      randomImage: 'Random Image'
    }
  }
}
