/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2024-03-29 21:59:07
 * @Description: file content
 * @FilePath: \auxface_web\src\views\warning\lang\en.jsx
 */
export default {
  "warning": {
    "router": {
      "warningCenter": "Warning Center",
      "personnelWarning": "Personnel Warning",
      "dustWarning": "Dust Warning",
      "electricWarning": "Electricity Warning",
      "waterWarning": "Water Warning"
    },
    "worker": {
      "title": "Personnel Warning",
      "defaultConfig": "Default Configuration",
      "personnelName": "Personnel Name:",
      "enterPersonnelName": "Enter personnel name",
      "warningType": "Warning Type:",
      "selectWarningType": "Select warning type",
      "search": "Search",
      "warningType": "Warning Type",
      "personnelName": "Personnel Name",
      "warningContent": "Warning Content",
      "warningTime": "Warning Time",
      "enableWarning": "Enable Warning",
      "warningNotification": "Warning Notification Via",
      "select": "Select",
      "sendNotification": "Send Notification",
      "phoneNumber": "Phone Number",
      "enterPhoneNumber": "Enter phone number",
      "weChatUser": "WeChat User",
      "bindWeChatUser": "Click to bind WeChat user",
      "emailAddress": "Email Address",
      "enterEmailAddress": "Enter email address",
      "laborerIn": "Laborers in",
      "projectsRegistered": "projects are registered",
      "badBehavior": "bad behavior is recorded",
      "laborerDistance": "laborer distance",
      "daysMale": "days, for males over",
      "yearsMale": "years, for females over",
      "yearsFemale": "years, for warning",
      "laborerWithin": "Laborer within",
      "daysJobChange": "days and change jobs",
      "warningTimes": "times for warning",
      "title": "Personnel Warning Details",
      "sms": "SMS Reminder",
      "wechat": "WeChat Mini Program Reminder",
      "email": "Email Reminder",
      "statusRequired": "Please select whether to enable warning",
      "wayRequired": "Please select notification method",
      "phoneRequired": "Please enter the phone number to receive SMS",
      "wxappUserUuidRequired": "Please bind WeChat Mini Program notification account",
      "emailRequired": "Please enter the email address to receive",
      "projectBadBehaviorRequired": "Please enter the number of projects",
      "badBehaviorRequired": "Please enter the number of bad behavior records",
      "nearDayRequired": "Please enter the distance in time",
      "maleAgeRequired": "Please enter the male warning age",
      "womenAgeRequired": "Please enter the female warning age",
      "changeJobDayRequired": "Please enter the date range",
      "changeJobRequired": "Please enter the number of job changes"
    },
    "dust": {
      "title": "Dust Warning Configuration",
      "warning": "Dust Warning",
      "defaultConfig": "Default Configuration",
      "deviceNameLabel": "Device Name:",
      "deviceNamePlaceholder": "Enter device name",
      "warningTypeLabel": "Warning Type:",
      "warningTypePlaceholder": "Select warning type",
      "search": "Search",
      "deviceNormal": "Device operates normally with no warning",
      "warningConfig": "Warning Configuration",
      "detailData": "Detailed Data",
      "warningTypeLabel": "Warning Type",
      "deviceNameLabel": "Device Name",
      "warningContentLabel": "Warning Content",
      "warningTimeLabel": "Warning Time",
      "warningConfigLabel": "Warning Configuration",
      "enableWarningLabel": "Enable Warning",
      "warningIntervalLabel": "Time from last warning to",
      "minutesBeforeWarning": "minutes for re-warning",
      "sms": "SMS Reminder",
      "wechat": "WeChat Mini Program Reminder",
      "email": "Email Reminder",
      "warningThreshold": "Warning Threshold",
      "pm25": "PM2.5",
      "pm10": "PM10",
      "tsp": "Total Suspended Particles (TSP)",
      "noise": "Noise",
      "windSpeed": "Wind Speed",
      "temperature": "Temperature",
      "humidity": "Humidity",
      "pressure": "Pressure",
      "totalSuspendedParticles": "TSP",
      "exceedsThreshold": "exceeding the threshold",
      "triggerWarning": "to trigger warning",
      "enableWarningMessage": "Please select whether to enable warning",
      "notificationMethodMessage": "Please select notification method",
      "warningIntervalMessage": "Please enter warning interval",
      "phoneNumberMessage": "Please enter the phone number for SMS reception",
      "bindWeChatAccountMessage": "Please bind WeChat Mini Program notification account",
      "emailAddressMessage": "Please enter the email address to receive",
      "pm25ThresholdMessage": "Please enter the PM2.5 threshold value",
      "pm10ThresholdMessage": "Please enter the PM10 threshold value",
      "tspThresholdMessage": "Please enter the TSP threshold value",
      "noiseThresholdMessage": "Please enter the noise threshold value",
      "windSpeedThresholdMessage": "Please enter the wind speed threshold value",
      "temperatureThresholdMessage": "Please enter the temperature threshold value",
      "humidityThresholdMessage": "Please enter the humidity threshold value",
      "atmosphericPressureThresholdMessage": "Please enter the atmospheric pressure threshold value",
      "warningTypeLabel": "Warning Type",
      "description": "Warning Content",
      "inDate": "Warning Time",
      "detailDataLabel": "Detailed Data",
      "detailTitle": "Detailed Data for {name}",
      "cancel": "Cancel",
      "dateLabel": "Date:",
      "dateRangeSeparator": "to",
      "startDatePlaceholder": "Start date",
      "endDatePlaceholder": "End date",
      "warningTypeLabel": "Warning Type:",
      "selectWarningTypePlaceholder": "Select warning type",
      "sendNotificationMessage": "Send Notification",
      "phoneNumberLabel": "Phone Number:",
      "notificationMethodLabel": "Warning Notification Via",
      "warningThreshold": "Warning Threshold",
      "selectNotificationMethodPlaceholder": "Select",
      "query": "Query",
      "emailLabel": "Email Address",
      "enterEmailAddress": "Enter email address",
      "enterPhoneNumber": "Enter phone number",
      "weChatUser": "WeChat User:",
      "bindWeChatUser": "Click to bind WeChat user"
    },
    "electric": {
      "title": "Electricity Warning Configuration",
      "warning": "Electricity Warning",
      "defaultConfig": "Default Configuration",
      "deviceNameLabel": "Device Name:",
      "deviceNamePlaceholder": "Enter device name",
      "warningTypeLabel": "Warning Type:",
      "warningTypePlaceholder": "Select warning type",
      "search": "Search",
      "deviceNormal": "Device operates normally with no warning",
      "warningConfig": "Warning Configuration",
      "detailData": "Detailed Data",
      "warningTypeLabel": "Warning Type",
      "deviceNameLabel": "Device Name",
      "warningContentLabel": "Warning Content",
      "warningTimeLabel": "Warning Time",
      "warningConfigLabel": "Warning Configuration",
      "enableWarningLabel": "Enable Warning",
      "warningIntervalLabel": "Time from last warning to",
      "minutesBeforeWarning": "minutes for re-warning",
      "sms": "SMS Reminder",
      "wechat": "WeChat Mini Program Reminder",
      "email": "Email Reminder",
      "enableWarningMessage": "Please select whether to enable warning",
      "notificationMethodMessage": "Please select notification method",
      "warningIntervalMessage": "Please enter warning interval",
      "phoneNumberMessage": "Please enter the phone number for SMS reception",
      "bindWeChatAccountMessage": "Please bind WeChat Mini Program notification account",
      "emailAddressMessage": "Please enter the email address to receive",
      "voltageLabel": "Voltage higher than",
      "currentLabel": "Current higher than",
      "temperatureLabel": "Temperature higher than",
      "powerLabel": "Power greater than",
      "electricPowerLabel": "Electric power greater than",
      "groundStateLabel": "When ground state is abnormal",
      "triggerWarning": "to trigger warning",
      "required": "Required",
      "notRequired": "Not Required",
      "whenGroundStateAbnormal": "to trigger warning",
      "enterVoltageThreshold": "Enter voltage threshold",
      "enterCurrentThreshold": "Enter current threshold",
      "enterTemperatureThreshold": "Enter temperature threshold",
      "enterPowerThreshold": "Enter power threshold",
      "enterElectricPowerThreshold": "Enter electric power threshold",
      "selectGroundStateReminder": "Select whether to remind when ground state is abnormal",
      "warningTypeLabel": "Warning Type",
      "description": "Warning Content",
      "enterPhoneNumber": "Enter phone number",
      "inDate": "Warning Time",
      "detailDataLabel": "Detailed Data",
      "detailTitle": "Detailed Data for {name}",
      "cancel": "Cancel",
      "dateLabel": "Date:",
      "dateRangeSeparator": "to",
      "startDatePlaceholder": "Start date",
      "endDatePlaceholder": "End date",
      "warningTypeLabel": "Warning Type:",
      "selectWarningTypePlaceholder": "Select warning type",
      "sendNotificationMessage": "Send Notification",
      "phoneNumberLabel": "Phone Number:",
      "notificationMethodLabel": "Warning Notification Via",
      "warningThreshold": "Warning Threshold",
      "selectNotificationMethodPlaceholder": "Select",
      "query": "Query",
      "emailLabel": "Email Address",
      "enterEmailAddress": "Enter email address",
      "weChatUser": "WeChat User:",
      "bindWeChatUser": "Click to bind WeChat user"
    },
    "水": {
      "title": "Water Warning Configuration",
      "warning": "Water Warning",
      "defaultConfig": "Default Configuration",
      "deviceNameLabel": "Device Name:",
      "deviceNamePlaceholder": "Enter device name",
      "warningTypeLabel": "Warning Type:",
      "warningTypePlaceholder": "Select warning type",
      "search": "Search",
      "deviceNormal": "Device operates normally with no warning",
      "warningConfig": "Warning Configuration",
      "detailData": "Detailed Data",
      "warningTypeLabel": "Warning Type",
      "deviceNameLabel": "Device Name",
      "warningContentLabel": "Warning Content",
      "warningTimeLabel": "Warning Time",
      "warningConfigLabel": "Warning Configuration",
      "enableWarningLabel": "Enable Warning",
      "warningIntervalLabel": "Time from last warning to",
      "minutesBeforeWarning": "minutes for re-warning",
      "sms": "SMS Reminder",
      "wechat": "WeChat Mini Program Reminder",
      "email": "Email Reminder",
      "enableWarningMessage": "Please select whether to enable warning",
      "notificationMethodMessage": "Please select notification method",
      "warningIntervalMessage": "Please enter warning interval",
      "phoneNumberMessage": "Please enter the phone number for SMS reception",
      "bindWeChatAccountMessage": "Please bind WeChat Mini Program notification account",
      "emailAddressMessage": "Please enter the email address to receive",
      "voltageLabel": "Voltage higher than",
      "currentLabel": "Current higher than",
      "temperatureLabel": "Temperature higher than",
      "powerLabel": "Power greater than",
      "electricPowerLabel": "Electric power greater than",
      "groundStateLabel": "When ground state is abnormal",
      "triggerWarning": "to trigger warning",
      "required": "Required",
      "notRequired": "Not Required",
      "whenGroundStateAbnormal": "to trigger warning",
      "enterVoltageThreshold": "Enter voltage threshold",
      "enterCurrentThreshold": "Enter current threshold",
      "enterTemperatureThreshold": "Enter temperature threshold",
      "enterPowerThreshold": "Enter power threshold",
      "enterElectricPowerThreshold": "Enter electric power threshold",
      "selectGroundStateReminder": "Select whether to remind when ground state is abnormal",
      "warningTypeLabel": "Warning Type",
      "description": "Warning Content",
      "enterPhoneNumber": "Enter phone number",
      "inDate": "Warning Time",
      "detailDataLabel": "Detailed Data",
      "detailTitle": "Detailed Data for {name}",
      "cancel": "Cancel",
      "dateLabel": "Date:",
      "dateRangeSeparator": "to",
      "startDatePlaceholder": "Start date",
      "endDatePlaceholder": "End date",
      "warningTypeLabel": "Warning Type:",
      "selectWarningTypePlaceholder": "Select warning type",
      "sendNotificationMessage": "Send Notification",
      "phoneNumberLabel": "Phone Number:",
      "notificationMethodLabel": "Warning Notification Via",
      "warningThreshold": "Warning Threshold",
      "selectNotificationMethodPlaceholder": "Select",
      "query": "Query",
      "emailLabel": "Email Address",
      "enterEmailAddress": "Enter email address",
      "weChatUser": "WeChat User:",
      "bindWeChatUser": "Click to bind WeChat user",
      "waterPressureExceeds": "Water Pressure Exceeds",
      "waterLevelExceeds": "Water Level Exceeds",
      "waterFlowExceeds": "Water Flow Exceeds",
      "waterPHExceeds": "Water PH Exceeds",
      "waterTemperatureExceeds": "Water Temperature Exceeds",
      "waterHumidityExceeds": "Water Humidity Exceeds",
      "sludgeConcentrationExceeds": "Sludge Concentration Exceeds",
      "below": ", below",
      "triggerWarning": "to trigger warning"
    }
  }
}