/*
 * @Author: Terry
 * @Date: 2023-12-03 17:10:00
 * @LastEditors: Terry
 * @LastEditTime: 2024-06-05 18:51:51
 * @Description: file content
 * @FilePath: \auxface_web\src\views\certificate\lang\zh-tw.jsx
 */

export default {
  certificate:{
    router:{
      certificateSystemName: '檢查表/許可證',
      machine: '設備檢查表',
      template: '模板管理',
      machinePermission: '權限管理',
      worker: '工作許可證',
      workerAudit: '人員管理',
      train: '培訓簽到',
    },
    global:{
      name:'名稱',
      selectTips:'請選擇',
      addBtn:'添加',
      searchBtn:'查詢',
      corpCodeRequired:'請選擇所屬企業',
      templateCodeRequired:'請選擇所屬模板',
      typeCodeRequired:'請選擇所屬分类',
      enterName:'請輸入名稱',
      corpName:'所屬企業',
      remark:'備註',
      noRemark:'無備註',
      remarkPlaceholder:'請輸入備註',
      template:'所屬模板',
      type:'所屬分类',
      status:'狀態',
      qrCode:'二維碼',
      item:'二級列表',
      exitConfirmation:'退場後將不能重新進場，是否確認？',
      applyDate:'申請時間',
      passBtn:'通過',
      rejectBtn:'拒絕',
      destroyBtn:'移除人員',
      notSelectUser:'未選擇用戶',
      inDate:'進場時間',
      passed:'使用中',
      wait:'待審核',
      notPass:'已拒絕',
      view:'查看',
    },
    machineList:{
      expiredTime: '到期時間',
      exit:'退場',
      entry:'已進場',
      exited:'已退場',
      "validityLabel": "檢查間隔有效時長(天)",
      "validityPlaceholder": "請輸入"
    },
    template:{
      on:'啟用',
      off:'禁用',
      inDate:'創建時間',
    },
    machinePermission:{
      confirmPass:'是否通過人員審核，通過後人員將可以進行設備工單申請',
      confirmDestroy:'是否移除人員，移除後人員將無權限進行設備工單處理',
      confirmReject:'是否拒絕人員審核，拒絕後人員需重新申請',
      alertTitle:'請勾選單個或多個人員進行審核，通過後人員將可以進行設備工單申請',
      machineName:'所屬設備證書',
    },
    risk:{
      expired: "已過期",
      confirmDestroy:'是否移除人員，移除後人員將取消該許可證資格',
      name:'所屬工作證書',
      info:'證書信息',
      content:'申請內容',
      expiredTime:'失效時間'
    },
    riskAudit:{
      confirmPass:'是否通過人員審核，通過後將下發許可證書給人員',
      confirmReject:'請在下方輸入拒絕原因，可按回車換行',
      alertTitle:'請勾選單個或多個人員進行審核，通過後人員獲得該許可證書',
      name:'所屬工作證書',
      info:'證書信息',
      content:'申請內容',
      rejectReason:'拒絕原因',
    },
    train:{
      lecturer:'講師',
      avatar: '人員頭像',
      name: '姓名',
      attendanceTime: '考勤時間',
      entry:'使用中',
      expired:'已過期',
      checkInCount:'考勤人數',
      expiredTime:'失效時間',
      timeSelect:'選擇日期時間',
    },
  }
}