/*
 * @Author: Terry
 * @Date: 2023-12-03 17:10:00
 * @LastEditors: Terry
 * @LastEditTime: 2024-06-02 19:44:30
 * @Description: file content
 * @FilePath: \auxface_web\src\views\certificate\lang\en.jsx
 */

export default {
  certificate:{
    router:{
      certificateSystemName: 'Electronic Certificate',
      machine: 'Equipment Electronic Certificate',
      template: 'template management',
      machinePermission: 'Permission management',
      worker: 'work Permit',
      workerAudit: 'Personnel audit',
      train: 'Training attendance',
    },
    global:{
      name:'Name',
      selectTips:'Please select',
      addBtn:'Add',
      searchBtn:'Search',
      corpCodeRequired:'Please select the affiliated corporation',
      templateCodeRequired:'Please select the affiliated template',
      typeCodeRequired:'Please select the affiliated type',
      enterName:'Enter Name',
      corpName:'Affiliated Corporation',
      remark:'Remarks',
      noRemark:'No remarks',
      remarkPlaceholder:'Enter remarks',
      template:'Affiliated template',
      type:'Affiliated type',
      status:'Status',
      qrCode:'QR Code',
      item:'Secondary list',
      exitConfirmation:'Once exited, re-entry will not be allowed. Are you sure?',
      applyDate:'Application Date',
      passBtn:'Approve',
      rejectBtn:'Reject',
      destroyBtn:'Remove personnel',
      notSelectUser:'User not selected',
      inDate:'Entry Time',
      passed:'Approved',
      wait:'Pending',
      notPass:'Rejected',
      view:'View',
    },
    machineList:{ 
      expiredTime:'Expiration Time',
      exit:'Exit',
      entry:'Entered',
      exited:'Exited',
      "validityLabel": "Check Interval Validity (Days)",
      "validityPlaceholder": "Please enter"
    },
    template:{
      on:'Enabled',
      off:'Disabled',
      inDate:'Creation Time',
    },
    machinePermission:{
      confirmPass:'Are you sure to approve personnel audit? Once approved, personnel will be able to apply for equipment work orders',
      confirmDestroy:'Are you sure to remove personnel? Once removed, personnel will lose the authorization to process equipment work orders',
      confirmReject:'Are you sure to reject personnel audit? Once rejected, personnel will need to reapply',
      alertTitle:"Please select one or more personnel for audit. Once approved, personnel will be able to apply for equipment work orders",
      machineName:'Affiliated equipment certificate',
    },
    risk:{
      expired: "Expired",
      confirmDestroy:'Are you sure to remove personnel? Once removed, personnel will lose the qualification for the license certificate',
      name:'Affiliated behavior certificate',
      info:'Certificate information',
      content:'Application content',
      expiredTime:'ExpiredTime'
    },
    riskAudit:{
      confirmPass:'Are you sure to approve personnel audit? Once approved, personnel will be issued the license certificate',
      confirmReject:'Please enter the rejection reason in the field below, you can use Enter for line break',
      alertTitle:"Please select one or more personnel for audit. Once approved, personnel will be issued the license certificate",
      name:'Affiliated behavior certificate',
      info:'Certificate information',
      content:'Application content',
      rejectReason:'Rejection reason',
    },
    train:{
      lecturer:'Lecturer',
      avatar: 'Avatar',
      name: 'Name',
      attendanceTime: 'Attendance Time',
      entry:'In use',
      expired:'Expired',
      checkInCount:'Attendance count',
      expiredTime:'Expiration Time',
      timeSelect:'Select date and time',
    },
  }
}