/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-09 18:30:47
 * @Description: file content
 * @FilePath: \auxface_web\src\views\corp\lang\zh-cn.jsx
 */
export default {
  corpSafetytrain: {
    router:{
      safetyTrainingEnterprise: '安全培训-企业版',
      courseCenter: '课程中心',
      classificationManagement: '分类管理',
      paperManagement: '试卷管理',
      questionBankManagement: '题库管理'
    },
    classCenter: {
      title:'分类详情',
      categoryManagement: '分类管理',
      addCategory: '添加分类',
      applicablePersonnel: '适用人员',
      selectApplicablePersonnel: '请选择适用人员',
      search: '查询',
      detailInformation: '详细信息',
      delete: '删除',
      noRemark: '无备注',
      confirmDeleteCategory: '是否确认删除此分类?',
      deleteConfirmation: '删除确认',
      confirm: '确定',
      cancel: '取消',
      sort: '排序',
      category: '分类',
      enterCategoryName: '请输入分类名称',
      applicablePersonnel: '适用人员',
      selectApplicablePersonnel: '请选择适用人员',
      remark: '备注',
      remarkPlaceholder: '在此填写备注',
      nameRequired: '分类名称不能为空',
      sortRequired: '请选择排序位置',
      workerRoleRequired: '适用人员不能为空',
      sort: '排序',
      name: '名字',
      workerRole: '适用人员',
      remark: '备注',
      detail: '详细信息',
      delete: '删除',
    },
    questionBank: {
      title:'题库管理',
      questionBankManagement: '题库管理',
      addQuestion: '添加题目',
      selectCategory: '请选择分类：',
      selectCategoryPlaceholder: '请选择分类',
      enterQuestionName: '请输入题目名称',
      enterQuestionNamePlaceholder: '请输入题目名称',
      search: '查询',
      questionName: '题目名称',
      authorName: '出题人',
      category: '分类',
      uploadTime: '上传时间',
      delete: '删除',
      confirmDelete: '是否确认删除此题目?',
      deleteConfirmation: '删除确认',
      confirm: '确定',
      cancel: '取消',
      questionName: '题目名称',
      enterQuestionName: '请输入题目名称',
      image: "图片：",
      uploadSuccess: "上传成功",
      author: "出题人：",
      enterAuthorName: "请输入出题人名称",
      category: "分类：",
      selectCategory: "请选择分类",
      questionType: "题目类型：",
      singleChoice: "单选",
      multipleChoice: "多选",
      options: "题目选项：",
      addOption: "添加选项",
      delete: "删除",
      answer: "题目答案：",
      enterQuestionName: "请输入题目名称",
      lengthBetween1And50: "长度在 1 到 50 个字符",
      enterAuthorName: "请输入出题人名称",
      lengthBetween1And10: "长度在 1 到 10 个字符",
      enterOptions: "请输入选项",
      enterScore: "请输入分数",
    },
    paper: {
      title:'试卷详情',
      paperManagement: '试卷管理',
      addPaper: '添加试卷',
      examName: "试卷名称：",
      enterExamName: "请输入试卷名称",
      classification: "分类：",
      selectClassification: "请选择分类",
      search: "查询",
      questionData: '题目数据',
      delete: '删除',
      examName: '试卷名称',
      authorName: '作者',
      classUuid: '所属分类',
      detail: '详细信息',
      uploadTime: '上传时间',
      delete: '删除',
      confirmDelete: '是否确认删除此试卷？',
      deleteConfirmation: '删除确认',
      confirm: '确定',
      cancel: '取消',
      examName: '试卷名称',
      enterExamName: '请输入试卷名称',
      author: '作者',
      enterAuthorName: '请输入作者名称',
      category: '分类',
      selectCategory: '请选择分类',
      totalScore: '试卷总分',
      passingScore: '试卷及格分',
      enterPassingScore: '请输入内容',
      enterExamName: '请输入试卷名称',
      examNameLength: '长度在 1 到 50 个字符',
      selectCategory: '请选择分类',
      examDetailData: '试卷题目详细数据',
      syncSubject: '同步题目',
      addSubject: '添加题目',
      enterQuestionName: '请输入题目名称',
      questionScore: '题目分值',
      enterQuestionScore: '请输入题目分值',
      questionType: '题目类型',
      selectQuestionType: '请选择题目类型',
      search: '查询',
      image: "图片",
      uploadTime: "上传时间",
      singleChoice: "单选题",
      multipleChoice: "多选题",
      uploadTime: "上传时间",
      delete: "删除",
      singleChoice: "单选题",
      multipleChoice: "多选题",
      confirmDeleteQuestion: "是否确认删除此试题目?",
      deleteConfirmation: "删除确认",
      confirm: "确定",
      cancel: "取消",
      score: "分值",
      answer: "题目答案：",
      questionNameLength: "长度在 1 到 50 个字符",
      enterAuthorName: "请输入出题人名称",
      authorNameLength: "长度在 1 到 10 个字符",
      enterOptions: "请输入选项",
      enterScore: "请输入分值",
      addOption:'添加选项',
      questionName: "题目名称",
      questionStatus: "题目类型",
      classification: "题目分类",
      batchSelectScore: "批量选择分值",
      score: "分值",
      selectClassification: "请选择分类",
      questionType: '题目类型',
      multipleChoice: '多选题',
      singleChoice: '单选题',
      author: '出题人',
      score: '分值',
      add :'添加',
      image: "图片",
      singleChoice: '单选',
      multipleChoice: '多选',
      selectQuestionToAdd: '请选择待添加的题目'
    },
    courseCenter: {
      detailTitle: '课程目录详情',
      title: '课程中心详情',
      courseCenter: '课程中心',
      courseAdd: '课程添加',
      search: '查询',
      selectCategory: '请选择分类',
      selectApplicablePerson: '请选择适用人员',
      enterCourseName: '请输入课程名称',
      noDescription: '无描述',
      delete: '删除',
      sort: '排序',
      courseName: '课程名称',
      image: '图片',
      category: '分类',
      applicablePerson: '适用人员',
      publishTime: '发布时间',
      courseDescription: '课件描述',
      catalogInfo: '目录信息',
      confirmDeleteCourse: '是否确认删除此课程?',
      deleteConfirmation: '删除确认',
      confirm: '确定',
      cancel: '取消',
      courseImage: '课程图片',
      enterCourseDescription: '请输入课件描述',
      courseNameRequired: '课程名称不能为空',
      sortRequired: '请选择排序位置',
      categoryRequired: '课程分类不能为空',
      imageRequired: '图片不能为空',
      applicablePersonRequired: '适用人员不能为空',
      courseDescriptionRequired: '课程描述不能为空',
      changeVideo: '更改视频',
      uploadFailed: '上传失败',
      invalidVideoFormat: '请上传正确的视频格式',
      videoSizeExceeded: '上传视频大小不能超过1024M!',
      uploadLimit: '当前限制上传1个文件',
      uploadOnlyDocument: '上传文件只能是文档格式!',
      documentSizeExceeded: '上传文档大小不能超过 5MB!',
      changeDocument: '更改文档',
      deleteConfirmationMessage: '是否确认删除此课程?',
      deleteConfirmationTitle: '删除确认',
      chapterName: '章节名称',
      fileType: '类型',
      describe: '内容介绍',
      publishTime: '发布时间',
      remark: '备注',
      selectCategoryPlaceholder: '请选择试卷分类',
      enterExamNamePlaceholder: '请输入试卷名称',
      examCategory: '试卷分类',
      examName: '试卷名称',
      sync: '同步',
      chapterName: '章节名称：',
      enterChapterName: '请输入章节名称',
      fileType: '类型：',
      selectType: '请选择类型',
      sort: '排序：',
      syncChapterExam: '章节试卷可进行同步',
      chapterExam: '章节试卷：',
      syncExam: '试卷同步',
      minStudyTime: '最少学习时间：',
      minutes: '分',
      seconds: '秒',
      contentIntro: '内容介绍：',
      enterContentIntro: '请输入内容介绍',
      media: '视频图文：',
      remark: '备注：',
      enterRemark: '请输入备注',
      chapterNameRequired: '章节名称不能为空',
      contentIntroRequired: '内容介绍不能为空',
      sortRequired: '请选择排序位置',
      mediaRequired: '视频图文不能为空',
      courseTypeRequired: '课程类型不能为空',
      minStudyTimeRequired: '最少学习时间不能为空',
    }
  }
}