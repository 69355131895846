/*
 * @Author: Terry
 * @Date: 2023-12-03 17:10:00
 * @LastEditors: Terry
 * @LastEditTime: 2024-04-27 11:15:20
 * @Description: file content
 * @FilePath: \auxface_web\src\views\certification\lang\zh-cn.jsx
 */

export default {
  certification:{
    router:{
      personnelRealNameSystem: '人员实名制',
      personnelList: '人员列表',
      personnelAudit: '人员审核',
      teamManagement: '班组管理',
      deviceManagement: '设备管理',
      attendanceManagement: '考勤管理',
      governmentIntegrationProgress: '政府对接进度',
      salaryList: '工资列表',
      salaryBackup: '工资备份',
      pendingDailyWorks: '待审核日工单',
      pendingMonthlyWorks: '待审核月工单'
    },
    home:{
      loadingText: '加载中...',
      projectCode: '项目编号',
      visualizationPanel: '可视化智慧面板',
      presentPersonnel: '在场人员',
      managementPersonnel: '管理人员',
      laborPersonnel: '劳务人员',
      pendingPersonnel: '待进场人员',
      totalAttendance: '总出勤人数',
      totalTeams: '总班组数',
      dailyAttendanceStatistics: '每日出勤统计',
      units: '个',
      constructionUnitName: '施工单位名称',
      constructionType: '施工类型',
      totalPersonnel: '总人数',
      attendancePersonnel: '出勤人数',
      notAttendancePersonnel: '未出勤人数',
      attendanceRate: '出勤率',
      status: '状态',
      present: '在场',
      pendingEntry: '待进场',
      attendanceRate: '出勤率',
      dailyAttendanceTrend: '当日现场人数变化趋势图',
      labalWorkers :'劳务人员',
      labalAdmin:'管理人员',
    },
    team:{
      teamManagement: '班组管理',
      addTeam:'添加班组',
      presentTeams: '在场班组',
      exitedTeams: '已退场班组',
      workType: '所属工种',
      selectUnit: '请选择所属单位',
      name: '名称',
      teamLeader: '班组长',
      search: '查询',
      enterTeamName: '请输入班组名称',
      teamName:'班组名称',
      enterTeamLeaderName: '请输入班组长名称',
      noData: '无',
      notSet: '未设置',
      yes: '是',
      no: '否',
      enter: '进场',
      exit: '退场',
      confirmExitTeam: '是否确认班组退场?',
      viewStaff: '人员查看',
      responsiblePersonName: "班组长",
      memberCount: "班组人数",
      attendanceCount: "考勤人数",
      isSync: "同步政府平台",
      entryTime: "进场时间",
      exitTime: "退场时间",
      qrCode: "二维码",
      viewStaff: "人员查看",
      corpName: "所属单位",
      selectCorp: "请选择所属单位",
      syncToGovPlatform: "同步到政府平台",
      contractFile: "合同文件",
      selectWorkType: "请选择班组工种",
      uploadContractFile: "合同文件必须上传",
      selectSyncToGovPlatform: "必须选择是否上传到政府平台",
      notSyncToGovPlatformMessage: '当前班组选择《不需要同步到政府平台》，包括(《班组信息》,《该班组人员信息》,《该班组人员考勤》),都将不会同步至政府平台',
      notSyncToGovPlatformTitle: '班组不需要同步到政府平台',
      syncToGovPlatformMessage: '当前班组选择了《确认同步到政府平台》,包括(《班组信息》,《该班组人员信息》,《该班组人员考勤》),都将会同步至政府平台',
      syncToGovPlatformTitle: '班组确认同步到政府平台',
      confirm: '确定',
      cancel: '取消',
      getRefreshQrCode: '获取/刷新二维码',
      qrCodeNoNeedSave: '二维码获取与刷新无需保存',
      teamName: '班组名称',
      placeholderTeamName: '请输入班组名称',
      workType: '班组工种',
      placeholderWorkType: '请选择所属工种',
      corpName: '所属企业',
      placeholderCorpName: '请输入所属企业',
      remark: '班组备注',
      entryExitInfo: '进退场信息',
      placeholderRemark: '备注',
      entryInfo: '进场信息：',
      entryTime: '进场时间',
      notEntered: '未进场',
      exitInfo: '退场信息：',
      exitTime: '退场时间',
      notExited: '未退场',
      responsiblePersonInfo: '班组长信息：',
      name: '姓名',
      notSet: '未设置',
      contactNumber: '联系电话：',
      idNumber: '身份证号码：',
      enterTeamName: '请输入班组名字',
      entry: '班组进场',
      exitTeam: '班组退场',
      entryAttachment: '进场附件',
      exitAttachment: '离场附件',
      requiredUrl: '请上传退场附件',
      prompt: '提示',
      confirm: '确定',
      cancel: '取消',
      title:'班组管理详情'
    },
    staff: {
      isTeamLeader:'是',
      notTeamLeader:'否',
      personnelList: '人员列表',
      entry: '已进场',
      none: '未进场',
      exit: '已退场',
      workType: '工种',
      selectWorkType: '请选择工种',
      noting:'无',
      workerRole: '工人类型',
      selectWorkerRole: '请选择工人类型',
      workerRole: '工人类型',
      selectWorkerRole: '请选择工人类型',
      personnelName: '人员名称',
      enterPersonnelName: '请输入人员名称',
      search: '查询',
      excelExport: 'excel导出',
      confirmEntry: '确认把该用户入场吗',
      confirmExit: '是否把该用户退场，此操作不可逆请谨慎处理！',
      exitBtn: '退场',
      entryBtn: '进场',
      confirmSetLeader: '是否把该用户设为班组长',
      setLeader: '设为班组长',
      confirmLossLeader: '是否把该用户取消班组长',
      lossLeader: '取消班组长',
      admin: '管理员',
      nonAdmin: '非管理人员',
      male: '男',
      female: '女',
      notVerified: '未实名',
      view: '查看',
      nothing: '无',
      edit: '编辑',
      notSet: '未设置',
      notExited: '未退场',
      avatar: "头像",
      name: "姓名",
      teamName: "班组名称",
      workerType: "人员类型",
      workType: "工种",
      managementPosition: "管理岗位",
      gender: "性别",
      age: "年龄",
      contract: "劳动合同",
      payManagement: "工资管理",
      teamLeader: "班组长",
      entryTime: "进场时间",
      exitTime: "退场时间",
      notSelectedUser: "未选择用户",
      prompt: "提示",
      confirm: "确定",
      cancel: "取消",
      contractRuleSettings: "设置合同规则",
      contractPeriodType: "期限类型：",
      periodTypePlaceholder: "期限类型",
      fixedTermContract: "固定期限合同",
      workBasedContract: "以完成一定工作为期限的合同",
      startDate: "开始日期：",
      endDate: "结束时期：",
      selectDate: "选择日期时间",
      unit: "计量单位：",
      selectUnit: "请选择计量单位",
      unitPrice: "计量单价：",
      selectUnitPrice: "请选择计量单价",
      save: "立即保存",
      contractAttachments: "合同附件",
      deleteAttachment: "删除附件",
      modifyAttachment: "修改附件",
      selectContractPeriodType: '请选择合同期限类型',
      selectStartDate: '请选择开始时间',
      selectEndDate: '请选择结束时间',
      uploadError: '上传失败',
      uploadImageFormat: '上传图片只能是 JPG 或 PNG 格式！',
      uploadImageSize: '上传图片大小不能超过 3MB!',
      selectStartDateFirst: '需要先选择开始时间',
      endDateEarlierThanStartDate: '结束日期不能早于开始日期',
      confirmClose: '确认关闭？',
      attachmentView: '附件查看',
      entryAttachment: '进场附件',
      exitAttachment: '退场附件',
      notUploaded: '未上传',
      personnelExport: '人员导出',
      corpBelong: '所属企业',
      selectCorp: '请选择所属单位',
      export: '导出',
      cancel: '取消',
      attendanceDateRequired: '考勤日期不能为空',
      corpBelongRequired: '所属参建单位不能为空',
      personnelDetails: "人员详情数据",
      photo: "证件照/头像",
      idCardNumberLabel: "证件号码：",
      genderLabel: "性别：",
      male: "男",
      female: "女",
      nationLabel: "民族：",
      birthdayLabel: "出生日期：",
      nativePlaceLabel: "籍贯：",
      issuingAuthorityLabel: "签证机关：",
      validFromLabel: "证件生效期：",
      validUntilLabel: "证件失效期：",
      medicalHistoryLabel: "有无重大病史：",
      no: "无",
      yes: "有",
      phoneNumberLabel: "手机号码：",
      emergencyContactLabel: "紧急联系人：",
      emergencyContactPhoneLabel: "紧急联系人电话：",
      notCertified: "该用户未实名认证",
      careerHistory: "职业履历",
      projectName: "项目名称",
      participatingUnit: "参建单位",
      teamName: "班组名称",
      workType: "工种",
      labourContract: "劳动合同",
      attachment: "附件",
      notUploaded: "未上传",
      entryTime: "入场时间",
      exitTime: "退场时间",
      notExited: "未退场",
      qualificationInformation: "资质信息",
      certificateName: "证书名称",
      certificateNumber: "证书编号",
      certificateCategory: "证书种类",
      certificateType: "证书类型",
      confirmingDepartment: "认定部门",
      jobType: "岗位类型",
      firstIssuanceDate: "第一次发证时间",
      certificateValidityStart: "证书有效时间（起）",
      certificateValidityEnd: "证书有效时间（止）",
      issuingAuthority: "发证机关",
      salaryManagement: "工资管理",
      compensationMethod: "计酬方式",
      selectCompensationMethod: "请选择计算薪酬的方式",
      calculateByHour: "按时计算",
      calculateByDay: "按日计算",
      calculateByMonth: "按月计算",
      salaryUnitPrice: "薪酬单价(元)",
      enterSalaryUnitPrice: "请输入薪酬单价",
      workingDays: "工作日(天)",
      enterWorkingDays: "请输入工作日",
      enterWorkingDaysPerMonth: "一个月多少个工作日",
      personnelBankCardManagement: "人员银行卡管理",
      bank: "所属银行",
      selectBank: "请选择所属银行",
      bankBranch: "银行支行",
      enterBankBranch: "请输入银行支行",
      bankCardNumber: "银行卡号",
      enterBankCardNumber: "请输入银行卡号",
      submit: "提交",
      selectCompensationMethod: "请选择计算薪酬的方式",
      enterSalaryUnitPrice: "请输入薪酬单价",
  
    },
    attendance: {
      attendanceRecord: "考勤记录",
      enteredPersonnel: "已进场人员",
      exitedPersonnel: "已离场人员",
      personnelName: "人员名称",
      enterPersonnelName: "请输入人员名称",
      workType: "工种",
      selectWorkType: "请选择工种",
      workerType: "工人类型",
      selectWorkerType: "请选择工人类型",
      attendanceMonth: "考勤月份",
      selectMonth: "请选择月份",
      search: "查询",
      excelExport: "Excel导出",
      userName: "用户姓名",
      teamName: "所在班组",
      attendanceTotalDays: "考勤总天数",
      firstEntryTime: "首次进场",
      notEntered: "未进场",
      lastExitTime: "最后离场",
      notExited: "未离场",
      totalTime: "共",
      noAttendance: "当日无考勤",
      entryDays: "进场天数",
      noAttendanceDays: "无考勤天数",
      entryCount: "进场次数",
      exitCount: "离场次数",
      visitSource: "访问来源",
      attendanceRecord: "考勤记录",
      attendanceRecord: "考勤记录",
      entry: "进场",
      exit: "出场",
      exportAttendance: "考勤导出",
      corp: "所属企业",
      selectCorp: "请选择所属单位",
      exportMonth: "导出月份",
      selectMonth: "请选择月份",
      exportType: "导出类型",
      personnelHours: "人员工时",
      attendanceDetails: "考勤详细",
      export: "导出",
      cancel: "取消",
      dateRequired: "考勤日期不能为空",
      corpRequired: "所属参建单位不能为空"
    },
    audit: {
      pendingTitle: '待审核人员',
      pending: '待审核',
      notPass: '不通过',
      workType: '工种',
      selectWorkType: '请选择工种',
      workerRole: '工人类型',
      selectWorkerRole: '请选择工人类型',
      manageType: '管理岗位',
      selectManageType: '请选择管理岗位',
      workerName: '人员名称',
      enterWorkerName: '请输入人员名称',
      search: '查询',
      alertTitle: '请勾选单个或多个人员进行审核，通过后人员为待进场状态。亦可点击《通过并进场》按钮使人员直接为进场状态',
      confirmPassAndEnter: '是否通过人员审核并让此人员进场，进场人员可直接通过设备入场',
      passAndEnter: '通过并进场',
      confirmPass: '是否通过人员审核，通过后人员将成为该项目人员',
      pass: '通过',
      confirmReject:'是否拒绝人员审核,拒绝后人员需重新申请',
      reject:'拒绝',
      nonManager: '非管理人员',
      male: '男',
      female: '女',
      notVerified: '未实名',
      rejected: '已拒绝',
      corpName: '所属企业',
      avatar: "头像",
      workerName: '姓名',
      teamName: '所在班组名称',
      workerRole: '人员类型',
      workType: '工种',
      manageType: '管理岗位',
      gender: '性别',
      age: '年龄',
      status: '审核结果',
      notSelectUser: '未选择用户',
      prompt: '提示',
      confirm: '确定',
      cancel: '取消'
    },
    machineManage:{
      lastHeartDate:'最后心跳',
      title: '设备管理详情',
      deviceManagement: '设备管理',
      deviceAdd: '设备添加',
      deviceName: '设备名称',
      enterDeviceName: '请输入设备名称',
      onlineStatus: '在线状态',
      selectStatus: '请选择',
      online: '在线',
      offline: '离线',
      search: '查询',
      syncFailureAlert: '当设备多次同步，人员更换头像，重新下发人员到设备均失败后，请重置设备后再次进行数据同步',
      noRemark: '无备注',
      dataSync: '数据同步',
      syncFailureUsers: '同步失败人员',
      reset: '重置',
      deviceName: '设备名称',
      remark: '备注',
      deviceCode: '设备编码',
      onlineStatus: '在线状态',
      deviceUsers: '设备人数',
      faceSync: '人脸同步',
      reset: '重置',
      delete: '删除',
      confirm: '确定',
      cancel: '取消',
      resetConfirm: '重置确认',
      deleteConfirm: '删除确认',
      confirm: '确定',
      cancel: '取消',
      resetConfirmation: '将会把设备内所有人员清空，是否确认？',
      deleteConfirmation: '是否确认删除此考勤机？',
      syncFailedUsers: '同步失败人员',
      loadingText: '拼命加载中',
      userLabel: '用户名称',
      commandLabel: '命令下发时间',
      remarkLabel: '备注',
      failType0: '人员移除失败，请重试',
      failType1: '人员下发失败，请让用户换头像后重新下发人脸数据',
      failType2: '人员下发失败，请让用户实名后重新下发人脸数据',
      deviceName: '设备名称',
      deviceNamePlaceholder: '请输入设备名称',
      deviceCode: '设备编码',
      deviceCodePlaceholder: '请输入设备编码',
      integrator: '集成商',
      integratorPlaceholder: '请选择',
      deviceDirection: '设备方向',
      directionIn: '进场',
      directionOut: '出场',
      remark: '备注',
      remarkPlaceholder: '有需要可以填写备注',
      machineNameRequired: '设备名称不能为空',
      deviceIdRequired: '设备编码不能为空',
      directionRequired: '进场方向不能为空',
      integratorRequired: '集成商不能为空',
    },
    governmentStatus:{
      governmentAbnormalConnection: '政府异常对接',
      team: '班组：',
      member: '人员：',
      attendance: '今日考勤：',
      connectionStatus: '对接情况（成功数/总数）',
      teamConnectionAbnormal: '班组对接异常',
      workerConnectionAbnormal: '人员对接异常',
      attendanceConnectionAbnormal: '考勤对接异常',
      noGovernmentConnection: '该项目暂无对接政府系统，如需要对接请联系管理员',
      syncTeam: '班组同步',
      syncWorker: '人员同步',
      syncAttendance: '考勤同步',
      syncMachine: '设备同步',
      syncInProgress: '正在同步中，如长时间没有变化请再次点击上方同步按钮',
      notSynced: '未同步，请点击上方同步按钮进行同步',
      notSynced: '未同步',
      uploadFailed: '上传失败',
      uploading: '上传中，请耐心等待',
      prompt: '提示',
      cancel: '取消',
      confirm: '确认',
      contractor: '参建单位',
      teamConnection: '班组对接',
      workerBasicInfo: '工人基础信息',
      workerDataEntry: '工人数据进项目',
      attendanceData: '人员考勤数据',
      salaryAccount: '工资发放账号',
      trainingInfo: '人员培训信息',
      workerPersonalInfo: '工人个人信息',
      faceDevice: '人脸设备',
      workerSalarySlip: '工人工资单',
      getProjectHash: '获取项目hash值',
      getPersonnelHashData: '获取人员hash数据',
      teamName: '班组名称',
      name: '名称',
      governmentType: '政府类型',
      attendanceTime: '考勤时间',
      errorReason: '错误原因',
      connectionTime: '对接时间',
      uploadStatus: '上传状态'
    },
  }
}
  