/*
 * @Author: Terry
 * @Date: 2023-12-03 17:10:00
 * @LastEditors: Terry
 * @LastEditTime: 2024-09-09 21:19:18
 * @Description: file content
 * @FilePath: \auxface_web\src\views\confinedSpace\lang\zh-cn.jsx
 */

export default {
  confinedSpace: {
    router: {
      systemName:'密闭空间',
      gas:'气体检测',
    },
  },
};