/*
 * @Descripttion: 
 * @version: 
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Terry
 * @LastEditTime: 2024-09-05 20:25:37
 */ 
export default [
  {
    path:'/auth',
    name:'authLayout',
    component: ()=>import("@/views/platform/auth/Layout.vue"),
    redirect: { path: '/auth' },
    children: [
      {name: "login",path: "login",component: ()=>import("@/views/platform/auth/Login.vue")},
      {name: "forget",path: "forget",component: ()=>import("@/views/platform/auth/Forget.vue")},
      {name: "register",path: "register",component: ()=>import("@/views/platform/auth/Register.vue")},
    ]
  },
  {name: 'modules',path :'/modules',component: ()=>import("@/views/platform/modules/Index.vue"),},
  {
    path: '/platform',
    name: 'platformIndex',
    component: ()=>import("@/views/Index.vue"),
    redirect: { path: '/platform' },
    meta: { title: 'platform.router.title' },
    // 嵌套路由 》》 对应着Home组件中的router-viewa
      children: [
        //平台首页
        { name: 'pOverView', path: 'overView', component: () => import("@/views/platform/overView/Index.vue") },
        //项目相关
        { name: 'projectCreate', path: 'project/create', component: () => import("@/views/platform/project/Create.vue"), meta: { title: 'platform.router.projectCreateTitle' } },
        { name: 'projectList', path: 'project/list', component: () => import("@/views/platform/project/Index.vue"), meta: { title: 'platform.router.projectListTitle' } },
        //账号管理
        { name: 'accountList', path: 'account/list', component: () => import("@/views/platform/account/Index.vue"), meta: { title: 'platform.router.accountListTitle' } },
        //公司管理
        { name: 'corpReg', path: 'corp/corpReg', component: () => import("@/views/platform/corp/CorpReg.vue") },
        { name: 'corpInfo', path: 'corp/info', component: () => import("@/views/platform/corp/Info.vue") },
        { name: 'corpAudit', path: 'corp/audit', component: () => import("@/views/platform/corp/Audit.vue"), meta: { title: 'platform.router.corpAuditTitle' } },
        { name: 'corpList', path: 'corp/list', component: () => import("@/views/platform/corp/List.vue"), meta: { title: 'platform.router.corpListTitle' } },
        //  账单
        { name: 'bill', path: 'finance/bill', component: () => import("@/views/platform/finance/Bill.vue") },
        //政府账号
        { name: 'governmentList', path: 'government/list', component: () => import("@/views/platform/government/Index.vue"), meta: { title: 'platform.router.governmentListTitle' } },
        //个人中心
        { name: 'userCenter', path: 'user/center', component: () => import("@/views/platform/user/Center.vue"), meta: { title: 'platform.router.userCenterTitle' } },
      ]
  }
];

