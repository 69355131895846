/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-14 23:28:57
 * @Description: file content
 * @FilePath: \auxface_web\src\views\warning\lang\zh-cn.jsx
 */
export default {
  warning:{
    router: {
      warningCenter: "预警中心",
      personnelWarning: "人员预警",
      dustWarning: "扬尘预警",
      electricWarning: "用电预警",
      waterWarning: "用水预警"
    }, 
    worker: {
      title: '人员预警',
      defaultConfig: '默认配置',
      personnelName: '人员名称:',
      enterPersonnelName: '请输入人员名称',
      warningType: '警告类型:',
      selectWarningType: '请选择警告类型',
      search: '查询',
      warningType: '警告类型',
      personnelName: '人员名称',
      warningContent: '预警内容',
      warningTime: '预警时间',
      enableWarning: '是否开启预警',
      warningNotification: '预警通知通过',
      select: '请选择',
      sendNotification: '发送信息提醒',
      phoneNumber: '手机号码',
      enterPhoneNumber: '请输入手机号码',
      weChatUser: '微信用户',
      bindWeChatUser: '点击绑定微信用户',
      emailAddress: '邮箱号码',
      enterEmailAddress: '请输入邮箱号码',
      laborerIn: '劳务人员在',
      projectsRegistered: '个项目中被登记',
      badBehavior: '次不良记录进行预警',
      laborerDistance: '劳务人员距离',
      daysMale: '天，男性达到',
      yearsMale: '岁，女性达到',
      yearsFemale: '岁，进行预警',
      laborerWithin: '劳务人员在',
      daysJobChange: '天内更换工作',
      warningTimes: '次进行预警',
      title: '人员预警详情',
      sms: '短信提醒',
      wechat: '微信小程序提醒',
      email: '邮箱提醒',
      statusRequired: '请选择是否开启预警',
      wayRequired: '请选择通知方式',
      phoneRequired: '请输入接收短信的手机号码',
      wxappUserUuidRequired: '请绑定微信小程序通知帐号',
      emailRequired: '请输入接受邮件的邮箱号码',
      projectBadBehaviorRequired: '请输入项目数',
      badBehaviorRequired: '请输入不良记录次数',
      nearDayRequired: '请输入距离时间',
      maleAgeRequired: '请输入男性预警年龄',
      womenAgeRequired: '请输入女性预警年龄',
      changeJobDayRequired: '请输入日期范围',
      changeJobRequired: '请输入更换工作次数',
    },
    dust: {
      title:'扬尘预警配置',
      warning: '扬尘预警',
      defaultConfig: '默认配置',
      deviceNameLabel: '设备名称：',
      deviceNamePlaceholder: '请输入设备名称',
      warningTypeLabel: '警告类型：',
      warningTypePlaceholder: '请选择警告类型',
      search: '查询',
      deviceNormal: '设备正常无预警',
      warningConfig: '预警配置',
      detailData: '详细数据',
      warningTypeLabel: "警告类型",
      deviceNameLabel: "设备名称",
      warningContentLabel: "预警内容",
      warningTimeLabel: "预警时间",
      warningConfigLabel: "预警配置",
      enableWarningLabel: "是否开启预警",
      warningIntervalLabel: "距离上次预警",
      minutesBeforeWarning: "分钟再次预警",
      sms: '短信提醒',
      wechat: '微信小程序提醒',
      email: '邮箱提醒',
      warningThreshold: "预警阈值",
      pm25: "pm2.5",
      pm10: "pm10",
      tsp: "总悬浮颗粒物(TSP)",
      noise: "噪音",
      windSpeed: "风速",
      temperature: "温度",
      humidity: "湿度",
      pressure: "气压",
      totalSuspendedParticles: "tsp",
      exceedsThreshold: "持续超过",
      triggerWarning: "进行预警",
      enableWarningMessage: '请选择是否开启预警',
      notificationMethodMessage: '请选择通知方式',
      warningIntervalMessage: '请输入预警间距',
      phoneNumberMessage: '请输入接收短信的手机号码',
      bindWeChatAccountMessage: '请绑定微信小程序通知帐号',
      emailAddressMessage: '请输入接受邮件的邮箱号码',
      pm25ThresholdMessage: '请输入PM2.5超标阈值',
      pm10ThresholdMessage: '请输入PM10超标阈值',
      tspThresholdMessage: '请输入TSP超标阈值',
      noiseThresholdMessage: '请输入噪音超标阈值',
      windSpeedThresholdMessage: '请输入风速超标阈值',
      temperatureThresholdMessage: '请输入温度超标阈值',
      humidityThresholdMessage: '请输入湿度超标阈值',
      atmosphericPressureThresholdMessage: '请输入气压超标阈值',
      warningTypeLabel:'警告类型',
      description:'预警内容',
      inDate:'预警时间',
      detailDataLabel:'详细数据',
      detailTitle: "{name}的详细数据",
      cancel: "取消",
      dateLabel: "日期：",
      dateRangeSeparator: "至",
      startDatePlaceholder: "开始日期",
      endDatePlaceholder: "结束日期",
      warningTypeLabel: "警告类型：",
      selectWarningTypePlaceholder: "请选择警告类型",
      sendNotificationMessage: "发送信息提醒",
      phoneNumberLabel: "手机号码:",
      notificationMethodLabel: "预警通知通过",
      warningThreshold: "预警阈值",
      selectNotificationMethodPlaceholder: "请选择",
      query: "查询",
      emailLabel: '邮箱号码',
      enterEmailAddress: '请输入邮箱号码',
      enterPhoneNumber: '请输入手机号码',
      weChatUser: '微信用户:',
      bindWeChatUser: '点击绑定微信用户',
    },
    electric: {
      title:'用电预警配置',
      warning: '用电预警',
      defaultConfig: '默认配置',
      deviceNameLabel: '设备名称：',
      deviceNamePlaceholder: '请输入设备名称',
      warningTypeLabel: '警告类型：',
      warningTypePlaceholder: '请选择警告类型',
      search: '查询',
      deviceNormal: '设备正常无预警',
      warningConfig: '预警配置',
      detailData: '详细数据',
      warningTypeLabel: "警告类型",
      deviceNameLabel: "设备名称",
      warningContentLabel: "预警内容",
      warningTimeLabel: "预警时间",
      warningConfigLabel: "预警配置",
      enableWarningLabel: "是否开启预警",
      warningIntervalLabel: "距离上次预警",
      minutesBeforeWarning: "分钟再次预警",
      sms: '短信提醒',
      wechat: '微信小程序提醒',
      email: '邮箱提醒',
      enableWarningMessage: '请选择是否开启预警',
      notificationMethodMessage: '请选择通知方式',
      warningIntervalMessage: '请输入预警间距',
      phoneNumberMessage: '请输入接收短信的手机号码',
      bindWeChatAccountMessage: '请绑定微信小程序通知帐号',
      emailAddressMessage: '请输入接受邮件的邮箱号码',
      voltageLabel: "电压高于",
      currentLabel: "电流高于",
      temperatureLabel: "温度高于",
      powerLabel: "功率大于",
      electricPowerLabel: "电力大于",
      groundStateLabel: "接地状态异常时",
      triggerWarning: "进行预警",
      required: "需要",
      notRequired: "不需要",
      whenGroundStateAbnormal: "进行预警",
      enterVoltageThreshold: "请输入电压阈值",
      enterCurrentThreshold: "请输入电流阈值",
      enterTemperatureThreshold: "请输入温度阈值",
      enterPowerThreshold: "请输入功率阈值",
      enterElectricPowerThreshold: "请输入电力阈值",
      selectGroundStateReminder: "请选择接地状态异常是否提醒",
      warningTypeLabel:'警告类型',
      description:'预警内容',
      enterPhoneNumber: '请输入手机号码',
      inDate:'预警时间',
      detailDataLabel:'详细数据',
      detailTitle: "{name}的详细数据",
      cancel: "取消",
      dateLabel: "日期：",
      dateRangeSeparator: "至",
      startDatePlaceholder: "开始日期",
      endDatePlaceholder: "结束日期",
      warningTypeLabel: "警告类型：",
      selectWarningTypePlaceholder: "请选择警告类型",
      sendNotificationMessage: "发送信息提醒",
      phoneNumberLabel: "手机号码:",
      notificationMethodLabel: "预警通知通过",
      warningThreshold: "预警阈值",
      selectNotificationMethodPlaceholder: "请选择",
      query: "查询",
      emailLabel: '邮箱号码',
      enterEmailAddress: '请输入邮箱号码',
      weChatUser: '微信用户:',
      bindWeChatUser: '点击绑定微信用户',
    },
    water: {
      title:'用水预警配置',
      warning: '用水预警',
      defaultConfig: '默认配置',
      deviceNameLabel: '设备名称：',
      deviceNamePlaceholder: '请输入设备名称',
      warningTypeLabel: '警告类型：',
      warningTypePlaceholder: '请选择警告类型',
      search: '查询',
      deviceNormal: '设备正常无预警',
      warningConfig: '预警配置',
      detailData: '详细数据',
      warningTypeLabel: "警告类型",
      deviceNameLabel: "设备名称",
      warningContentLabel: "预警内容",
      warningTimeLabel: "预警时间",
      warningConfigLabel: "预警配置",
      enableWarningLabel: "是否开启预警",
      warningIntervalLabel: "距离上次预警",
      minutesBeforeWarning: "分钟再次预警",
      sms: '短信提醒',
      wechat: '微信小程序提醒',
      email: '邮箱提醒',
      enableWarningMessage: '请选择是否开启预警',
      notificationMethodMessage: '请选择通知方式',
      warningIntervalMessage: '请输入预警间距',
      phoneNumberMessage: '请输入接收短信的手机号码',
      bindWeChatAccountMessage: '请绑定微信小程序通知帐号',
      emailAddressMessage: '请输入接受邮件的邮箱号码',
      voltageLabel: "电压高于",
      currentLabel: "电流高于",
      temperatureLabel: "温度高于",
      powerLabel: "功率大于",
      electricPowerLabel: "电力大于",
      groundStateLabel: "接地状态异常时",
      triggerWarning: "进行预警",
      required: "需要",
      notRequired: "不需要",
      whenGroundStateAbnormal: "进行预警",
      enterVoltageThreshold: "请输入电压阈值",
      enterCurrentThreshold: "请输入电流阈值",
      enterTemperatureThreshold: "请输入温度阈值",
      enterPowerThreshold: "请输入功率阈值",
      enterElectricPowerThreshold: "请输入电力阈值",
      selectGroundStateReminder: "请选择接地状态异常是否提醒",
      warningTypeLabel:'警告类型',
      description:'预警内容',
      enterPhoneNumber: '请输入手机号码',
      inDate:'预警时间',
      detailDataLabel:'详细数据',
      detailTitle: "{name}的详细数据",
      cancel: "取消",
      dateLabel: "日期：",
      dateRangeSeparator: "至",
      startDatePlaceholder: "开始日期",
      endDatePlaceholder: "结束日期",
      warningTypeLabel: "警告类型：",
      selectWarningTypePlaceholder: "请选择警告类型",
      sendNotificationMessage: "发送信息提醒",
      phoneNumberLabel: "手机号码:",
      notificationMethodLabel: "预警通知通过",
      warningThreshold: "预警阈值",
      selectNotificationMethodPlaceholder: "请选择",
      query: "查询",
      emailLabel: '邮箱号码',
      enterEmailAddress: '请输入邮箱号码',
      weChatUser: '微信用户:',
      bindWeChatUser: '点击绑定微信用户',
      waterPressureExceeds: "水压高于",
      waterLevelExceeds: "水位高于",
      waterFlowExceeds: "水流量值高于",
      waterPHExceeds: "水PH值高于",
      waterTemperatureExceeds: "水温度高于",
      waterHumidityExceeds: "水湿度高于",
      sludgeConcentrationExceeds: "污泥浓度高于",
      below: "，低于",
      triggerWarning: "进行预警"
    },

  }
}
  