/*
 * @Author: Terry
 * @Date: 2023-12-03 17:10:00
 * @LastEditors: Terry
 * @LastEditTime: 2024-06-05 18:51:51
 * @Description: file content
 * @FilePath: \auxface_web\src\views\certificate\lang\zh-tw.jsx
 */

export default {
  confinedSapce:{
  }
}