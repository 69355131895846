<!--
 * @Descripttion: 
 * @version: v1
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Terry
 * @LastEditTime: 2022-05-23 14:31:56
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>


<style lang="scss">

</style>
<script>
export default({
    name:'App',
    mounted(){
      
    },
    created(){

    }
})
</script>
