/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-14 23:42:45
 * @Description: file content
 * @FilePath: \auxface_web\src\views\warning\lang\zh-tw.jsx
 */
export default {
  warning:{
    router: {
      warningCenter: "預警中心",
      personnelWarning: "人員預警",
      dustWarning: "揚塵預警",
      electricWarning: "用電預警",
      waterWarning: "用水預警"
    }, 
    worker: {
      personnelWarning: "人員預警",
      defaultConfig: "默認配置",
      personnelName: "人員名稱:",
      enterPersonnelName: "請輸入人員名稱",
      warningType: "警告類型:",
      selectWarningType: "請選擇警告類型",
      search: "查詢",
      warningContent: "預警內容",
      warningTime: "預警時間",
      enableWarning: "是否開啟預警",
      warningNotification: "預警通知通過",
      select: "請選擇",
      sendNotification: "發送信息提醒",
      phoneNumber: "手機號碼",
      enterPhoneNumber: "請輸入手機號碼",
      weChatUser: "微信用戶",
      bindWeChatUser: "點擊綁定微信用戶",
      emailAddress: "郵箱號碼",
      enterEmailAddress: "請輸入郵箱號碼",
      laborerIn: "勞務人員在",
      projectsRegistered: "個項目中被登記",
      badBehavior: "次不良記錄進行預警",
      laborerDistance: "勞務人員距離",
      daysMale: "天，男性達到",
      yearsMale: "歲，女性達到",
      yearsFemale: "歲，進行預警",
      laborerWithin: "勞務人員在",
      daysJobChange: "天內更換工作",
      warningTimes: "次進行預警",
      title: "人員預警詳情",
      sms: "短信提醒",
      wechat: "微信小程序提醒",
      email: "郵箱提醒",
      statusRequired: "請選擇是否開啟預警",
      wayRequired: "請選擇通知方式",
      phoneRequired: "請輸入接收短信的手機號碼",
      wxappUserUuidRequired: "請綁定微信小程序通知帳號",
      emailRequired: "請輸入接收郵件的郵箱號碼",
      projectBadBehaviorRequired: "請輸入項目數",
      badBehaviorRequired: "請輸入不良記錄次數",
      nearDayRequired: "請輸入距離時間",
      maleAgeRequired: "請輸入男性預警年齡",
      womenAgeRequired: "請輸入女性預警年齡",
      changeJobDayRequired: "請輸入日期範圍",
      changeJobRequired: "請輸入更換工作次數"
    },
    dust: {
      title: "揚塵預警配置",
      warning: "揚塵預警",
      defaultConfig: "默認配置",
      deviceNameLabel: "設備名稱：",
      deviceNamePlaceholder: "請輸入設備名稱",
      warningTypeLabel: "警告類型：",
      warningTypePlaceholder: "請選擇警告類型",
      search: "查詢",
      deviceNormal: "設備正常無預警",
      warningConfig: "預警配置",
      detailData: "詳細數據",
      warningTypeLabel: "警告類型",
      deviceNameLabel: "設備名稱",
      warningContentLabel: "預警內容",
      warningTimeLabel: "預警時間",
      warningConfigLabel: "預警配置",
      enableWarningLabel: "是否開啟預警",
      warningIntervalLabel: "距離上次預警",
      minutesBeforeWarning: "分鐘再次預警",
      sms: "短信提醒",
      wechat: "微信小程序提醒",
      email: "郵箱提醒",
      warningThreshold: "預警閾值",
      pm25: "PM2.5",
      pm10: "PM10",
      tsp: "總懸浮顆粒物(TSP)",
      noise: "噪音",
      windSpeed: "風速",
      temperature: "溫度",
      humidity: "濕度",
      pressure: "氣壓",
      totalSuspendedParticles: "TSP",
      exceedsThreshold: "持續超過",
      triggerWarning: "進行預警",
      enableWarningMessage: "請選擇是否開啟預警",
      notificationMethodMessage: "請選擇通知方式",
      warningIntervalMessage: "請輸入預警間距",
      phoneNumberMessage: "請輸入接收短信的手機號碼",
      bindWeChatAccountMessage: "請綁定微信小程序通知帳號",
      emailAddressMessage: "請輸入接收郵件的郵箱號碼",
      pm25ThresholdMessage: "請輸入PM2.5超標閾值",
      pm10ThresholdMessage: "請輸入PM10超標閾值",
      tspThresholdMessage: "請輸入TSP超標閾值",
      noiseThresholdMessage: "請輸入噪音超標閾值",
      windSpeedThresholdMessage: "請輸入風速超標閾值",
      temperatureThresholdMessage: "請輸入溫度超標閾值",
      humidityThresholdMessage: "請輸入濕度超標閾值",
      atmosphericPressureThresholdMessage: "請輸入氣壓超標閾值",
      warningTypeLabel: "警告類型",
      description: "預警內容",
      inDate: "預警時間",
      detailDataLabel: "詳細數據",
      detailTitle: "{name}的詳細數據",
      cancel: "取消",
      dateLabel: "日期：",
      dateRangeSeparator: "至",
      startDatePlaceholder: "開始日期",
      endDatePlaceholder: "結束日期",
      warningTypeLabel: "警告類型：",
      selectWarningTypePlaceholder: "請選擇警告類型",
      sendNotificationMessage: "發送信息提醒",
      phoneNumberLabel: "手機號碼：",
      notificationMethodLabel: "預警通知通過",
      warningThreshold: "預警閾值",
      selectNotificationMethodPlaceholder: "請選擇",
      query: "查詢",
      emailLabel: "郵箱號碼",
      enterEmailAddress: "請輸入郵箱號碼",
      enterPhoneNumber: "請輸入手機號碼",
      weChatUser: "微信用戶：",
      bindWeChatUser: "點擊綁定微信用戶"
    },
    electric: {
      title: "用電預警配置",
      warning: "用電預警",
      defaultConfig: "默認配置",
      deviceNameLabel: "設備名稱：",
      deviceNamePlaceholder: "請輸入設備名稱",
      warningTypeLabel: "警告類型：",
      warningTypePlaceholder: "請選擇警告類型",
      search: "查詢",
      deviceNormal: "設備正常無預警",
      warningConfig: "預警配置",
      detailData: "詳細數據",
      warningTypeLabel: "警告類型",
      deviceNameLabel: "設備名稱",
      warningContentLabel: "預警內容",
      warningTimeLabel: "預警時間",
      warningConfigLabel: "預警配置",
      enableWarningLabel: "是否開啟預警",
      warningIntervalLabel: "距離上次預警",
      minutesBeforeWarning: "分鐘再次預警",
      sms: "短信提醒",
      wechat: "微信小程序提醒",
      email: "郵箱提醒",
      enableWarningMessage: "請選擇是否開啟預警",
      notificationMethodMessage: "請選擇通知方式",
      warningIntervalMessage: "請輸入預警間距",
      phoneNumberMessage: "請輸入接收短信的手機號碼",
      bindWeChatAccountMessage: "請綁定微信小程序通知帳號",
      emailAddressMessage: "請輸入接收郵件的郵箱號碼",
      voltageLabel: "電壓高於",
      currentLabel: "電流高於",
      temperatureLabel: "溫度高於",
      powerLabel: "功率大於",
      electricPowerLabel: "電力大於",
      groundStateLabel: "接地狀態異常時",
      triggerWarning: "進行預警",
      required: "需要",
      notRequired: "不需要",
      whenGroundStateAbnormal: "進行預警",
      enterVoltageThreshold: "請輸入電壓閾值",
      enterCurrentThreshold: "請輸入電流閾值",
      enterTemperatureThreshold: "請輸入溫度閾值",
      enterPowerThreshold: "請輸入功率閾值",
      enterElectricPowerThreshold: "請輸入電力閾值",
      selectGroundStateReminder: "請選擇接地狀態異常是否提醒",
      warningTypeLabel: "警告類型",
      description: "預警內容",
      enterPhoneNumber: "請輸入手機號碼",
      inDate: "預警時間",
      detailDataLabel: "詳細數據",
      detailTitle: "{name}的詳細數據",
      cancel: "取消",
      dateLabel: "日期：",
      dateRangeSeparator: "至",
      startDatePlaceholder: "開始日期",
      endDatePlaceholder: "結束日期",
      warningTypeLabel: "警告類型：",
      selectWarningTypePlaceholder: "請選擇警告類型",
      sendNotificationMessage: "發送信息提醒",
      phoneNumberLabel: "手機號碼：",
      notificationMethodLabel: "預警通知通過",
      warningThreshold: "預警閾值",
      selectNotificationMethodPlaceholder: "請選擇",
      query: "查詢",
      emailLabel: "郵箱號碼",
      enterEmailAddress: "請輸入郵箱號碼",
      weChatUser: "微信用戶：",
      bindWeChatUser: "點擊綁定微信用戶"
    },
    water: {
      title: "用水預警配置",
      warning: "用水預警",
      defaultConfig: "默認配置",
      deviceNameLabel: "設備名稱：",
      deviceNamePlaceholder: "請輸入設備名稱",
      warningTypeLabel: "警告類型：",
      warningTypePlaceholder: "請選擇警告類型",
      search: "查詢",
      deviceNormal: "設備正常無預警",
      warningConfig: "預警配置",
      detailData: "詳細數據",
      warningTypeLabel: "警告類型",
      deviceNameLabel: "設備名稱",
      warningContentLabel: "預警內容",
      warningTimeLabel: "預警時間",
      warningConfigLabel: "預警配置",
      enableWarningLabel: "是否開啟預警",
      warningIntervalLabel: "距離上次預警",
      minutesBeforeWarning: "分鐘再次預警",
      sms: "短信提醒",
      wechat: "微信小程序提醒",
      email: "郵箱提醒",
      enableWarningMessage: "請選擇是否開啟預警",
      notificationMethodMessage: "請選擇通知方式",
      warningIntervalMessage: "請輸入預警間距",
      phoneNumberMessage: "請輸入接收短信的手機號碼",
      bindWeChatAccountMessage: "請綁定微信小程序通知帳號",
      emailAddressMessage: "請輸入接收郵件的郵箱號碼",
      voltageLabel: "電壓高於",
      currentLabel: "電流高於",
      temperatureLabel: "溫度高於",
      powerLabel: "功率大於",
      electricPowerLabel: "電力大於",
      groundStateLabel: "接地狀態異常時",
      triggerWarning: "進行預警",
      required: "需要",
      notRequired: "不需要",
      whenGroundStateAbnormal: "進行預警",
      enterVoltageThreshold: "請輸入電壓閾值",
      enterCurrentThreshold: "請輸入電流閾值",
      enterTemperatureThreshold: "請輸入溫度閾值",
      enterPowerThreshold: "請輸入功率閾值",
      enterElectricPowerThreshold: "請輸入電力閾值",
      selectGroundStateReminder: "請選擇接地狀態異常是否提醒",
      warningTypeLabel: "警告類型",
      description: "預警內容",
      enterPhoneNumber: "請輸入手機號碼",
      inDate: "預警時間",
      detailDataLabel: "詳細數據",
      detailTitle: "{name}的詳細數據",
      cancel: "取消",
      dateLabel: "日期：",
      dateRangeSeparator: "至",
      startDatePlaceholder: "開始日期",
      endDatePlaceholder: "結束日期",
      warningTypeLabel: "警告類型：",
      selectWarningTypePlaceholder: "請選擇警告類型",
      sendNotificationMessage: "發送信息提醒",
      phoneNumberLabel: "手機號碼：",
      notificationMethodLabel: "預警通知通過",
      warningThreshold: "預警閾值",
      selectNotificationMethodPlaceholder: "請選擇",
      query: "查詢",
      emailLabel: "郵箱號碼",
      enterEmailAddress: "請輸入郵箱號碼",
      weChatUser: "微信用戶：",
      bindWeChatUser: "點擊綁定微信用戶",
      waterPressureExceeds: "水壓高於",
      waterLevelExceeds: "水位高於",
      waterFlowExceeds: "水流量值高於",
      waterPHExceeds: "水PH值高於",
      waterTemperatureExceeds: "水溫度高於",
      waterHumidityExceeds: "水濕度高於",
      sludgeConcentrationExceeds: "污泥濃度高於",
      below: "，低於",
      triggerWarning: "進行預警"
    },

  }
}
  