/*
 * @Author: Terry
 * @Date: 2023-12-03 17:10:00
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-09 23:08:12
 * @Description: file content
 * @FilePath: \auxface_web\src\views\mobileAttendance\lang\zh-cn.jsx
 */

export default {
  mobileAttendance:{
    router:{
      mobileAttendance: '移动考勤',
      attendanceManagement: '考勤管理',
      faceAttendance: '人脸考勤'
    },
    region: {
      header: '围栏列表',
      display: '围栏展示',
      createPolygon: '新建图形',
      startEditing: '开始编辑',
      endEditing: '结束编辑',
      saveFence: '保存围栏',
      cancelSelection: '取消选中',
      delete: '删除',
      deleteConfirm: '此操作将永久删除该操作，是否继续？',
      prompt: '提示',
      confirm: '确定',
      cancel: '取消',
      cancelDelete: '已取消删除',
      searchPlaceholder: '输入地址名称搜索位置',
      title: '区域信息',
      nameLabel: '区域名称：',
      namePlaceholder: '请输入区域名称',
      remarkLabel: '备注：',
      remarkPlaceholder: '请输入备注',
      updateButton: '更新',
      addButton: '添加'
    },
    attendance:{
      title:'考勤管理详情',
      unselectedPersonnel:'未选打卡区域人员',
      management: '考勤管理',
      query: '查询',
      noRemark: '无备注',
      details:'考勤管理详情',
      fenceName: '围栏名称',
      selectCount: '选择人数',
      remark: '备注',
      enterFenceName: '请输入围栏名称',
      addPunchForUnselected: '为未选择打卡区域的用户进行打卡添加',
      filterKeyword: '输入关键字进行过滤',
      selectedPersonnel: '已选打卡区域人员',
      noData: '暂无数据',
 
    },
    face:{
      workerName: '人员名称',
      attendance: '人脸考勤',
      workerName: '人员名称',
      enterWorkerName: '请输入人员名称',
      query: '查询',
      name: '姓名',
      originalImage: '原图',
      randomImage: '随机后图'
    }
  }
}
  