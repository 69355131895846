/*
 * @Author: Terry
 * @Date: 2023-12-03 17:10:00
 * @LastEditors: Terry
 * @LastEditTime: 2024-04-27 11:15:52
 * @Description: file content
 * @FilePath: \auxface_web\src\views\certification\lang\zh-tw.jsx
 */

export default {
  certification:{
    router: {
      personnelRealNameSystem: '人員實名制',
      personnelList: '人員列表',
      personnelAudit: '人員審核',
      teamManagement: '班組管理',
      deviceManagement: '設備管理',
      attendanceManagement: '考勤管理',
      governmentIntegrationProgress: '政府對接進度',
      salaryList: '工資列表',
      salaryBackup: '工資備份',
      pendingDailyWorks: '待審核日工單',
      pendingMonthlyWorks: '待審核月工單'
    },    
    home:{
      loadingText: '加載中...',
      projectCode: '項目編號',
      visualizationPanel: '可視化智慧面板',
      presentPersonnel: '在場人員',
      managementPersonnel: '管理人員',
      laborPersonnel: '勞務人員',
      pendingPersonnel: '待進場人員',
      totalAttendance: '總出勤人數',
      totalTeams: '總班組數',
      dailyAttendanceStatistics: '每日出勤統計',
      units: '個',
      constructionUnitName: '施工單位名稱',
      constructionType: '施工類型',
      totalPersonnel: '總人數',
      attendancePersonnel: '出勤人數',
      notAttendancePersonnel: '未出勤人數',
      attendanceRate: '出勤率',
      status: '狀態',
      present: '在場',
      pendingEntry: '待進場',
      attendanceRate: '出勤率',
      dailyAttendanceTrend: '當日現場人數變化趨勢圖',
      labalWorkers :'勞務人員',
      labalAdmin:'管理人員',
    },
    team:{
      teamManagement: '班組管理',
      addTeam:'添加班組',
      presentTeams: '在場班組',
      exitedTeams: '已退場班組',
      workType: '所屬工種',
      selectUnit: '請選擇所屬單位',
      name: '名稱',
      teamLeader: '班組長',
      search: '查詢',
      enterTeamName: '請輸入班組名稱',
      teamName:'班組名稱',
      enterTeamLeaderName: '請輸入班組長名稱',
      noData: '無',
      notSet: '未設置',
      yes: '是',
      no: '否',
      enter: '進場',
      exit: '退場',
      confirmExitTeam: '是否確認班組退場?',
      viewStaff: '人員查看',
      responsiblePersonName: "班組長",
      memberCount: "班組人數",
      attendanceCount: "考勤人數",
      isSync: "同步政府平臺",
      entryTime: "進場時間",
      exitTime: "退場時間",
      qrCode: "二維碼",
      viewStaff: "人員查看",
      corpName: "所屬單位",
      selectCorp: "請選擇所屬單位",
      syncToGovPlatform: "同步到政府平臺",
      contractFile: "合同文件",
      selectWorkType: "請選擇班組工種",
      uploadContractFile: "合同文件必須上傳",
      selectSyncToGovPlatform: "必須選擇是否上傳到政府平臺",
      notSyncToGovPlatformMessage: '當前班組選擇《不需要同步到政府平臺》，包括(《班組信息》,《該班組人員信息》,《該班組人員考勤》),都將不會同步至政府平臺',
      notSyncToGovPlatformTitle: '班組不需要同步到政府平臺',
      syncToGovPlatformMessage: '當前班組選擇了《確認同步到政府平臺》,包括(《班組信息》,《該班組人員信息》,《該班組人員考勤》),都將會同步至政府平臺',
      syncToGovPlatformTitle: '班組確認同步到政府平臺',
      confirm: '確定',
      cancel: '取消',
      getRefreshQrCode: '獲取/刷新二維碼',
      qrCodeNoNeedSave: '二維碼獲取與刷新無需保存',
      teamName: '班組名稱',
      placeholderTeamName: '請輸入班組名稱',
      workType: '班組工種',
      placeholderWorkType: '請選擇所屬工種',
      corpName: '所屬企業',
      placeholderCorpName: '請輸入所屬企業',
      remark: '班組備註',
      entryExitInfo: '進退場信息',
      placeholderRemark: '備註',
      entryInfo: '進場信息：',
      entryTime: '進場時間',
      notEntered: '未進場',
      exitInfo: '退場信息：',
      exitTime: '退場時間',
      notExited: '未退場',
      responsiblePersonInfo: '班組長信息：',
      name: '姓名',
      notSet: '未設置',
      contactNumber: '聯繫電話：',
      idNumber: '身份證號碼：',
      enterTeamName: '請輸入班組名称',
      entry: '班组进场',
      exitTeam: '班组退场',
      entryAttachment: '进场附件',
      exitAttachment: '离场附件',
      requiredUrl: '请上传退场附件',
      prompt: '提示',
      confirm: '确定',
      cancel: '取消',
      title:'班组管理详情'
    },
    staff: {
      isTeamLeader:'是',
      notTeamLeader:'否',
      personnelList: '人員列表',
      entry: '已進場',
      none: '未進場',
      exit: '已退場',
      workType: '工種',
      selectWorkType: '請選擇工種',
      workerRole: '工人類型',
      noting:'無',
      selectWorkerRole: '請選擇工人類型',
      workerRole: '工人類型',
      selectWorkerRole: '請選擇工人類型',
      personnelName: '人員名稱',
      enterPersonnelName: '請輸入人員名稱',
      search: '查詢',
      excelExport: 'Excel導出',
      confirmEntry: '確認將該用戶入場嗎',
      confirmExit: '是否將該用戶退場，此操作不可逆請謹慎處理！',
      exitBtn: '退場',
      entryBtn: '進場',
      confirmSetLeader: '是否將該用戶設為班組長',
      setLeader: '設為班組長',
      confirmLossLeader: '是否將該用戶取消班組長',
      lossLeader: '取消班組長',
      admin: '管理員',
      nonAdmin: '非管理人員',
      male: '男',
      female: '女',
      notVerified: '未實名',
      view: '查看',
      nothing: '無',
      edit: '編輯',
      notSet: '未設置',
      notExited: '未退場',
      avatar: "頭像",
      name: "姓名",
      teamName: "班組名稱",
      workerType: "人員類型",
      workType: "工種",
      managementPosition: "管理崗位",
      gender: "性別",
      age: "年齡",
      contract: "勞動合同",
      payManagement: "工資管理",
      teamLeader: "班組長",
      exitTime: "退場時間",
      notSelectedUser: "未選擇用戶",
      prompt: "提示",
      confirm: "確定",
      cancel: "取消",
      contractRuleSettings: "設置合同規則",
      contractPeriodType: "期限類型：",
      periodTypePlaceholder: "期限類型",
      fixedTermContract: "固定期限合同",
      workBasedContract: "以完成一定工作為期限的合同",
      startDate: "開始日期：",
      endDate: "結束時期：",
      selectDate: "選擇日期時間",
      unit: "計量單位：",
      selectUnit: "請選擇計量單位",
      unitPrice: "計量單價：",
      selectUnitPrice: "請選擇計量單價",
      save: "立即保存",
      contractAttachments: "合同附件",
      deleteAttachment: "刪除附件",
      modifyAttachment: "修改附件",
      selectContractPeriodType: '請選擇合同期限類型',
      selectStartDate: '請選擇開始時間',
      selectEndDate: '請選擇結束時間',
      uploadError: '上傳失敗',
      uploadImageFormat: '上傳圖片只能是 JPG 或 PNG 格式！',
      uploadImageSize: '上傳圖片大小不能超過 3MB!',
      selectStartDateFirst: '需要先選擇開始時間',
      endDateEarlierThanStartDate: '結束日期不能早於開始日期',
      confirmClose: '確認關閉？',
      attachmentView: '附件查看',
      entryAttachment: '進場附件',
      exitAttachment: '退場附件',
      notUploaded: '未上傳',
      personnelExport: '人員導出',
      corpBelong: '所屬企業',
      selectCorp: '請選擇所屬單位',
      export: '導出',
      cancel: '取消',
      attendanceDateRequired: '考勤日期不能為空',
      corpBelongRequired: '所屬參建單位不能為空',
      personnelDetails: "人員詳情數據",
      photo: "證件照/頭像",
      idCardNumberLabel: "證件號碼：",
      genderLabel: "性別：",
      male: "男",
      female: "女",
      nationLabel: "民族：",
      birthdayLabel: "出生日期：",
      nativePlaceLabel: "籍貫：",
      issuingAuthorityLabel: "簽證機關：",
      validFromLabel: "證件生效期：",
      validUntilLabel: "證件失效期：",
      medicalHistoryLabel: "有無重大病史：",
      no: "無",
      yes: "有",
      phoneNumberLabel: "手機號碼：",
      emergencyContactLabel: "緊急聯繫人：",
      emergencyContactPhoneLabel: "緊急聯繫人電話：",
      notCertified: "該用戶未實名認證",
      careerHistory: "職業履歷",
      projectName: "項目名稱",
      participatingUnit: "參建單位",
      teamName: "班組名稱",
      workType: "工種",
      labourContract: "勞動合同",
      attachment: "附件",
      notUploaded: "未上傳",
      entryTime: "入場時間",
      exitTime: "退場時間",
      notExited: "未退場",
      qualificationInformation: "資質信息",
      certificateName: "證書名稱",
      certificateNumber: "證書編號",
      certificateCategory: "證書種類",
      certificateType: "證書類型",
      confirmingDepartment: "認定部門",
      jobType: "崗位類型",
      firstIssuanceDate: "第一次發證時間",
      certificateValidityStart: "證書有效時間（起）",
      certificateValidityEnd: "證書有效時間（止）",
      issuingAuthority: "發證機關",
      salaryManagement: "工資管理",
      compensationMethod: "計酬方式",
      selectCompensationMethod: "請選擇計算薪酬的方式",
      calculateByHour: "按時計算",
      calculateByDay: "按日計算",
      calculateByMonth: "按月計算",
      salaryUnitPrice: "薪酬單價(元)",
      enterSalaryUnitPrice: "請輸入薪酬單價",
      workingDays: "工作日(天)",
      enterWorkingDays: "請輸入工作日",
      enterWorkingDaysPerMonth: "一個月多少個工作日",
      personnelBankCardManagement: "人員銀行卡管理",
      bank: "所屬銀行",
      selectBank: "請選擇所屬銀行",
      bankBranch: "銀行支行",
      enterBankBranch: "請輸入銀行支行",
      bankCardNumber: "銀行卡號",
      enterBankCardNumber: "請輸入銀行卡號",
      submit: "提交",
      selectCompensationMethod: "請選擇計算薪酬的方式",
      enterSalaryUnitPrice: "請輸入薪酬單價",
    },
    audit: {
      avatar: "頭像",
      pendingTitle: '待審核人員',
      pending: '待審核',
      notPass: '不通過',
      workType: '工種',
      selectWorkType: '請選擇工種',
      workerRole: '工人類型',
      selectWorkerRole: '請選擇工人類型',
      manageType: '管理崗位',
      selectManageType: '請選擇管理崗位',
      workerName: '人員名稱',
      enterWorkerName: '請輸入人員名稱',
      search: '查詢',
      alertTitle: '請勾選單個或多個人員進行審核，通過後人員為待進場狀態。亦可點擊《通過並進場》按鈕使人員直接為進場狀態',
      confirmPassAndEnter: '是否通過人員審核並讓此人員進場，進場人員可直接通過設備入場',
      passAndEnter: '通過並進場',
      confirmPass: '是否通過人員審核，通過後人員將成為該項目人員',
      pass: '通過',
      confirmReject:'是否拒絕人員審核，拒絕後人員需重新申請',
      reject:'拒絕',
      nonManager: '非管理人員',
      male: '男',
      female: '女',
      notVerified: '未實名',
      rejected: '已拒絕',
      corpName: '所屬企業',
      workerName: '姓名',
      teamName: '所在班組名稱',
      workerRole: '人員類型',
      workType: '工種',
      manageType: '管理崗位',
      gender: '性別',
      age: '年齡',
      status: '審核結果',
      notSelectUser: '未選擇用戶',
      prompt: '提示',
      confirm: '確定',
      cancel: '取消'
    },
    attendance: {
      attendanceRecord: "考勤記錄",
      enteredPersonnel: "已進場人員",
      exitedPersonnel: "已離場人員",
      personnelName: "人員名稱",
      enterPersonnelName: "請輸入人員名稱",
      workType: "工種",
      selectWorkType: "請選擇工種",
      workerType: "工人類型",
      selectWorkerType: "請選擇工人類型",
      attendanceMonth: "考勤月份",
      selectMonth: "請選擇月份",
      search: "查詢",
      excelExport: "Excel導出",
      userName: "用戶姓名",
      teamName: "所在班組",
      attendanceTotalDays: "考勤總天數",
      firstEntryTime: "首次進場",
      notEntered: "未進場",
      lastExitTime: "最後離場",
      notExited: "未離場",
      totalTime: "共",
      noAttendance: "當日無考勤",
      entryDays: "進場天數",
      noAttendanceDays: "無考勤天數",
      entryCount: "進場次數",
      exitCount: "離場次數",
      visitSource: "訪問來源",
      attendanceRecord: "考勤記錄",
      attendanceRecord: "考勤記錄",
      entry: "進場",
      exit: "離場",
      exportAttendance: "考勤導出",
      corp: "所屬企業",
      selectCorp: "請選擇所屬單位",
      exportMonth: "導出月份",
      selectMonth: "請選擇月份",
      exportType: "導出類型",
      personnelHours: "人員工時",
      attendanceDetails: "考勤詳細",
      export: "導出",
      cancel: "取消",
      dateRequired: "考勤日期不能為空",
      corpRequired: "所屬參建單位不能為空"
    },
    machineManage:{
      lastHeartDate:'最後心跳',
      title: '設備管理詳情',
      deviceManagement: '設備管理',
      deviceAdd: '設備添加',
      deviceName: '設備名稱',
      enterDeviceName: '請輸入設備名稱',
      onlineStatus: '在線狀態',
      selectStatus: '請選擇',
      online: '在線',
      offline: '離線',
      search: '查詢',
      syncFailureAlert: '當設備多次同步，人員更換頭像，重新下發人員到設備均失敗後，請重置設備後再次進行數據同步',
      noRemark: '無備註',
      dataSync: '數據同步',
      syncFailureUsers: '同步失敗人員',
      reset: '重置',
      deviceName: '設備名稱',
      remark: '備註',
      deviceCode: '設備編碼',
      onlineStatus: '在線狀態',
      deviceUsers: '設備人數',
      faceSync: '人臉同步',
      reset: '重置',
      delete: '刪除',
      confirm: '確定',
      cancel: '取消',
      resetConfirm: '重置確認',
      deleteConfirm: '刪除確認',
      confirm: '確定',
      cancel: '取消',
      resetConfirmation: '將會把設備內所有人員清空，是否確認？',
      deleteConfirmation: '是否確認刪除此考勤機？',
      syncFailedUsers: '同步失敗人員',
      loadingText: '拼命加載中',
      userLabel: '用戶名稱',
      commandLabel: '命令下發時間',
      remarkLabel: '備註',
      failType0: '人員移除失敗，請重試',
      failType1: '人員下發失敗，請讓用戶換頭像後重新下發人臉數據',
      failType2: '人員下發失敗，請讓用戶實名後重新下發人臉數據',
      deviceName: '設備名稱',
      deviceNamePlaceholder: '請輸入設備名稱',
      deviceCode: '設備編碼',
      deviceCodePlaceholder: '請輸入設備編碼',
      integrator: '集成商',
      integratorPlaceholder: '請選擇',
      deviceDirection: '設備方向',
      directionIn: '進場',
      directionOut: '出場',
      remark: '備註',
      remarkPlaceholder: '有需要可以填寫備註',
      machineNameRequired: '設備名稱不能為空',
      deviceIdRequired: '設備編碼不能為空',
      directionRequired: '進場方向不能為空',
      integratorRequired: '集成商不能為空',
    },
    governmentStatus:{
      governmentAbnormalConnection: '政府異常對接',
      team: '班組：',
      member: '人員：',
      attendance: '今日考勤：',
      connectionStatus: '對接情況（成功數/總數）',
      teamConnectionAbnormal: '班組對接異常',
      workerConnectionAbnormal: '人員對接異常',
      attendanceConnectionAbnormal: '考勤對接異常',
      noGovernmentConnection: '該專案暫無對接政府系統，如需要對接請聯繫管理員',
      syncTeam: '班組同步',
      syncWorker: '人員同步',
      syncAttendance: '考勤同步',
      syncMachine: '設備同步',
      syncInProgress: '正在同步中，如長時間沒有變化請再次點擊上方同步按鈕',
      notSynced: '未同步，請點擊上方同步按鈕進行同步',
      notSynced: '未同步',
      uploadFailed: '上傳失敗',
      uploading: '上傳中，請耐心等待',
      prompt: '提示',
      cancel: '取消',
      confirm: '確認',
      contractor: '參建單位',
      teamConnection: '班組對接',
      workerBasicInfo: '工人基礎信息',
      workerDataEntry: '工人數據進專案',
      attendanceData: '人員考勤數據',
      salaryAccount: '工資發放賬號',
      trainingInfo: '人員培訓信息',
      workerPersonalInfo: '工人個人信息',
      faceDevice: '人臉設備',
      workerSalarySlip: '工人工資單',
      getProjectHash: '獲取專案hash值',
      getPersonnelHashData: '獲取人員hash數據',
      teamName: '班組名稱',
      name: '名稱',
      governmentType: '政府類型',
      attendanceTime: '考勤時間',
      errorReason: '錯誤原因',
      connectionTime: '對接時間',
      uploadStatus: '上傳狀態'
    },
  }
}
  