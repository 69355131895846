/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-12 22:22:46
 * @Description: file content
 * @FilePath: \auxface_web\src\views\equipment\lang\zh-cn.jsx
 */
export default {
  "equipment": {
    "router": {
      "equipmentSafety": "Equipment Safety",
      "elevatorManagement": "Elevator Management",
      "towerCraneManagement": "Tower Crane Management",
      "scaffoldManagement": "Scaffold Management"
    },
    "towerCrane": {
      "title": "Tower Crane Management Details",
      "towerCraneManagement": "Tower Crane Management",
      "confirmDeleteDevice": "Are you sure you want to delete this device?",
      "deleteConfirmation": "Delete Confirmation",
      "confirm": "Confirm",
      "cancel": "Cancel",
      "integrator": "Integrator",
      "deviceName": "Device Name",
      "onlineStatus": "Online Status",
      "totalHeight": "Total Height (m)",
      "hoistingWeight": "Hoisting Weight (t)",
      "span": "Span (m)",
      "height": "Height (m)",
      "rotationAngle": "Rotation Angle (degrees)",
      "ratedHoistingWeight": "Rated Hoisting Weight (t)",
      "momentPercentage": "Moment Percentage (%)",
      "windSpeed": "Wind Speed (m/s)",
      "forwardTiltAngle": "Forward Tilt Angle (degrees)",
      "deviceCode": "Device Code",
      "deviceLocation": "Device Location",
      "remark": "Remark",
      "uploadTime": "Upload Time",
      "detailData": "Detail Data",
      "delete": "Delete",
      "addDevice": "Add Device",
      "enterDeviceName": "Enter Device Name",
      "integrator": "Integrator",
      "selectIntegrator": "Select Integrator",
      "search": "Search",
      "offline": "Offline",
      "online": "Online",
      "noData": "No Data",
      "longitude": "Longitude",
      "latitude": "Latitude",
      "deviceArmLength": "Device Arm Length",
      "enterDeviceArmLength": "Enter Device Arm Length",
      "remarks": "Remarks",
      "enterRemarks": "Enter Remarks",
      "nameLength": "Length must be between 1 and 50 characters",
      "integratorRequired": "Integrator cannot be empty",
      "enterDeviceCode": "Enter Device Code",
      "codeLength": "Length must be between 1 and 10 characters",
      "enterLongitude": "Enter Longitude",
      "selectLatitude": "Select Latitude",
      "deviceDetails": "Device Details",
      "date": "Date",
      "to": "To",
      "startDate": "Start Date",
      "endDate": "End Date"
    },
    "elevator": {
      "title": "Elevator Management Details",
      "management": "Elevator Management",
      "addDevice": "Add Device",
      "enterDeviceName": "Enter Device Name",
      "integrator": "Integrator",
      "selectIntegrator": "Select Integrator",
      "search": "Search",
      "closed": "Closed",
      "opened": "Opened",
      "notInstalled": "Not Installed",
      "installed": "Installed",
      "longitude": "Longitude",
      "latitude": "Latitude",
      "detailData": "Detail Data",
      "delete": "Delete"
    },
    "scaffold": {
      "search": "Search",
      "management": "Scaffold Management",
      "addDevice": "Add Device",
      "enterDeviceName": "Enter Device Name",
      "onlineStatus": "Online Status",
      "deviceId": "Device ID",
      "deviceName": "Device Name",
      "weight": "Axial Pressure (kN)",
      "spanX": "Horizontal X Displacement (mm)",
      "spanY": "Horizontal Y Displacement (mm)",
      "dipX": "Horizontal X Tilt Angle (°)",
      "dipY": "Horizontal Y Tilt Angle (°)",
      "height": "Height",
      "down": "Settlement (mm)",
      "electric": "Battery Voltage",
      "windSpeed": "Wind Speed (m/s)",
      "alarmStatus": "Alarm Status",
      "inDate": "Upload Time",
      "details": "Details",
      "delete": "Delete",
      "title": "Scaffold Details",
      "detailData": "Detail Data",
      "offline": "Offline",
      "online": "Online",
      "confirmDeleteDevice": "Are you sure you want to delete this device?",
      "deleteConfirmation": "Delete Confirmation",
      "confirm": "Confirm",
      "cancel": "Cancel",
      "date": "Date",
      "to": "To",
      "startDate": "Start Date",
      "endDate": "End Date",
      "deviceDetails": "Device Details",
      "description": "Description",
      "enterDescription": "Enter Description",
      "normal": "Normal",
      "horizontalDisplacementXAlarm": "Horizontal Displacement X Axis Alarm",
      "verticalDisplacementAlarm": "Vertical Displacement Alarm",
      "angleAlarm": "Angle Alarm",
      "loadAlarm": "Load Alarm",
      "horizontalDisplacementYAlarm": "Horizontal Y Axis Displacement Alarm"
    }
  }
}
