import axios from '@/utils/axios.js'
var pre = 'platform/admin/v1/module/'
var func = [ 'getList' ]
var api = {}
func.forEach(value => {
  api[value] = pre + value
})

var getList = (params) => {
  return axios.get(api.getList, {params: params})
}

export { getList }
