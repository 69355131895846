/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2024-03-29 21:54:59
 * @Description: file content
 * @FilePath: \auxface_web\src\views\greenSite\lang\en.jsx
 */
export default {
  greenSite:{
    router:{
      greenSite: 'Green Site',
      dustDevices: 'Dust Devices',
      electricMeterManagement: 'Electric Meter Management',
      waterMeterManagement: 'Water Meter Management'
    },
    dust: {
        dustDeviceManagement: 'Dust Device Management',
        addDevice: 'Add Device',
        enterDeviceName: 'Enter Device Name',
        integrator: 'Integrator',
        selectIntegrator: 'Select Integrator',
        search: 'Search',
        online: 'Online',
        onlineStatus: 'Online Status',
        detail:"Detailed Data",
        offline: 'Offline',
        noRemark: 'No Remark',
        noData: 'No Data',
        northWind: 'North Wind',
        northeastByEast: 'Northeast by East',
        northeast: 'Northeast',
        northeastByNorth: 'Northeast by North',
        eastWind: 'East Wind',
        southeastByEast: 'Southeast by East',
        southeast: 'Southeast',
        southeastBySouth: 'Southeast by South',
        southWind: 'South Wind',
        southwestBySouth: 'Southwest by South',
        southwest: 'Southwest',
        southwestByWest: 'Southwest by West',
        westWind: 'West Wind',
        northwestByWest: 'Northwest by West',
        northwest: 'Northwest',
        northwestByNorth: 'Northwest by North',
        detailData: 'Detailed Data',
        remove: 'Remove',
        deviceName: 'Device Name',
        deviceNamePlaceholder: 'Enter Device Name',
        deviceSerialNumber: 'Device Serial Number',
        deviceSerialNumberPlaceholder: 'Enter Device Serial Number',
        integratorPlaceholder: 'Select Integrator',
        remark: 'Remark',
        remarkPlaceholder: 'Enter Remark',
        deviceNameRequired: 'Enter Device Name',
        deviceNameLength: 'Length between 1 and 40 characters',
        integratorRequired: 'Integrator cannot be empty',
        deviceSerialNumberRequired: 'Enter Device Serial Number',
        deviceSerialNumberLength: 'Length between 1 and 40 characters',
        noise: 'Noise (dB)',
        windDirect: 'Wind Direction',
        windSpeed: 'Wind Speed (m/s)',
        temp: 'Temperature (°C)',
        humid: 'Humidity (%RH)',
        atoms: 'Atmospheric Pressure (kpa)',
        inDate: 'Upload Time',
        confirmDeleteDevice: 'Are you sure you want to delete this device?',
        deleteConfirmation: 'Delete Confirmation',
        confirm: 'Confirm',
        cancel: 'Cancel',
        title:'Dust Device Management Details',
        deviceDetails: 'Device Details Information',
        dateLabel: 'Date:',
        dateSeparator: 'to',
        startDatePlaceholder: 'Start Date',
        endDatePlaceholder: 'End Date',
        searchButton: 'Search'
      },
      electric:{
        title:'Electric Meter Management Details',
        electricMeterManagement: 'Electric Meter Management',
        selectIntegrator: 'Select Integrator',
        search: 'Search' ,
        electricMeterManagement: 'Electric Meter Management',
        addEquipment: 'Add Equipment',
        offline: 'Offline',
        online: 'Online',
        noRemark: 'No Remark',
        noData: 'No Data',
        detailData: 'Detailed Data',
        delete: 'Delete',
        integrator: "Integrator",
        deviceName: "Device Name",
        deviceSerialNumber: "Device Serial Number",
        onlineStatus: "Online Status",
        remark: "Remark",
        electricMeterReading: "Electric Meter Reading (kWh)",
        uploadTime: "Upload Time",
        confirmDeleteDevice: "Are you sure you want to delete this device?",
        deleteConfirmation: "Delete Confirmation",
        confirm: "Confirm",
        cancel: "Cancel",
        enterDeviceName: "Enter Device Name",
        enterDeviceSerialNumber: "Enter Device Serial Number",
        enterDeviceName: "Enter Device Name",
        nameLength: "Length between 1 and 40 characters",
        integratorRequired: "Integrator cannot be empty",
        enterDeviceSerialNumber: "Enter Device Serial Number",
        codeLength: "Length between 1 and 10 characters",
        deviceDetails: "Device Details Information",
        date: "Date:",
        to: "to",
        startDate: "Start Date",
        endDate: "End Date",
        search: "Search",
        recentSevenDaysElectricity: "Recent Seven Days Electricity Consumption (kWh)",
        powerConsumption: "Power Consumption",
        todayElectricity: "Today's Electricity Consumption {reading} kWh",
        deviceName: "Device Name",
        enterDeviceName: "Enter Device Name"
      },
      water: {
        title:'Water Meter Management Details',
        waterMeterManagement: "Water Meter Management",
        addEquipment: "Add Equipment",
        deviceName: "Device Name",
        enterDeviceName: "Enter Device Name",
        integrator: "Integrator",
        selectIntegrator: "Select Integrator",
        search: "Search",
        offline: "Offline",
        online: "Online",
        noRemark: "No Remark",
        noData: "No Data",
        detailData: "Detailed Data",
        deviceName: "Device Name",
        deviceSerialNumber: "Device Serial Number",
        onlineStatus: "Online Status",
        remark: "Remark",
        waterMeterReading: "Water Meter Reading",
        uploadTime: "Upload Time",
        delete: "Delete",
        confirmDeleteDevice: "Are you sure you want to delete this device?",
        deleteConfirmation: "Delete Confirmation",
        confirm: "Confirm",
        cancel: "Cancel",
        deviceDetails: "Device Details Information",
        date: "Date:",
        to: "to",
        startDate: "Start Date",
        endDate: "End Date",
        search: "Search",
        todayWater: "Today's Water Consumption {reading} kWh",
        recentSevenDaysWater:'Recent Seven Days Water Consumption (ton)',
        powerConsumption:'Water Consumption',
        ton: "ton",
        enterDeviceName: "Enter Device Name",
        enterDeviceSerialNumber: "Enter Device Serial Number",
        enterDeviceName: "Enter Device Name",
        integratorRequired: "Integrator cannot be empty",
        enterDeviceSerialNumber: "Enter Device Serial Number",
        characterLength: "Length between 1 and 10 characters"
      }
    }
}
  