/*
 * @Author: Terry
 * @Date: 2023-12-03 15:44:26
 * @LastEditors: Terry
 * @LastEditTime: 2024-08-29 21:53:02
 * @Description: file content
 * @FilePath: \auxface_web\src\views\video\lang\zh-cn.jsx
 */
export default {
  video:{
    router:{
      playPage: '播放页面',
      monitorPlayback: '监控回放',
      videoMonitoring: '视频监控',
      config: '账号设置',
      camera: '摄像头管理',
      channel:'录像机子通道',
      cloudBack:'云回放',
      home:'首页',
    },
    account: {
      config: '账号设置',
      addCameraAccount: '添加摄像头账号',
      accountName: '账号名称',
      enterAccountName: '请输入账号名称',
      search: '查询',
      noRemark: '无备注',
      delete: '删除',
      accountName: '账号名称',
      remark: '备注',
      confirmDelete: '是否确认删除此账号?',
      deleteConfirmation: '删除确认',
      confirm: '确定',
      cancel: '取消',
      accountName: '账号名称',
      enterAccountName: '请输入账号名称',
      enterAppKey: '请输入账号appkey',
      enterSecret: '请输入账号Secret',
      accountNameRequired: '摄像头账号不能为空',
      appKeyRequired: 'AppKey不能为空',
      secretRequired: 'Secret不能为空'
    },
    camera: {
      addDevice: '设备添加',
      deviceName: '设备名称',
      enterDeviceName: '请输入设备名称',
      accountConfig: '账号配置',
      selectAccountConfig: '请选择账号配置',
      selectModel: '请选择机型',
      search: '查询',
      deviceName: '设备名称',
      deviceSerialNumber: '设备序列号',
      channelNumber: '通道号',
      model: '机型',
      onlineStatus: '在线状态',
      online: '在线',
      offline: '离线',
      retrieve: '重新获取',
      nonNvrDevice: '非NVR设备',
      playback: '回放',
      section: '所属板块',
      accountConfig: '账号配置',
      display: '是否显示',
      remark: '备注',
      delete: '删除',
      nvr: 'NVR',
      bulletCamera: '枪机',
      domeCamera: '球机',
      normalCamera: '普通摄像头',
      personnelManagement: '人员管理',
      dustDevice: '扬尘设备',
      towerCraneDevice: '塔吊设备',
      elevatorDevice: '升降机设备',
      confirmDeleteDevice: '是否确认删除此设备?',
      confirmation: '提示',
      confirm: '确定',
      cancel: '取消' ,
      serialNumber: '设备序列码',
      serialNumberPlaceholder: '请输入9位字母或数字的序列号',
      name: '设备名称',
      namePlaceholder: '设备名称',
      verificationCode: '设备验证码',
      verificationCodePlaceholder: '请输入设备验证码',
      accountConfiguration: '账号配置',
      selectAccountConfiguration: '请选择账号配置',
      belongingSection: '所属板块',
      selectBelongingSection: '请选择所属板块',
      machineType: '机器类型',
      selectMachineType: '请选择机器类型',
      remark: '备注',
      remarkPlaceholder: '请输入备注'
    },
    player: {
      goBack: '返回',
      screen: '屏幕',
      clearAllCameras: '一键清除所有摄像头',
      confirmDeleteAllCameras: '是否确认删除全部摄像头?',
      deleteConfirmation: '删除确认',
      confirm: '确定',
      cancel: '取消',
      dragCameras: '请拖放左侧列表摄像头',
      dragCamerasHere: '请把摄像头拖到此处播放',
      videoAlreadyInPlaylist: '该视频已经在播放列表了，请勿重复放置'
    },
  }
}
  