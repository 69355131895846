import axios from '@/utils/axios.js'
var pre = 'platform/admin/v1/menu/'
var func = [ 'getMenu', 'getModuleAside' ]
var api = {}
func.forEach(value => {
  api[value] = pre + value
})

var getMenu = (params) => {
  return axios.get(api.getMenu, {params: params})
}

var getModuleAside = (params) => {
  return axios.get(api.getModuleAside, {params: params})
}

export { getMenu, getModuleAside }
