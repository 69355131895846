/*
 * @Descripttion: 
 * @version: 
 * @Author: Terry
 * @Date: 2020-06-04 17:18:56
 * @LastEditors: Terry
 * @LastEditTime: 2023-12-09 18:28:28
 */ 
export  default  [
  {
    path: '/modules/projectInfo/',
    name: 'projectInfoIndex',
    component: ()=>import("@/views/Index.vue"),
    redirect: { path: '/modules/projectInfo/' },
    meta: {title: 'projectInfo.router.projectInformation'},
    children: [
      {name: 'projectInfo', path: 'info', component: ()=>import("@/views/projectInfo/project/Index.vue"), meta: {breadcrumb: false}},
      {name: 'rule', path: 'rule', component: ()=>import("@/views/projectInfo/rule/Index.vue"), meta: {title: 'projectInfo.router.permissionManagement'}},
      {name: 'authEdit', path: 'rule/authEdit', component: ()=>import("@/views/projectInfo/rule/AuthEdit.vue")},
      {name: 'projectCorp', path: 'projectCorp', component: ()=>import("@/views/projectInfo/projectCorp/Index.vue"), meta: {title: 'projectInfo.router.participatingUnits'}},
      {name: 'builderLicense', path: 'builderLicense', component: ()=>import("@/views/projectInfo/builderLicense/Index.vue"), meta: {title: 'projectInfo.router.constructionPermit'}},
      {name: 'settting', path: 'setting', component: ()=>import("@/views/projectInfo/setting/Index.vue"), meta: {title: 'projectInfo.router.projectSettings'}},
      {name: 'integrator', path: 'integrator/list', component: ()=>import("@/views/projectInfo/integrator/Index.vue"), meta: {title: 'projectInfo.router.integratorList'}},
      {name: 'integratorApply', path: 'integrator/apply', component: ()=>import("@/views/projectInfo/integrator/Apply.vue"), meta: {title: 'projectInfo.router.integratorApproval'}},
      {name: 'governmentConfig', path: 'governmentConfig', component: ()=>import("@/views/projectInfo/governmentConfig/Index.vue"), meta: {title: 'projectInfo.router.governmentIntegration'}},
      {name: 'salesSetting', path: 'salesSetting', component: ()=>import("@/views/projectInfo/salesSetting/Index.vue"), meta: {title: 'projectInfo.router.salesSettings'}},
    ]
  }
];
