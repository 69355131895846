/*
 * @Author: Terry
 * @Date: 2023-11-10 00:20:29
 * @LastEditors: Terry
 * @LastEditTime: 2024-05-09 15:03:35
 * @Description: file content
 * @FilePath: \auxface_web\src\lang\index.js
 */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import Cookies from 'js-cookie'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui lang
import elementTWLocale from 'element-ui/lib/locale/lang/zh-TW'// element-ui lang
import enLocale from './en'
import zhLocale from './zh-cn'
import zhTWLocale from './zh-tw'
Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  //简体
  'zh-cn': {
    ...zhLocale,
    ...elementZhLocale
  },

  "zh-tw": {
    ...zhTWLocale,
    ...elementTWLocale
  }
}
export function getLanguage() {
  var chooseLanguage = localStorage.getItem('language');
  if (chooseLanguage) return chooseLanguage
  var chooseLanguage = 'zh-tw';
  
  // if has not choose language
  const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  if(language=='zh') language ='zh-cn';
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      chooseLanguage = locale;
    }
  }
  localStorage.setItem('language',chooseLanguage);
  return chooseLanguage;
}
const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages
})
export default i18n
