/*
 * @Author: Healer
 * @Date: 2022-03-07 17:51:26
 * @LastEditTime: 2023-12-11 18:30:37
 * @LastEditors: Terry
 * @Description: 
 */
export default [
    {
      path: '/modules/equipment',
      name: 'equipmentIndex',
      component: ()=>import("@/views/Index.vue"),
      meta: {title: 'equipment.router.equipmentSafety'},
      redirect: { path: '/modules/equipment' },
      children: [
        //升降机
        {name: 'elevator', path: 'elevator', component: ()=>import("@/views/equipment/elevator/Index.vue"), meta: {title: 'equipment.router.elevatorManagement'}},
        {name: 'elevatorDetail', path: 'elevator/detail', component: ()=>import("@/views/equipment/elevator/Details.vue")},
        //塔吊
        {name: 'towerCrane', path: 'towerCrane', component: ()=>import("@/views/equipment/towerCrane/Index.vue"), meta: {title: 'equipment.router.towerCraneManagement'}},
        {name: 'towerCraneDetail', path: 'towerCrane/detail', component: ()=>import("@/views/equipment/towerCrane/Details.vue")},
        //脚手架
        {name: 'scaffold', path: 'scaffold', component: ()=>import("@/views/equipment/scaffold/Index.vue"), meta: {title: 'equipment.router.scaffoldManagement'}},
        {name: 'scaffoldDetail', path: 'scaffold/detail', component: ()=>import("@/views/equipment/scaffold/Details.vue")},
        
        {name: 'crane', path: 'crane', component: ()=>import("@/views/equipment/crane/Index.vue")},
        {name: 'craneDetail', path: 'crane/detail', component: ()=>import("@/views/equipment/crane/Details.vue")},
        {name: 'loadometer', path: 'loadometer', component: ()=>import("@/views/equipment/loadometer/Index.vue")},
      ]
      
    }, 
  ];